import React from 'react';
import "./AddEntry.css";
import Button from 'react-bootstrap/Button';
import * as constants from '../../../variables/constants';

class AddEntry extends React.Component {

    render() {

        var currentTransitions = null;
        var currentInteraction = null;


        if (this.props.entry.transitions.length > 0) {

            const newLine = <br />;

            currentTransitions = this.props.entry.transitions.map(transition => {

                const preview = this.generateTRExtraText(transition);

                return (
                    <div>
                        {this.props.entry.transitions.length > 1 && newLine}
                        <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="">Choose type</option>
                            <option value="transition" selected={transition.type === constants.TRANSITION}>Transition</option>
                            <option value="interaction" selected={transition.type === constants.INTERACTION}>Interaction</option>
                        </select>
                        <input
                            className="entry-transition-input"
                            size="70"
                            value={preview}
                            onClick={() => this.props.gotoTransitionExtras(transition)}>
                        </input>
                        <div class="row mx-md-n1">
                            <div class="px-md-1">
                                <Button variant="dark"
                                    onClick={() => this.props.containerRemoveTransition(transition)}>
                                    {'Remove'}
                                </Button>
                            </div>
                            <div class="px-md-1">
                                <Button variant="dark"
                                    onClick={() => this.props.containerTransitionUp(transition)}>
                                    {'↑'}
                                </Button>
                            </div>
                            <div class="px-md-1">
                                <Button variant="dark"
                                    onClick={() => this.props.containerTransitionDown(transition)}>
                                    {'↓'}
                                </Button>
                            </div>
                        </div>

                    </div>
                );
            });

        }


        if (this.props.entry.interaction) {

            const interaction = this.props.entry.interaction;

            if (interaction.type === constants.CHOICE3) {

                const reactions1 = this.generateReactionsJSX(constants.OPTION1, interaction.metadata.option1);
                const whereTo1 = this.generateOptionWhereToJSX(constants.OPTION1, interaction.metadata.option1);

                var hangOn1 = null;
                if (interaction.metadata.option1.hangOn) {
                    hangOn1 = this.generateHangOnJSX(constants.OPTION1, interaction.metadata.option1);
                }


                const reactions2 = this.generateReactionsJSX(constants.OPTION2, interaction.metadata.option2);
                const whereTo2 = this.generateOptionWhereToJSX(constants.OPTION2, interaction.metadata.option2);

                var hangOn2 = null;
                if (interaction.metadata.option2.hangOn) {
                    hangOn2 = this.generateHangOnJSX(constants.OPTION2, interaction.metadata.option2);
                }

                var reactions3 = null;
                var whereTo3 = null;

                if (interaction.metadata.option3) {
                    reactions3 = this.generateReactionsJSX(constants.OPTION3, interaction.metadata.option3);
                    whereTo3 = this.generateOptionWhereToJSX(constants.OPTION3, interaction.metadata.option3);

                    var hangOn3 = null;
                    if (interaction.metadata.option3.hangOn) {
                        hangOn3 = this.generateHangOnJSX(constants.OPTION3, interaction.metadata.option3);
                    }
                }

                const addHangOnElement1 = <div class="px-md-1">
                    <Button variant="dark"
                        onClick={() => this.props.addEmptyHangOnToOption(interaction.metadata.option1, constants.OPTION1)}>
                        {'Add Hang-On'}
                    </Button>
                </div>;
                const addHangOnElement2 = <div class="px-md-1">
                    <Button variant="dark"
                        onClick={() => this.props.addEmptyHangOnToOption(interaction.metadata.option2, constants.OPTION2)}>
                        {'Add Hang-On'}
                    </Button>
                </div>;
                var addHangOnElement3 = null;
                if (interaction.metadata.option3) {
                    addHangOnElement3 = <div class="px-md-1">
                        <Button variant="dark"
                            onClick={() => this.props.addEmptyHangOnToOption(interaction.metadata.option3, constants.OPTION3)}>
                            {'Add Hang-On'}
                        </Button>
                    </div>;
                }

                var replyText1 = "";
                if (interaction.metadata.option1.replyData) {
                    replyText1 = interaction.metadata.option1.replyData.text;
                } else {
                    replyText1 = interaction.metadata.option1.reply;
                }

                var replyText2 = "";
                if (interaction.metadata.option2.replyData) {
                    replyText2 = interaction.metadata.option2.replyData.text;
                } else {
                    replyText2 = interaction.metadata.option2.reply;
                }

                var replyText3 = "";
                if (interaction.metadata.option3.replyData) {
                    replyText3 = interaction.metadata.option3.replyData.text;
                } else {
                    replyText3 = interaction.metadata.option3.reply;
                }

                const newLine = <br />;

                currentInteraction = (
                    <div key="choice3">
                        {this.props.entry.transitions.length > 0 && newLine}
                        <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="">Choose type</option>
                            <option value="transition">Transition</option>
                            <option value="interaction" selected="true">Interaction</option>
                        </select>
                        {this.provideInteractionTypes(constants.CHOICE3)}

                            <div class="px-md-1">
                                <Button variant="dark"
                                    onClick={() => this.props.removeInteraction()}>
                                    {'Remove'}
                                </Button>
                            </div>

                        <br />
                        <div>
                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            <div className="option1">
                                Option 1:
                                <Button variant="dark"
                                    onClick={() => this.props.addEmptyReactionToOption(interaction.metadata.option1, constants.OPTION1)}>
                                    {'Add Reaction'}
                                </Button>
                                {!hangOn1 && addHangOnElement1}
                                <br />
                                <br />
                            Reply 1: <input
                                    className="answer1-input"
                                    size="75"
                                    defaultValue={replyText1}
                                    onClick={() => this.props.editReply(constants.OPTION1, interaction.metadata.option1)}
                                ></input>
                                <br />
                                {reactions1}
                                {reactions1.length > 0 && whereTo1}
                                {hangOn1}
                            </div>
                            <br />
                            <div className="option2">
                                Option 2:
                                <Button variant="dark"
                                    onClick={() => this.props.addEmptyReactionToOption(interaction.metadata.option2, constants.OPTION2)}>
                                    {'Add Reaction'}
                                </Button>
                                {!hangOn2 && addHangOnElement2}
                                <br />
                                <br />
                                Reply 2: <input
                                    className="answer2-input"
                                    size="75"
                                    defaultValue={replyText2}
                                    onClick={() => this.props.editReply(constants.OPTION2, interaction.metadata.option2)}></input>
                                <br />
                                {reactions2}
                                {reactions2.length > 0 && whereTo2}
                                {hangOn2}
                            </div>
                            <br />
                            <div className="option3">
                                Option 3:
                                <Button variant="dark"
                                    onClick={() => this.props.addEmptyReactionToOption(interaction.metadata.option3, constants.OPTION3)}>
                                    {'Add Reaction'}
                                </Button>
                                {!hangOn3 && addHangOnElement3}
                                <br />
                                <br />
                                Reply 3: <input
                                    className="answer3-input"
                                    size="75"
                                    defaultValue={replyText3}
                                    onClick={() => this.props.editReply(constants.OPTION3, interaction.metadata.option3)}></input>

                                <br />
                                {reactions3}
                                {reactions3 ? reactions3.length > 0 && whereTo3 : null}
                                {hangOn3}
                            </div>
                            <br />
                        </div>
                    </div>
                );
            } else if (interaction.type.startsWith(constants.CHOICE3_AVAILABILITY)) {

                var whereTo1 = null;
                var whereTo2 = null;
                var whereTo3 = null;

                if (!interaction.type.includes("-see")) {
                    whereTo1 = this.generateAvailabilityOptionWhereToJSX(constants.OPTION1, interaction.metadata.option1);
                    whereTo2 = this.generateAvailabilityOptionWhereToJSX(constants.OPTION2, interaction.metadata.option2);
                    if (interaction.metadata.option3) {
                        whereTo3 = this.generateAvailabilityOptionWhereToJSX(constants.OPTION3, interaction.metadata.option3);
                    }

                }

                const newLine = <br />;

                const contentCondition = interaction.type.includes("-see")
                    || interaction.type.includes("-respond")
                    || interaction.type.includes("-chapter");

                const contentElement = (
                    <div>
                        <br />
                    Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                        <br />
                        <br />
                        <div className="option1">
                            Option 1:
                        <br />
                            <br />
                    Reply 1: <input
                                className="answer1-input"
                                size="75"
                                defaultValue={interaction.metadata.option1.reply}
                                onChange={(event) => this.props.availabilityReplyTextChanged(constants.OPTION1, interaction.metadata.option1, event.target.value)}></input>

                            <br />
                            {whereTo1}
                        </div>
                        <br />
                        <div className="option2">
                            Option 2:
                        <br />
                            <br />
                        Reply 2: <input
                                className="answer2-input"
                                size="75"
                                defaultValue={interaction.metadata.option2.reply}
                                onChange={(event) => this.props.availabilityReplyTextChanged(constants.OPTION2, interaction.metadata.option2, event.target.value)}></input>
                            <br />
                            {whereTo2}
                        </div>
                        <br />
                        <div className="option3">
                            Option 3:
                        <br />
                            <br />
                        Reply 3: <input
                                className="answer3-input"
                                size="75"
                                defaultValue={interaction.metadata.option3 ? interaction.metadata.option3.reply : ""}
                                onChange={(event) => this.props.availabilityReplyTextChanged(constants.OPTION3, interaction.metadata.option3, event.target.value)}></input>

                            <br />
                            {whereTo3}
                        </div>
                        <br />
                    </div>);

                currentInteraction = (
                    <div key="choice3">
                        {this.props.entry.transitions.length > 0 && newLine}
                        <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="">Choose type</option>
                            <option value="transition">Transition</option>
                            <option value="interaction" selected="true">Interaction</option>
                        </select>
                        {this.provideInteractionTypes(constants.CHOICE3_AVAILABILITY)}
                        {this.provideAvailabilityTypes(this.props.entry.interaction.type)}
                        <input
                            type="button"
                            className="btn-general"
                            id="remove-transition" value="Remove"
                            onClick={() => this.props.removeInteraction()}>
                        </input>
                        <br />
                        {contentCondition && contentElement}

                    </div>
                );
            } else if (interaction.type === constants.YES_NO
                || interaction.type === constants.SHARE
                || interaction.type === constants.PAYMENT
                || interaction.type === constants.IMAGE) {

                var hangOnYes = null;
                if (interaction.metadata.optionYes.hangOn) {
                    hangOnYes = this.generateHangOnJSX(constants.OPTION_YES, interaction.metadata.optionYes);
                }

                var hangOnNo = null;
                if (interaction.metadata.optionNo.hangOn) {
                    hangOnNo = this.generateHangOnJSX(constants.OPTION_NO, interaction.metadata.optionNo);
                }

                const reactionsYes = this.generateReactionsJSX("optionYes", interaction.metadata.optionYes);
                const whereToYes = this.generateOptionWhereToJSX("optionYes", interaction.metadata.optionYes);

                const reactionsNo = this.generateReactionsJSX("optionNo", interaction.metadata.optionNo);
                const whereToNo = this.generateOptionWhereToJSX("optionNo", interaction.metadata.optionNo);

                const addHangOnElementYes = <input type="button" className="btn-general" id="add-reaction1" value="Add Hang-On" onClick={() => this.props.addEmptyHangOnToOption(interaction.metadata.optionYes, constants.OPTION_YES)}></input>;
                const addHangOnElementNo = <input type="button" className="btn-general" id="add-reaction1" value="Add Hang-On" onClick={() => this.props.addEmptyHangOnToOption(interaction.metadata.optionNo, constants.OPTION_NO)}></input>;

                const newLine = <br />;

                var dynamicInteractionTypes = null;
                if (interaction.type === constants.YES_NO) {

                    dynamicInteractionTypes = this.provideInteractionTypes(constants.YES_NO)

                } else if (interaction.type === constants.SHARE) {

                    dynamicInteractionTypes = this.provideInteractionTypes(constants.SHARE)

                } else if (interaction.type === constants.PAYMENT) {

                    dynamicInteractionTypes = this.provideInteractionTypes(constants.PAYMENT)

                } else if (interaction.type === constants.IMAGE) {

                    dynamicInteractionTypes = this.provideInteractionTypes(constants.IMAGE)
                }
                currentInteraction = (

                    <div key="yesno">
                        {this.props.entry.transitions.length > 0 && newLine}
                        <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="">Choose type</option>
                            <option value="transition">Transition</option>
                            <option value="interaction" selected="true">Interaction</option>
                        </select>
                        {dynamicInteractionTypes}
                        <input
                            type="button"
                            className="btn-general"
                            id="remove-transition" value="Remove"
                            onClick={() => this.props.removeInteraction()}></input>
                        <br />
                        <div>

                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            <div className="option1">
                                Option Yes: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToOption(interaction.metadata.optionYes, constants.OPTION_YES)}></input>
                                {!hangOnYes && addHangOnElementYes}
                                <br />
                                <br />
                                {reactionsYes}
                                {reactionsYes.length > 0 && whereToYes}
                                {hangOnYes}
                            </div>
                            <br />
                            <div className="option2">
                                Option No: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToOption(interaction.metadata.optionNo, constants.OPTION_NO)}></input>
                                {!hangOnNo && addHangOnElementNo}
                                <br />
                                <br />
                                {reactionsNo}
                                {reactionsNo.length > 0 && whereToNo}
                                {hangOnNo}
                            </div>
                            <br />
                        </div>
                    </div>
                );

            } else if (interaction.type === constants.LOCATION) {

                const reactionsGeneral = this.generateLocationReactionsJSX(constants.REACTIONS, interaction.metadata.optionYes.reactions, constants.OPTION_YES, interaction.metadata.optionYes, true);
                const reactionsLogicTrue = this.generateLocationReactionsJSX(constants.REACTIONS_LOGIC_TRUE, interaction.metadata.optionYes.reactionsLogicTrue, constants.OPTION_YES, interaction.metadata.optionYes, true);
                const reactionsLogicFalse = this.generateLocationReactionsJSX(constants.REACTIONS_LOGIC_FALSE, interaction.metadata.optionYes.reactionsLogicFalse, constants.OPTION_YES, interaction.metadata.optionYes, true);
                const reactionsUndetermined = this.generateLocationReactionsJSX(constants.REACTIONS_UNDETERMINED, interaction.metadata.optionYes.reactionsUndetermined, constants.OPTION_YES, interaction.metadata.optionYes, true);
                const reactionsLocationNo = this.generateLocationReactionsJSX(constants.REACTIONS, interaction.metadata.optionNo.reactions, constants.OPTION_NO, interaction.metadata.optionNo, false);

                const whereToYesLocation = this.generateLocationOptionWhereToJSX(true, constants.OPTION_YES, interaction.metadata.optionYes);
                const whereToNoLocation = this.generateLocationOptionWhereToJSX(false, constants.OPTION_NO, interaction.metadata.optionNo);

                const newLine = <br />;

                var dynamicInteractionTypes = null;

                currentInteraction = (

                    <div key="yesno">
                        {this.props.entry.transitions.length > 0 && newLine}
                        <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="">Choose type</option>
                            <option value="transition">Transition</option>
                            <option value="interaction" selected="true">Interaction</option>
                        </select>
                        {this.provideInteractionTypes(constants.LOCATION)}
                        <input
                            type="button"
                            className="btn-general"
                            id="remove-transition" value="Remove"
                            onClick={() => this.props.removeInteraction()}></input>
                        <br />
                        <div>
                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            <div className="optionLocationYesGeneral">
                                Option Yes General: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToLocationOption(interaction.metadata.optionYes, "optionYes", interaction.metadata.optionYes.reactions, constants.REACTIONS)}></input>
                                <br />
                                <br />
                                {reactionsGeneral}
                            </div>
                            <br />
                            <div className="optionLocationYesSame">
                                Option Same: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToLocationOption(interaction.metadata.optionYes, "optionYes", interaction.metadata.optionYes.reactionsLogicTrue, constants.REACTIONS_LOGIC_TRUE)}></input>
                                <br />
                                <br />
                                {reactionsLogicTrue}
                            </div>
                            <br />
                            <div className="optionLocationYesDifferent">
                                Option Different: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToLocationOption(interaction.metadata.optionYes, "optionYes", interaction.metadata.optionYes.reactionsLogicFalse, constants.REACTIONS_LOGIC_FALSE)}></input>
                                <br />
                                <br />
                                {reactionsLogicFalse}
                            </div>
                            <br />
                            <div className="optionLocationYesBroken">
                                Option Undetermined: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToLocationOption(interaction.metadata.optionYes, "optionYes", interaction.metadata.optionYes.reactionsUndetermined, constants.REACTIONS_UNDETERMINED)}></input>
                                <br />
                                <br />
                                {reactionsUndetermined}
                            </div>
                            <br />
                            {reactionsGeneral.length > 0
                                && reactionsLogicTrue.length > 0
                                && reactionsLogicFalse.length > 0
                                && reactionsUndetermined.length > 0
                                && whereToYesLocation}
                            <br />
                            <div className="optionLocationNo">
                                Option No: <input type="button" className="btn-general" id="add-reaction1" value="Add Reaction" onClick={() => this.props.addEmptyReactionToLocationOption(interaction.metadata.optionNo, "optionNo", interaction.metadata.optionNo.reactions, constants.REACTIONS)}></input>
                                <br />
                                <br />
                                {reactionsLocationNo}
                            </div>
                            <br />
                            {reactionsLocationNo.length > 0
                                && whereToNoLocation}
                            <br />
                        </div>
                    </div>
                );

            } else if (interaction.type === constants.MULTI_WORDS) {


                const newLine = <br />;

                const optionsDiv = interaction.metadata.options.map((option, optionIndex) => {

                    var reactions = this.generateMultiWordsReactionsJSX(option, optionIndex);
                    var whereTo = this.generateOptionWhereToJSX("", option);

                    // var hangOn = null;
                    // if (option.hangOn) {
                    //     hangOn = this.generateHangOnJSX("", option);
                    // }

                    // const addHangOnElement = <input type="button" className="btn-general" id="add-reaction1" value="Add Hang-On" onClick={() => this.props.addEmptyHangOnToOption(option, "")}></input>;

                    return (
                        <div className="optionMultiWords">
                            Option {optionIndex + 1}:
                            <input
                                type="button"
                                className="btn-general"
                                id="add-reaction1"
                                value="Add Reaction"
                                onClick={() => this.props.addEmptyReactionToMultiWords(option)}>
                            </input>
                            {/* {!hangOn && addHangOnElement} */}
                            <br />
                            <br />
                            Answers: <input
                                onChange={event => this.props.multiwordAnswerChanged(option, event.target.value)}
                                className="entry-transition-input"
                                size="70"
                                defaultValue={option.answers.map(answer => {
                                    return answer
                                })}>
                            </input>
                            <br />
                            {reactions}
                            <br />
                            {whereTo}
                            <br />
                            {/* {hangOn}
                            <br /> */}
                        </div>
                    );
                });

                currentInteraction = (
                    <div>
                        <div>
                            {this.props.entry.transitions.length > 0 && newLine}
                            <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                                <option value="">Choose type</option>
                                <option value="transition">Transition</option>
                                <option value="interaction" selected="true">Interaction</option>
                            </select>
                            {this.provideInteractionTypes(constants.MULTI_WORDS)}
                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Remove"
                                onClick={() => this.props.removeInteraction()}>
                            </input>

                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Add Option"
                                onClick={() => this.props.addMultiWordsOption()}>
                            </input>
                        </div>

                        <div>
                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            {optionsDiv}
                        </div>
                    </div>
                );

            } else if (interaction.type === constants.MULTI_NUMBERS) {

                const newLine = <br />;

                const optionsDiv = interaction.metadata.options.map((option, optionIndex) => {

                    var reactions = this.generateMultiNumbersReactionsJSX(option, optionIndex);
                    var whereTo = this.generateOptionWhereToJSX("", option);

                    var from = -1;
                    var to = -1;
                    if (option.range.length > 0) {
                        from = option.range[0];
                        to = option.range[1];
                    }
                    return (
                        <div className="optionMultiNumbers">
                            Option {optionIndex + 1}:
                            <input
                                type="button"
                                className="btn-general"
                                id="add-reaction1"
                                value="Add Reaction"
                                onClick={() => this.props.addEmptyReactionToMultiWords(option)}>
                            </input>
                            <br />
                            <br />
                            Range: <div className="entry-whereto">
                                <div className="entry-whereto-elements">
                                    <div className="whereto-next">
                                        From:<input
                                            type="short-text"
                                            pattern="[0-9]*"
                                            size="10"
                                            onInput={e => this.props.rangeFromChanged(parseInt(e.target.value, 10), option)}
                                            defaultValue={from}>
                                        </input>
                                    </div>
                                    <div className="whereto-availability">
                                        To:<input
                                            type="short-text"
                                            pattern="[0-9]*"
                                            size="10"
                                            onInput={e => this.props.rangeToChanged(parseInt(e.target.value, 10), option)}
                                            defaultValue={to}></input>
                                    </div>
                                </div>
                            </div>
                            {newLine && reactions}
                            {newLine && whereTo}
                            <br />
                            <br />
                        </div>
                    );
                });

                currentInteraction = (
                    <div>
                        <div>
                            {this.props.entry.transitions.length > 0 && newLine}
                            <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                                <option value="">Choose type</option>
                                <option value="transition">Transition</option>
                                <option value="interaction" selected="true">Interaction</option>
                            </select>
                            {this.provideInteractionTypes(constants.MULTI_NUMBERS)}
                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Remove"
                                onClick={() => this.props.removeInteraction()}>
                            </input>

                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Add Option"
                                onClick={() => this.props.addMultiWordsOption()}>
                            </input>
                        </div>

                        <div>
                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            {optionsDiv}
                        </div>
                    </div>
                );

            } else if (interaction.type === constants.NAME
                || interaction.type === constants.QUESTION_NAME) {

                const newLine = <br />;

                const reactions = this.generateNameReactionsJSX(interaction.metadata.reactions);

                currentInteraction = (
                    <div>
                        <div>
                            {this.props.entry.transitions.length > 0 && newLine}
                            <select id="types" onChange={event => this.props.parentSelectionChanged(event)} >
                                <option value="">Choose type</option>
                                <option value="transition">Transition</option>
                                <option value="interaction" selected="true">Interaction</option>
                            </select>
                            {this.provideInteractionTypes(constants.NAME)}
                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Remove"
                                onClick={() => this.props.removeInteraction()}>
                            </input>

                            <input
                                type="button"
                                className="btn-general"
                                id="remove-transition" value="Add Reaction"
                                onClick={() => this.props.addEmptyReactionToName()}>
                            </input>

                        </div>

                        <div>
                            <br />
                            Text: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.text} onChange={(e) => this.props.interactionTextChanged(e.target.value)}></input>
                            <br />
                            <br />
                            Hint: <input className="entry-transition-input" size="80" defaultValue={interaction.metadata.hint} onChange={(e) => this.props.interactionNameHintChanged(e.target.value)}></input>
                            <br />
                            <br />
                            {reactions}
                            <br />
                            <div className="whereto-frame">
                                Where to:
                                <table>
                                    <tr>
                                        <td>Next:</td>
                                        <td><input
                                            type="short-text"
                                            pattern="[0-9]*"
                                            size="10"
                                            onInput={e => this.props.nameNextEntryChanged(parseInt(e.target.value, 10))}
                                            defaultValue={interaction.metadata.whereTo.next}></input></td>
                                    </tr>
                                    <tr>
                                        <td>Availability:</td>
                                        <td><input
                                            type="short-text"
                                            pattern="[0-9]*"
                                            size="10"
                                            onInput={e => this.props.nameAvailabilityEntryChanged(parseInt(e.target.value, 10))}
                                            defaultValue={interaction.metadata.whereTo.availability}>
                                        </input>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            } else {

                const newLine = <br />;

                currentInteraction = (
                    <div key="selection">
                        {this.props.entry.transitions.length > 0 && newLine}
                        <select id="types" className="select1" onChange={event => this.props.parentSelectionChanged(event)} >
                            <option value="" selected>Choose type</option>
                            <option value="transition">Transition</option>
                            <option value="interaction">Interaction</option>
                        </select>
                        {this.provideInteractionTypes("")}
                    </div>
                );
            }

        }

        var currentElementDiv = null;
        if (this.props.editedElement) {

            var currentElementInput = null;
            var currentElement2ndSelection = null;

            if (this.props.editedElement.type === "transition") {

                const newLine = <br />;

                currentElementDiv = <div>
                    {this.props.entry.transitions.length > 0 && newLine}
                    <select id="types"
                        onChange={event => this.props.parentSelectionChanged(event)} >
                        <option value="">Choose type</option>
                        <option value="transition" selected="true">Transition</option>
                        <option value="interaction">Interaction</option>
                    </select>
                    <input
                        className="entry-transition-input"
                        size="70"
                        value={this.props.editedElement.text ? this.props.editedElement.text : "EMPTY TEXT"}
                        onClick={() => this.props.gotoTransitionExtras(this.props.editedElement)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.containerRemoveTransition(this.props.editedElement)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="↑"
                        onClick={() => this.props.containerTransitionUp(this.props.editedElement)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="↓"
                        onClick={() => this.props.containerTransitionDown(this.props.editedElement)}>
                    </input>
                </div>
            } else if (this.props.editedElement.type === "interaction") {

                const newLine = <br />;

                currentElementDiv = (<div key="edit_selection">
                    {this.props.entry.transitions.length > 0 && newLine}
                    <select id="types"
                        className="select1"
                        onChange={(event) => this.props.parentSelectionChanged(event)} >
                        <option value="">Choose type</option>
                        <option value="transition">Transition</option>
                        <option value="interaction" selected>Interaction</option>
                    </select>
                    {this.provideInteractionTypes("")}
                </div>);

            } else {

                const newLine = <br />;

                currentElementDiv = <div key="edit">
                    {this.props.entry.transitions.length > 0 && newLine}
                    <select id="types"
                        onChange={event => this.props.parentSelectionChanged(event)} >
                        <option value="" defaultValue>Choose type</option>
                        <option value="transition">Transition</option>
                        <option value="interaction">Interaction</option>
                    </select>
                    {currentElement2ndSelection}
                    {currentElementInput}
                </div>;
            }


        }

        var next = -1;
        var availability = -1;
        if (this.props.entry.whereTo) {
            next = this.props.entry.whereTo.next;
            availability = this.props.entry.whereTo.availability;
        }

        //handling case status is object, and when it's 
        var status = "";
        if (this.props.entry.status && this.props.entry.status.text) {
            status = this.props.entry.status.text;
        }

        const transitionWhereTo = <div>WhereTo:

            <table>
                <tr>
                    <td>Next:</td>
                    <td><input
                        type="short-text"
                        pattern="[0-9]*"
                        size="10"
                        onInput={e => this.props.transitionNextEntryChanged(parseInt(e.target.value, 10))}
                        defaultValue={next}></input></td>
                </tr>
                <tr>
                    <td>Availability:</td>
                    <td><input
                        type="short-text"
                        pattern="[0-9]*"
                        size="10"
                        onInput={e => this.props.transitionAvailabilityEntryChanged(parseInt(e.target.value, 10))}
                        defaultValue={availability}>
                    </input>
                    </td>
                </tr>
            </table>
        </div>;

        var hangOnElement = null;

        const transitionsWithoutInteraction = !this.props.entry.interaction && this.props.entry.transitions.length > 0;
        const transitionEditedElementWithoutInteraction = !this.props.entry.interaction && this.props.editedElement && this.props.editedElement.type === constants.TRANSITION;

        if (this.props.entry.hangOn) { //this hangOn is in case an interaction is not present

            if (transitionsWithoutInteraction || transitionEditedElementWithoutInteraction) {
                hangOnElement = this.generateTransitionHangOnJSX(this.props.entry.hangOn);
            }
        } else if (transitionsWithoutInteraction || transitionEditedElementWithoutInteraction) {
            hangOnElement = <div><br /><input type="button" className="btn-general" id="add-reaction1" value="Add Hang-On" onClick={() => this.props.addEmptyHangOnToEntry()}></input></div>;
        }

        const statusCondition = this.props.entry.transitions.length > 0 || (this.props.entry.interaction && this.props.entry.interaction.type) && !this.props.entry.interaction.type.startsWith(constants.CHOICE3_AVAILABILITY);
        const statusElement = <tr><td>Status:</td><td><input type="text" size="80" defaultValue={status} onChange={e => this.props.statusChanged(e.target.value)}></input></td></tr>;


        var mainActionElement = null; //add element or remove entire entry

        var pasteElement = null;
        if (this.props.copiedEntry) {
            pasteElement = <input type="button" className="btn-cancel" id="btnPaste" value="Paste Entry" onClick={() => this.props.pasteEntry(this.props.copiedEntry)}></input>
        }

        if (this.props.entry.interaction) {

            mainActionElement = (
                <div class="row mx-md-n1">
                <div class="px-md-1">
                    <Button variant="dark"
                        onClick={() => this.props.deleteEntry(this.props.entry)}>
                        {'Delete Entry'}
                    </Button>
                </div>
                <div class="px-md-1">
                    <Button variant="dark"
                        onClick={() => this.props.copyEntry(this.props.entry)}>
                        {'Copy Entry'}
                    </Button>
                </div>
                <div class="px-md-1">
                    <Button variant="dark"
                        onClick={() => this.props.plusTransition()}>
                        {'+ Transition'}
                    </Button>
                </div>
                {pasteElement}
                <br />
            </div>);
        } else {
            mainActionElement = (<div>
                <input type="button" id="btnAdd" value="Add Entry Element" onClick={() => this.props.addElementToEntry()}></input>
                <input type="button" className="btn-cancel" id="btnDelete" value="Delete Entry" onClick={() => this.props.deleteEntry(this.props.entry)}></input>
                <input type="button" className="btn-cancel" id="btnCopy" value="Copy Entry" onClick={() => this.props.copyEntry(this.props.entry)}></input>
                {pasteElement}
                <br />
            </div>
            );
        }

        const finalButtons = <div>
            <br />
            <input type="button" className="btn-done" id="btnDone" value="DONE" onClick={this.entryDone}></input>
            <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={this.entryCancel}></input>
        </div>;

        const firstElement = <table>
            <tr>
                <td>Entry Id:</td>
                <td><input
                    type="char-text"
                    defaultValue={this.props.entry.id}
                    onChange={e => this.props.entryIdChanged(parseInt(e.target.value, 10))}>
                </input></td>
            </tr>
            {statusCondition && statusElement}
        </table>;

        var secondElement = finalButtons;
        if (this.props.entry.transitions.length > 0 || this.props.entry.interaction
            || this.props.editedElement) {
            secondElement = (<div>
                {(transitionsWithoutInteraction || transitionEditedElementWithoutInteraction) && transitionWhereTo}

                {hangOnElement}
                <br />
                <div id="entry-elements">
                    <br />
                    {currentTransitions}
                    {currentInteraction}
                    {currentElementDiv}
                </div>
                {finalButtons}
            </div>);
        }
        return <div>

            {mainActionElement}
            <br />

            {firstElement}
            {secondElement}

        </div>
    }


    generateLocationOptionWhereToJSX = (isYes, dynamicOptionKey, option) => {

        if (option.whereTo) {

            if (isYes) {

                const next = option.whereTo.next;
                const availability = option.whereTo.availability;
                const same = option.whereTo.same;
                const different = option.whereTo.different;
                const broken = option.whereTo.broken;

                return <div >
                    <br />
                    <div className="entry-whereto">
                        <div className="entry-whereto-elements">
                            <div className="whereto-next">
                                Next:<input
                                    className="entry-transition-input"
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.NEXT, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={next}>
                                </input>
                            </div>
                            <div className="whereto-availability">
                                Availability:<input
                                    className="entry-transition-input"
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.AVAILABILITY, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={availability}></input>
                            </div>
                            <div className="whereto-availability">
                                Same:<input
                                    className="entry-transition-input"
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.SAME, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={same}></input>
                            </div>
                            <div className="whereto-availability">
                                Different:<input
                                    className="entry-transition-input"
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.DIFFERENT, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={different}></input>
                            </div>
                            <div className="whereto-availability">
                                Broken:<input
                                    className="entry-transition-input"
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.BROKEN, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={broken}></input>
                            </div>
                        </div>
                    </div>
                </div>

            } else {

                const next = option.whereTo.next;
                const availability = option.whereTo.availability;


                return <div >
                    <br />
                    <div className="entry-whereto">
                        <div className="entry-whereto-elements">
                            <div className="whereto-next">
                                Next:<input
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.NEXT, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={next}>
                                </input>
                            </div>
                            <div className="whereto-availability">
                                Availability:<input
                                    type="text"
                                    pattern="[0-9]*"
                                    size="10"
                                    onInput={e => this.props.reactionLocationWhereToChanged(constants.AVAILABILITY, parseInt(e.target.value, 10), dynamicOptionKey, option)}
                                    defaultValue={availability}></input>
                            </div>
                        </div>
                    </div>
                </div>
            }

        }

    }


    generateAvailabilityOptionWhereToJSX = (dynamicOptionKey, option) => {

        //see => no where to
        //chapter => yes where to with next
        //respond => yes where to with next

        var next = -1;

        if (option.whereTo) {
            next = option.whereTo.next;
        }

        return <div >
            <br />
            <div className="entry-whereto">
                <div className="entry-whereto-elements">
                    <div className="whereto-next">
                        Next:<input
                            type="short-text"
                            pattern="[0-9]*"
                            size="10"
                            onInput={e => this.props.reactionNextEntryChanged(parseInt(e.target.value, 10), dynamicOptionKey, option)}
                            defaultValue={next}>
                        </input>
                    </div>
                </div>
            </div>
        </div>

    }



    generateTransitionHangOnJSX = (hangOn) => {

        var minutes = -1;
        var uiMode = "";

        if (hangOn) {
            minutes = hangOn.time;
            if (this.props.baeMetadata.documentId === constants.NOELLE_METADATA
                || this.props.baeMetadata.documentId === constants.VASILENA_METADATA
                || this.props.baeMetadata.documentId === constants.KAYLEE_METADATA) {
                minutes = hangOn.time / 60000; //turn miliseconds to minutes
            }
            uiMode = hangOn.uiMode;
        }

        return <div >
            <br />
            <div className="entry-whereto">
                <div className="entry-whereto-elements">
                    <div className="whereto-next">
                        Minutes:<input
                            type="short-text"
                            pattern="[0-9]*"
                            size="10"
                            onInput={e => this.props.hangOnTransitionMinutesChanged(parseInt(e.target.value, 10), hangOn)}
                            defaultValue={minutes}>
                        </input>
                    </div>
                    <select id="ui-mode" className="select2" onChange={event => this.props.hangOnTransitionUIModeChanged(event.target.value)} >
                        <option value="">Choose type</option>
                        <option value={constants.ONLINE} selected={uiMode === constants.ONLINE}>Online</option>
                        <option value={constants.AWAY} selected={uiMode === constants.AWAY}>Away</option>
                        <option value={constants.OFFLINE} selected={uiMode === constants.OFFLINE}>Offline</option>
                    </select>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove Hang-On"
                        onClick={() => this.props.removeHangOnTransition()}>
                    </input>
                </div>
            </div>
        </div>
    }

    generateHangOnJSX = (dynamicOptionKey, option) => {

        var minutes = -1;
        var uiMode = "";

        if (option.hangOn) {
            minutes = option.hangOn.time;
            uiMode = option.hangOn.uiMode;
        }

        return <div >
            <br />
            <div className="entry-whereto">
                <div className="entry-whereto-elements">
                    <div className="whereto-next">
                        Minutes:<input
                            type="short-text"
                            pattern="[0-9]*"
                            size="10"
                            onInput={e => this.props.hangOnMinutesChanged(parseInt(e.target.value, 10), dynamicOptionKey, option)}
                            defaultValue={minutes}>
                        </input>
                    </div>
                    <select id="ui-mode" className="select2" onChange={event => this.props.hangOnUIModeChanged(event.target.value, dynamicOptionKey, option)} >
                        <option value="">Choose type</option>
                        <option value={constants.ONLINE} selected={uiMode === constants.ONLINE}>Online</option>
                        <option value={constants.AWAY} selected={uiMode === constants.AWAY}>Away</option>
                        <option value={constants.OFFLINE} selected={uiMode === constants.OFFLINE}>Offline</option>
                    </select>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove Hang-On"
                        onClick={() => this.props.removeHangOn(dynamicOptionKey, option)}>
                    </input>
                </div>
            </div>
        </div>
    }

    generateOptionWhereToJSX = (dynamicOptionKey, option) => {

        var next = -1;
        var availability = -1;

        if (option.whereTo) {
            next = option.whereTo.next;
            availability = option.whereTo.availability;
        }

        const availabilityElement = <div className="whereto-availability">
            Availability:<input
                type="short-text"
                pattern="[0-9]*"
                size="10"
                onInput={e => this.props.reactionAvailabilityEntryChanged(parseInt(e.target.value, 10), dynamicOptionKey, option)}
                defaultValue={availability}></input>
        </div>;

        return <div >
            <br />
            <div className="entry-whereto">
                <div className="entry-whereto-elements">
                    <div className="whereto-next">
                        Next:<input
                            type="short-text"
                            pattern="[0-9]*"
                            size="10"
                            onInput={e => this.props.reactionNextEntryChanged(parseInt(e.target.value, 10), dynamicOptionKey, option)}
                            defaultValue={next}>
                        </input>
                    </div>
                    {option.hangOn && availabilityElement}
                </div>
            </div>
        </div>

    }


    generateTransitionWhereToJSX = (whereTo) => {

        var next = -1;
        var availability = -1;

        if (whereTo) {
            next = whereTo.next;
            availability = whereTo.availability;
        }

        return <div>
            WhereTo: (Optional)
                <br />
                Next:<input
                className="entry-transition-input"
                type="text"
                pattern="[0-9]*"
                size="10"
                onInput={e => this.props.transitionNextEntryChanged(e.target.value)}
                defaultValue={next}>
            </input>
            <br />
                Availability:<input
                className="entry-transition-input"
                type="text"
                pattern="[0-9]*"
                size="10"
                onInput={e => this.props.transitionAvailabilityEntryChanged(e.target.value)}
                defaultValue={availability}></input>
            <br />
        </div>
    }

    generateTRExtraText = (item) => {

        var preview = item.text ? item.text : "EMPTY TEXT";

        if (item.imageId && item.imageId !== -1) {
            preview += " (Image: " + item.imageId + ")";
        } else if (item.videoId && item.videoId !== -1) {
            preview += " (Video: " + item.videoId + ")";
        } else if (item.profilePic && item.profilePic !== -1) {
            preview += " (Profile Image: " + item.profilePic + ")";
        } else if (item.locationImageId && item.locationImageId !== -1) {
            preview += " (Location Image: " + item.locationImageId + ")";
        } else if (item.voiceId && item.voiceId !== -1) {
            preview += " (Voice: " + item.voiceId + ")";
        } else if (item.linkImage
            && (item.linkImage.linkId
                && item.linkImage.linkId !== -1
                && item.linkImage.imageId
                && item.linkImage.imageId !== -1)) {
            preview += " (Link: " + item.linkImage.linkId
                + " Image: " + item.linkImage.imageId + ")";
        }

        return preview;
    }

    generateMultiNumbersReactionsJSX = (option, optionIndex) => {

        if (option && option.reactions) {
            const jsx = option.reactions.map((reaction, idx) => {

                const preview = this.generateTRExtraText(reaction);

                return <div key={reaction.id}>
                    <br />
                Reaction {idx + 1}: <input
                        className="entry-transition-input"
                        size="68"
                        defaultValue={preview}
                        onClick={() => this.props.gotoMultiNumbersReactionExtras(constants.MULTI_NUMBERS, reaction, optionIndex)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.removeMultiNumbersReaction(option, reaction)}>
                    </input>
                </div>
            });
            return jsx;
        }

        return <div></div>;

    }

    generateMultiWordsReactionsJSX = (option, optionIndex) => {

        if (option && option.reactions) {
            const jsx = option.reactions.map((reaction, idx) => {

                const preview = this.generateTRExtraText(reaction);

                return <div key={reaction.id}>
                    <br />
                Reaction {idx + 1}: <input
                        className="entry-transition-input"
                        size="68"
                        defaultValue={preview}
                        onClick={() => this.props.gotoMultiWordsReactionExtras(constants.MULTI_WORDS, reaction, optionIndex)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.removeMultiWordsReaction(option, reaction)}>
                    </input>
                </div>
            });
            return jsx;
        }

        return <div></div>;

    }


    generateLocationReactionsJSX = (dynamicReactionsKey, reactions, dynamicOptionKey, option, isYes) => {

        if (option && reactions) {
            const jsx = reactions.map((reaction, idx) => {

                const preview = this.generateTRExtraText(reaction);

                return <div key={reaction.id}>
                    <br />
                Reaction {idx + 1}: <input
                        className="entry-transition-input"
                        size="68"
                        defaultValue={preview}
                        onClick={() => this.props.gotoLocationReactionExtras(dynamicReactionsKey, reaction, isYes)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.removeLocationReaction(dynamicReactionsKey, reactions, reaction, dynamicOptionKey, option)}>
                    </input>
                </div>
            });
            return jsx;
        }

        return <div></div>;

    }

    generateReactionsJSX = (dynamicOptionKey, option) => {

        if (option && option.reactions) {

            const jsx = option.reactions.map((reaction, idx) => {

                const preview = this.generateTRExtraText(reaction);

                return <div key={reaction.id}>
                    <br />
                Reaction {idx + 1}: <input
                        className="entry-transition-input"
                        size="68"
                        defaultValue={preview}
                        onClick={() => this.props.gotoReactionExtras(dynamicOptionKey, reaction)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.removeGeneralReaction(dynamicOptionKey, option, reaction)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="↑"
                        onClick={() => this.props.containerReactionUp(dynamicOptionKey, option, reaction)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="↓"
                        onClick={() => this.props.containerReactionDown(dynamicOptionKey, option, reaction)}>
                    </input>
                </div>
            });
            return jsx;
        }

        return <div></div>;

    }

    generateNameReactionsJSX = (reactions) => {

        if (reactions) {

            const jsx = reactions.map((reaction, idx) => {

                const preview = this.generateTRExtraText(reaction);

                return <div key={reaction.id}>
                    <br />
                Reaction {idx + 1}: <input
                        className="entry-transition-input"
                        size="68"
                        defaultValue={preview}
                        onClick={() => this.props.gotoNameReactionExtras(reaction)}>
                    </input>
                    <input
                        type="button"
                        className="btn-general"
                        id="remove-transition" value="Remove"
                        onClick={() => this.props.removeNameReaction(reaction)}>
                    </input>

                </div>
            });
            return jsx;
        }

        return <div></div>;

    }


    entryDone = () => {

        console.log("[AddEntry] entryDone");

        var alertText = "";

        if (this.props.entry.interaction) {

            const interaction = this.props.entry.interaction;

            //check everything is filled correctly
            if (interaction.type === "choice3") {

                alertText = this.validateOption(alertText, interaction.metadata.option1, "1", true);
                alertText = this.validateOption(alertText, interaction.metadata.option2, "2", true);
                if (interaction.metadata.option3) {
                    alertText = this.validateOption(alertText, interaction.metadata.option3, "3", true);
                }


            } else if (interaction.type === "yesno"
                || interaction.type === "image"
                || interaction.type === "share"
                || interaction.type === "payment") {

                if (!interaction.metadata.text || interaction.metadata.text.length === 0) {
                    alertText += "Please fill interaction text\n";
                }

                alertText = this.validateOption(alertText, interaction.metadata.optionYes, "Yes", false);
                alertText = this.validateOption(alertText, interaction.metadata.optionNo, "No", false);

            } else if (interaction.type === "location") {

                if (!interaction.metadata.text || interaction.metadata.text.length === 0) {
                    alertText += "Please fill interaction text\n";
                }

                alertText = this.validateOption(alertText, interaction.metadata.optionYes, "Location Approval", false);
                alertText = this.validateLocationOption(alertText, interaction.metadata.optionYes.reactionsLogicTrue, "Location Same Country");
                alertText = this.validateLocationOption(alertText, interaction.metadata.optionYes.reactionsLogicFalse, "Location Different Country");
                alertText = this.validateLocationOption(alertText, interaction.metadata.optionYes.reactionsUndetermined, "Location Missing Country");
                alertText = this.validateOption(alertText, interaction.metadata.optionNo, "Location Refusal", false);
            }

        }

        if (alertText) {
            alert(alertText);
        } else {
            this.props.done();
        }
    }

    validateOption = (alertText, option, optionName, shouldValidateReply) => {

        if (shouldValidateReply) {
            if (option.replyData) {
                if (!option.replyData.text || option.replyData.text.length === 0) {
                    alertText += "Please fill option " + optionName + " reply\n";
                }
            } else if (!option.reply || option.reply.length === 0) {
                alertText += "Please fill option " + optionName + " reply\n";
            }

        }

        if (!option.reactions || option.reactions.length === 0) {

            alertText += "Please fill option " + optionName + " reactions\n";

        } else { //have reactions

            var valid = true;

            option.reactions.forEach(reaction => {
                if (reaction.text === "") {
                    if (reaction.imageId === -1
                        && reaction.voiceId === -1
                        && !reaction.linkImage
                        && reaction.locationImageId === -1) {
                        valid = false;
                    }

                }
            });

            if (!valid) {
                alertText += "Please fill option " + optionName + " reactions\n";
            }

        }

        if (option.hangOn) {
            if (option.hangOn.time === -1) {
                alertText += "Please fill option " + optionName + " hang-on time\n";
            }
            if (option.hangOn.uiMode === "") {
                alertText += "Please fill option " + optionName + " hang-on uiMode\n";
            }

        }

        return alertText;
    }

    validateLocationOption = (alertText, reactions, reactionsName) => {

        if (!reactions || reactions.length === 0) {

            alertText += "Please fill " + reactionsName + " reactions\n";

        } else { //have reactionss

            var valid = true;

            reactions.forEach(reaction => {
                if (reaction.text === "") {
                    if (reaction.imageId === -1
                        && reaction.voiceId === -1
                        && reaction.videoId === -1
                        && !reaction.linkImage
                        && reaction.locationImageId === -1) {
                        valid = false;
                    }

                }
            });

            if (!valid) {
                alertText += "Please fill " + reactionsName + " reactions\n";
            }

        }

        return alertText;
    }

    entryCancel = () => {
        console.log("[AddEntry]entryCancel");
        this.props.cancel();
    }

    provideInteractionTypes = (selected) => {

        return <select id="interactionTypes" className="select2" onChange={event => this.props.addInteractionTypeToState(event.target.value)} >
            <option value="" selected={selected === ""}>Choose type</option>
            <option value={constants.CHOICE3_AVAILABILITY} selected={selected === constants.CHOICE3_AVAILABILITY}>Availability</option>
            <option value={constants.CHOICE3} selected={selected === constants.CHOICE3}>Choice 3</option>
            <option value={constants.YES_NO} selected={selected === constants.YES_NO}>Yes / No</option>
            <option value={constants.MULTI_WORDS} selected={selected === constants.MULTI_WORDS}>Multi-words</option>
            <option value={constants.MULTI_NUMBERS} selected={selected === constants.MULTI_NUMBERS}>Multi-numbers</option>
            <option value={constants.NAME} selected={selected === constants.NAME}>Name</option>
            <option value={constants.SHARE} selected={selected === constants.SHARE}>Share</option>
            <option value={constants.PAYMENT} selected={selected === constants.PAYMENT}>Payment</option>
            <option value={constants.IMAGE} selected={selected === constants.IMAGE}>Image</option>
            <option value={constants.LOCATION} selected={selected === constants.LOCATION}>Location</option>
        </select>

    }

    provideAvailabilityTypes = (selected) => {
        return <select id="types" className="select2" onChange={event => this.props.availabilitySelectionChanged(event)} >
            <option value="">Choose type</option>
            <option value={constants.AVAILABILITY_SEE} selected={selected === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_SEE}>See</option>
            <option value={constants.AVAILABILITY_RESPOND} selected={selected === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_RESPOND}>Respond</option>
            <option value={constants.AVAILABILITY_CHAPTER} selected={selected === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_CHAPTER}>Chapter</option>
        </select>
    }
}

export default AddEntry;
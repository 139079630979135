import React, { Component } from 'react';
import './Bae.css';

class Bae extends Component {

  componentDidMount() {
    console.log(this.props);
  }
    

  render() {
    return (

      <div className={this.props.selectedBae ? "baecard" : "baecard-grey"} onClick={this.props.clicked}>
        <div className="baecard_image">
          <img src={this.props.profileImage} alt="cool1"/>
        </div>
      </div>

    );
  }
}

export default Bae;
import React from 'react';
import "./ChapterTranslations.css";
import * as constants from '../../variables/constants';

class ChapterTranslations extends React.Component {

    render() {
        return <div></div>
    }
}

export default ChapterTranslations;
import React, { Component } from 'react';
import './MetadataInvitation.css';
import world from '../../../assets/img/world.jpg';

class MetadataInvitation extends Component {

    render() {
        return (
            <div className="images-container">


                <article class="bae-image-card bae-image-card--2">
                    <div class="bae-image-card__info-hover">
                        <span class="bae-image-card__time" viewBox="0 0 24 24">{0}</span>

                    </div>
                    <div class="bae-image-card__img"></div>
                    <a href={this.props.item} target="_blank" class="bae-image-card_link" style={{ backgroundImage: `url(${world})` }}>
                        <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${world})` }}></div>
                    </a>
                    <div>
                        <span class="bae-image-card__category">{this.props.item}</span>
                        <div class="bae-image-card__title">{this.props.item}</div>
                    </div>
                </article>

            </div>

        );
    }
}

export default MetadataInvitation;
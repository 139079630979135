import React from 'react';
import './ExtrasSelection.css';
import ExtraPreview from '../ExtraPreview/ExtraPreview';
import AddLocation from '../AddExtras/AddLocation/AddLocation';
import AddLinkImage from '../AddExtras/AddLinkImage/AddLinkImage';

class ExtrasSelection extends React.Component {

    state = {
        "type" : this.props.type,
        "extra" : null
    }
    
    render() {

        var extras = null;

        if (this.props.type === "images") {
            extras = this.props.baeMetadata.imagesList.map(item => {
                return <ExtraPreview 
                type={this.props.type}
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.imageExtrasSelection(item)} />
              });
        } else if (this.props.type === "imagesLinkImage") {
            extras = this.props.baeMetadata.imagesList.map(item => {
                return <ExtraPreview 
                type={this.props.type}
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.galleryImageClick("linkImage", item)} />
              });
        } else if (this.props.type === "imagesLocation") {
            extras = this.props.baeMetadata.imagesList.map(item => {
                return <ExtraPreview 
                type={this.props.type}
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.galleryImageClick("location", item)} />
              });
        } else if (this.props.type === "videos") {

            if (this.props.baeMetadata.videos && this.props.baeMetadata.videos.length > 0) {
                extras = this.props.baeMetadata.videos.map(item => {
                    return <ExtraPreview 
                    type={this.props.type}
                    className="preview-image"
                    key={item.id} 
                    item={item}
                    previewClick={() => this.galleryVideoClick(item)} />
                  });
            }
            
        } else if (this.props.type === "voices") {
            extras = this.props.baeMetadata.voices.map(item => {
                return <ExtraPreview 
                type={this.props.type}
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.galleryVoiceClick(item)} />
              });
        } else if (this.props.type === "links") {

            extras = this.props.baeMetadata.links.map(item => {
                return <ExtraPreview 
                type={this.props.type}
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.galleryLinkClick(item)} />
              });

        } else if (this.props.type === "linkImage") {

            extras = <AddLinkImage
            baeMetadata={this.props.baeMetadata}
            linkPreviewClick={(item) => this.toSelectLinkClick(item)}
            imagePreviewClick={() => this.linkImageToSelectImageClick()}
            editedElement={this.props.editedElement}
            linkImageDone={(linkImage) => this.props.linkImageDoneClick(linkImage)}
            linkImageCancel={() => this.props.linkImageCancelClick()}/>

        } else if (this.props.type === "location") {
            
            extras = <AddLocation 
            baeMetadata={this.props.baeMetadata}
            location={this.props.location}
            imagePreviewClick={() => this.locationToSelectImageClick()}
            locationDone={(location) => this.locationExtrasDone(location)}
            locationCancel={() => this.locationExtrasCancel()}/>

        }

        return (
            <div>
            <div align="left">
            <input className="button-gallery" type="button" id="btnCancel" value="X" onClick={this.props.extrasCancel}></input>
            </div>

            <div align="center" className="extras-cards-list">
                {extras}
            </div>
            
            </div>
        );


    }

    locationExtrasDone = (location) => {
        this.props.locationDoneClick(location)
    }

    locationExtrasCancel = () => {
        this.props.locationCancelClick()
    }

    imageExtrasSelection = (item) => {
        this.props.extrasSelectionClick("image",item)
    }

    voiceExtrasSelection = (item) => {
        this.props.extrasSelectionClick("voice",item)
    }


    //link click from linkImage, will show the gallery
    toSelectLinkClick = () => {
        this.props.toSelectLinkClick();
    }

    //image click from linkImage, will show the gallery
    linkImageToSelectImageClick = () => {
        this.props.toSelectImageClick("imagesLinkImage");
    }
    
    //gallery link was clicked
    galleryLinkClick = (item) => {
        this.props.linkSelectionClick(item)
    }

    //gallery link was clicked
    galleryVoiceClick = (item) => {
        this.props.voiceSelectionClick(item)
    }

    //gallery image was clicked
    galleryImageClick = (type, item) => {
        this.props.imageSelectionClick(type, item)
    }


    //gallery video was clicked
    galleryVideoClick = (item) => {
        this.props.videoSelectionClick(item)
    }

    locationToSelectImageClick = () => {
        this.props.toSelectImageClick("imagesLocation");
    }
    
    
}

export default ExtrasSelection;
import React from 'react';
import "./AddExtras.css";
import "./AddLocation/AddLocation";
import * as constants from "../../../variables/constants";

class AddExtras extends React.Component {

    state = {
        condition: null
    }

    render() {

        var text = "";
        if (this.props.editedElement.text) {
            text = this.props.editedElement.text;
        }
        
        var videoToPlace = "https://via.placeholder.com/100";
        var imageToPlace = "https://via.placeholder.com/100";
        var locationImageToPlace = "https://via.placeholder.com/100";

        var isProfilePic = false;
        var imageId = -1;
        var videoId = -1;

        if (this.props.editedElement.imageId !== -1) {

            const imageSource = this.props.baeMetadata.imagesList.filter(item => {
                return item.id === this.props.editedElement.imageId
            });

            if (imageSource.length === 1) {
                if (imageSource[0]) {
                    imageToPlace = imageSource[0].url;
                    imageId = imageSource[0].id;
                }

            }
        } else if (this.props.editedElement.profilePic !== -1) {

            const imageSource = this.props.baeMetadata.imagesList.filter(item => {
                return item.id === this.props.editedElement.profilePic
            });

            if (imageSource.length === 1) {
                if (imageSource[0]) {
                    isProfilePic = true;
                    imageToPlace = imageSource[0].url;
                    imageId = imageSource[0].id;
                }

            }
        } else if (this.props.editedElement.locationImageId !== -1) {

            const locationImageSource = this.props.baeMetadata.imagesList.filter(item => {
                return item.id === this.props.editedElement.locationImageId
            });

            if (locationImageSource.length === 1) {
                if (locationImageSource[0]) {
                    locationImageToPlace = locationImageSource[0].url;
                    imageId = locationImageSource[0].id;
                }
            }
        } else if (this.props.editedElement.videoId !== -1 && this.props.baeMetadata.videos) {

            if (this.props.baeMetadata.videos.length > 0) {
                const videoSource = this.props.baeMetadata.videos.filter(item => {
                    return item.id === this.props.editedElement.videoId
                });
    
                if (videoSource.length === 1) {
                    if (videoSource[0]) {
                        videoToPlace = videoSource[0].thumbnail;
                        videoId = videoSource[0].id;
                    }
    
                }
            }
            
        }


        var voiceURL = "";
        if (this.props.editedElement.voiceId !== -1) {

            const voiceSource = this.props.baeMetadata.voices.filter(item => {
                return item.id === this.props.editedElement.voiceId
            });

            if (voiceSource.length === 1) {
                if (voiceSource[0]) {
                    voiceURL = voiceSource[0].filename;
                }

            }
        }

        const linkImage = this.props.editedElement.linkImage;
        var linkImageText = "";
        if (linkImage) {
            if (linkImage.linkId !== -1) {
                linkImageText = "Link: " + linkImage.linkId;
            }
            if (linkImage.imageId !== -1) {
                if (linkImageText === "") {
                    linkImageText += "Image: " + linkImage.imageId;
                } else {
                    linkImageText += " Image: " + linkImage.imageId;
                }
            }
        }

        var conditions = null;
        if (this.props.baeKeys) {

            conditions = this.props.baeKeys.map( entryKey => {
                
                if (this.props.editedElement.conditions) {
                    const condition = this.props.editedElement.conditions[0];
                    return <option value={entryKey} selected={condition && condition === entryKey}>{entryKey}</option>
                } else {
                    return <option value={entryKey}>{entryKey}</option>
                }
                
                
            });
        }

        var key = "";
        if (this.props.editedElement.keys && this.props.editedElement.keys.length > 0) {
            key = this.props.editedElement.keys[0];
        }

        return (
            <div>
                <div id="entry-elements">
                    Current Element:
                <br />
                    <br />
                    <input
                        placeholder="Enter text..."
                        type="text"
                        size="80" value={text}
                        text={this.props.editedElement.text}
                        onChange={e => this.props.extraTextChanged(e.target.value)}></input>
                    <br />
                    <br />
                    <input
                        type="text"
                        placeholder="Select voice..."
                        size="80"
                        defaultValue={voiceURL}
                        onClick={() => this.props.extrasSelectionClick("voices")}>
                    </input>
                    <br />
                    <br />
                    <input
                        type="text"
                        placeholder="Select linkimage..."
                        size="80"
                        defaultValue={linkImageText}
                        onClick={() => this.props.extrasSelectionClick("linkImage")}>
                    </input>
                    <br />
                    <br />
                    <table>
                        <tr>
                            <td>Image:</td>
                            <td><img className="imageBorder" src={imageToPlace} width={150} height={150} onClick={() => this.props.extrasSelectionClick("images")} /></td>
                            <td>
                                <input
                                type="checkbox"
                                checked={isProfilePic}
                                onChange={(event) => this.props.profilePicStateChanged(event.target.checked, imageId)}
                            /> Profile Picture</td>
                        </tr>
                        <br />
                        <tr>
                            <td>Video:</td>
                            <td><img className="imageBorder" src={videoToPlace} width={150} height={150} onClick={() => this.props.extrasSelectionClick("videos")} /></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Location:</td>
                            <td><img className="imageBorder" src={locationImageToPlace} width={150} height={150} onClick={() => this.props.extrasSelectionClick("imagesLocation")} /></td>
                        </tr>
                    </table>
                    <input
                        placeholder="Enter keys..."
                        type="text"
                        size="80"
                        value={key}
                        onChange={e => this.props.entryKeyChanged(e.target.value)}></input>
                    <br />
                    <br />
                    <select id="types" onChange={event => this.conditionSelected(event.target.value)} >
                        <option value="">Choose condition</option>
                        {conditions}
                    </select>
                    <br />
                    <br />
                </div>
                <br />
                <input type="button" className="btn-done" id="btnDone" value="DONE" onClick={() => this.extrasDone()}></input>
                <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={() => this.extrasCancel()}></input>
            </div>
        );
    }

    conditionSelected = (condition) => {

        if (!condition) {
            this.props.editedElement.conditions = null;
        }
        
        this.setState({
            condition : condition
        })
    }

    extrasCancel = () => {
        this.props.cancelExtrasClick();
    }

    extrasDone = () => {

        if (this.props.editedElement.type === constants.TRANSITION) {

            this.props.doneTransitionExtrasClick(this.props.editedElement, this.state.condition);

        } else if (this.props.editedElement.type === constants.REACTION) {

            const entry = this.props.entry;

            if (entry.interaction.type === constants.NAME) {

                this.props.doneNameRectionExtrasClick(this.props.editedElement, this.state.condition);

            } else if (entry.interaction.type === constants.MULTI_WORDS) {

                const option = entry.interaction.metadata.options[this.props.editedElement.index];

                this.props.doneMultiWordsRectionExtrasClick(this.props.editedElement, option, this.state.condition);

            } else if (entry.interaction.type === constants.MULTI_NUMBERS) {

                const option = entry.interaction.metadata.options[this.props.editedElement.index];

                this.props.doneMultiWordsRectionExtrasClick(this.props.editedElement, option, this.state.condition);

            } else if (entry.interaction.type === constants.LOCATION) {

                var dynamicOptionKey = "";
                var dynamicReactionsKey = this.props.editedElement.answerType;
                var reactions = null;


                var option = null;
                if (dynamicReactionsKey === constants.REACTIONS) {

                    if (this.props.editedElement.isYes) {
                        dynamicOptionKey = constants.OPTION_YES;
                        option = entry.interaction.metadata.optionYes;
                        reactions = entry.interaction.metadata.optionYes.reactions;
                    } else {
                        dynamicOptionKey = constants.OPTION_NO;
                        option = entry.interaction.metadata.optionNo;
                        reactions = entry.interaction.metadata.optionNo.reactions;
                    }

                } else if (dynamicReactionsKey === constants.REACTIONS_LOGIC_TRUE) {

                    dynamicOptionKey = constants.OPTION_YES;
                    option = entry.interaction.metadata.optionYes;
                    reactions = entry.interaction.metadata.optionYes.reactionsLogicTrue;

                } else if (dynamicReactionsKey === constants.REACTIONS_LOGIC_FALSE) {

                    dynamicOptionKey = constants.OPTION_YES;
                    option = entry.interaction.metadata.optionYes;
                    reactions = entry.interaction.metadata.optionYes.reactionsLogicFalse;

                } else if (dynamicReactionsKey === constants.REACTIONS_UNDETERMINED) {

                    dynamicOptionKey = constants.OPTION_YES;
                    option = entry.interaction.metadata.optionYes;
                    reactions = entry.interaction.metadata.optionYes.reactionsUndetermined;

                } else { //no

                    dynamicOptionKey = constants.OPTION_NO;
                    option = entry.interaction.metadata.optionNo;
                    reactions = entry.interaction.metadata.optionNo.reactions;

                }


                this.props.doneLocationRectionExtrasClick(this.props.editedElement, dynamicReactionsKey, reactions, dynamicOptionKey, option, this.state.condition);

            } else {

                var dynamicOptionKey = "";

                if (entry.interaction.type === constants.CHOICE3) {

                    dynamicOptionKey = this.props.editedElement.answerType;

                    var option = null;
                    if (dynamicOptionKey === constants.OPTION1) {
                        option = entry.interaction.metadata.option1;
                    } else if (dynamicOptionKey === constants.OPTION2) {
                        option = entry.interaction.metadata.option2;
                    } else if (dynamicOptionKey === constants.OPTION3) {
                        option = entry.interaction.metadata.option3;
                    }
                } else if (entry.interaction.type === constants.YES_NO
                    || entry.interaction.type === constants.PAYMENT
                    || entry.interaction.type === constants.SHARE
                    || entry.interaction.type === constants.IMAGE) {

                    dynamicOptionKey = this.props.editedElement.answerType;

                    var option = null;
                    if (dynamicOptionKey === constants.OPTION_YES) {
                        option = entry.interaction.metadata.optionYes;
                    } else if (dynamicOptionKey === constants.OPTION_NO) {
                        option = entry.interaction.metadata.optionNo;
                    }
                }
                this.props.doneGeneralRectionExtrasClick(this.props.editedElement, option.reactions, dynamicOptionKey, option, this.state.condition);
            }
        }

    }

    extrasCancel = () => {
        console.log("extrasCancel");
        this.props.cancelExtrasClick();
    }
}

export default AddExtras;
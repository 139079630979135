import React, { Component } from 'react';
import * as constants from '../../variables/constants';

const storyLeftCoordinate = 0;
const centerCoordinate = 500;
const storyRightCoordinate = 1000;

const yesCoordinate = 200;
const noCoordinate = 700;

var availabilityLeftCoordinate = 1300;
var availabilityCenterCoordinate = 1800;
var availabilityRightCoordinate = 2300;

const yInitial = 100;
const ySpace = 200;
const EMPTY_TEXT = "EMPTY TEXT";

var tipsId = 1;

var lastAvailability = null;
var lastEntry = null;
var entry = null;

var lastEntryWithHangOn = null;
var lastHangOnNode = null;
var lastHangOn = null;

var lastAvailabilityAnswer1Node = null;
var lastAvailabilityAnswer2Node = null;
var lastAvailabilityAnswer3Node = null;



var lastReactionsTips = [];
var nodes = [];
var edges = [];

var nodesWithEntryId = [];

class ChapterGraphUtil {
    initData = () => {

        tipsId = 1;

        lastAvailability = null;
        lastEntry = null;
        entry = null;

        lastEntryWithHangOn = null;
        lastHangOnNode = null;
        lastHangOn = null;

        lastAvailabilityAnswer1Node = null;
        lastAvailabilityAnswer2Node = null;
        lastAvailabilityAnswer3Node = null;

        availabilityLeftCoordinate = 3800;
        availabilityCenterCoordinate = 4300;
        availabilityRightCoordinate = 4800;

        lastReactionsTips = [];
        nodes = [];
        edges = [];
    }

    createGraph = (entries) => {

        var id = 1;

        var currentY = yInitial;

        entries.forEach(item => {

            var currentNodes = [];

            entry = item;

            const transitions = entry.transitions;

            if (transitions) {

                var x = 0;

                var firstTransition = true;
                transitions.forEach(transition => {

                    var lastNode = null;

                    if (currentNodes.length > 0) {
                        lastNode = currentNodes[currentNodes.length - 1];
                    }

                    if (entry.interaction) {
                        if (this.isAvailability(entry.interaction.type)) { //this transition for avaiability would actually be empty
                            x = availabilityCenterCoordinate;
                        } else {
                            currentY += ySpace
                            x = centerCoordinate;
                        }
                    } else {
                        currentY += ySpace
                        x = centerCoordinate;
                    }


                    const currentNode = this.generateNode(id++, transition.text, x, currentY, "special", "transition", transition, entry);
                    currentNodes.push(currentNode);

                    //checking if need to connect it to last entry with hang on
                    if (lastEntryWithHangOn) {

                        if (lastHangOn.uiMode !== "OFFLINE") {
                            if (lastEntryWithHangOn.interaction) {
                                if (lastEntryWithHangOn.interaction.type === "Question-Image"
                                    || lastEntryWithHangOn.interaction.type === "Question-YesNo"
                                    || lastEntryWithHangOn.interaction.type === "image"
                                    || lastEntryWithHangOn.interaction.type === "yesno") { //yesno

                                    //need to find the hangOn
                                    if (lastEntryWithHangOn.interaction.metadata.optionYes) {
                                        if (lastEntryWithHangOn.interaction.metadata.optionYes.whereTo.next === entry.id) {
                                            if (lastEntryWithHangOn.interaction.metadata.optionYes.hangOn) {
                                                //need to connect this transition to last hangOn node
                                                const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                                edges.push(edge);
                                            }

                                        }

                                    }

                                    if (lastEntryWithHangOn.interaction.metadata.optionNo) {
                                        if (lastEntryWithHangOn.interaction.metadata.optionNo.whereTo.next === entry.id) {
                                            if (lastEntryWithHangOn.interaction.metadata.optionNo.hangOn) {
                                                //need to connect this transition to last hangOn node
                                                const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                                edges.push(edge);
                                            }

                                        }
                                    }

                                    //story
                                } else if (lastEntryWithHangOn.interaction.type === "Story-Conversation"
                                    || lastEntryWithHangOn.interaction.type === "choice3") {

                                    if (firstTransition) {

                                        if (lastEntryWithHangOn.interaction.metadata.option1) {
                                            if (lastEntryWithHangOn.interaction.metadata.option1.whereTo.next === entry.id) {
                                                if (lastEntryWithHangOn.interaction.metadata.option1.hangOn) {
                                                    //need to connect this transition to last hangOn node
                                                    const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                                    edges.push(edge);

                                                    lastHangOnNode = null;
                                                    lastEntryWithHangOn = null;
                                                    lastHangOn = null;
                                                }


                                            }
                                        } else if (lastEntryWithHangOn.interaction.metadata.option2) {
                                            if (lastEntryWithHangOn.interaction.metadata.option2.whereTo.next === entry.id) {
                                                if (lastEntryWithHangOn.interaction.metadata.option2.hangOn) {
                                                    //need to connect this transition to last hangOn node
                                                    const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                                    edges.push(edge);

                                                    lastHangOnNode = null;
                                                    lastEntryWithHangOn = null;
                                                    lastHangOn = null;
                                                }

                                            }
                                        } else if (lastEntryWithHangOn.interaction.metadata.option3) {
                                            if (lastEntryWithHangOn.interaction.metadata.option3.whereTo.next === entry.id) {
                                                if (lastEntryWithHangOn.interaction.metadata.option3.hangOn) {
                                                    //need to connect this transition to last hangOn node
                                                    const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                                    edges.push(edge);

                                                    lastHangOnNode = null;
                                                    lastEntryWithHangOn = null;
                                                    lastHangOn = null;
                                                }

                                            }
                                        }




                                    }

                                }



                            } else if (lastEntryWithHangOn.whereTo.next === entry.id) {
                                //connect
                                const edge = this.generateEdge(lastHangOnNode.id, currentNode.id, "emptyEdge", lastHangOnNode, currentNode);
                                edges.push(edge);

                                lastHangOnNode = null;
                                lastEntryWithHangOn = null;
                                lastHangOn = null;
                            }
                        }

                    }

                    if (lastAvailability) {
                        if (lastAvailability.interaction.type === "Story-Respond") {

                            if (lastAvailability.interaction.metadata.option1) {
                                if (lastAvailability.interaction.metadata.option1.whereTo.next === entry.id) {
                                    //need to connect this transition to last hangOn node

                                    if (lastAvailabilityAnswer1Node) {
                                        const edge = this.generateEdge(lastAvailabilityAnswer1Node.id, currentNode.id, "emptyEdge", lastAvailabilityAnswer1Node, currentNode);
                                        edges.push(edge);

                                        lastAvailabilityAnswer1Node = null;
                                    }

                                }
                            }

                            if (lastAvailability.interaction.metadata.option2) {
                                if (lastAvailability.interaction.metadata.option2.whereTo.next === entry.id) {

                                    if (lastAvailabilityAnswer2Node) {
                                        //need to connect this transition to last hangOn node
                                        const edge = this.generateEdge(lastAvailabilityAnswer2Node.id, currentNode.id, "emptyEdge", lastAvailabilityAnswer2Node, currentNode);
                                        edges.push(edge);

                                        lastAvailabilityAnswer2Node = null;
                                    }

                                }
                            }

                            if (lastAvailability.interaction.metadata.option3) {
                                if (lastAvailability.interaction.metadata.option3.whereTo.next === entry.id) {

                                    if (lastAvailabilityAnswer3Node) {
                                        //need to connect this transition to last hangOn node
                                        const edge = this.generateEdge(lastAvailabilityAnswer3Node.id, currentNode.id, "emptyEdge", lastAvailabilityAnswer3Node, currentNode);
                                        edges.push(edge);

                                        lastAvailabilityAnswer3Node = null;
                                    }

                                }
                            }

                            //get rid of this only if took care of all nodes for this availability respond entry
                            if (!lastAvailabilityAnswer1Node && !lastAvailabilityAnswer2Node && !lastAvailabilityAnswer3Node) {
                                lastAvailability = null;
                            }

                        }


                    }


                    if (currentNodes.length > 1) { //have more than 1 node

                        const edge = this.generateEdge(lastNode.id, currentNode.id, "emptyEdge", lastNode, currentNode);
                        edges.push(edge);


                    } else { //this is the first transition node for this entry

                        //checking to see if need to connect the first transition to a previous node

                        this.iterateTipsForEdge(currentNode);
                    }

                    firstTransition = false;
                });

                const hangOn = entry.hangOn

                if (hangOn) {

                    const lastNode = currentNodes[currentNodes.length - 1];

                    currentY += ySpace

                    const hangOnText = "hangOn-" + hangOn.uiMode + " " + hangOn.time / 1000 / 60 + " Minutes";

                    const hangOnNode = this.generateNode(id++, hangOnText, x, currentY, "special", "hangOn-" + hangOn.uiMode, hangOn, entry);
                    currentNodes.push(hangOnNode);

                    lastEntryWithHangOn = entry;
                    lastHangOnNode = hangOnNode;
                    lastHangOn = hangOn;

                    const edge = this.generateEdge(lastNode.id, hangOnNode.id, "emptyEdge", lastNode, hangOnNode);
                    edges.push(edge);

                }

                if (entry.interaction) {

                    const result = this.handleInteraction(entries, entry, currentNodes, id, currentY);
                    id = result.id;
                    currentY = result.currentY;

                } else { //no interaction, only transitions

                    if (entry.whereTo) {
                        //saving the last transition to be connected when the next transition will be shown
                        this.addLastReactionTip(entry.whereTo, currentNodes[currentNodes.length - 1]);
                    }

                }


            } else if (entry.interaction) { //no transitions
                const result = this.handleInteraction(entries, entry, currentNodes, id, currentY);
                id = result.id;
                currentY = result.currentY;
            } else {

            }


            if (entry.interaction) {
                if (this.isAvailability(entry.interaction.type)) {
                    lastAvailability = entry;
                } else {
                    lastEntry = entry;
                }
            } else {
                lastEntry = entry;
            }



            //console.log("nodes for entry " + entry.id);
            //console.log(currentNodes);

            //not doing anything with this yet
            nodesWithEntryId.push({
                entryId : entry.id,
                nodes : currentNodes
            })

            currentNodes.forEach(node => {
                nodes.push(node);
            });

        });

        return { nodes, edges };
    }


    handleInteraction = (entries, entry, currentNodes, id, currentY) => {

        if (entry.interaction.type === "Question-Name"
            || entry.interaction.type === constants.NAME) {

            var lastNode = null;

            if (entry.transitions) { //if have transitions, have them inside currentNodes
                if (currentNodes.length > 0) {
                    lastNode = currentNodes[currentNodes.length - 1];
                }
            } else if (lastReactionsTips.length > 0) { //no transitions, current nodes is empty 
                lastNode = lastReactionsTips[lastReactionsTips.length - 1].node;  //WHY?! should search for the right one!!!
            }

            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, centerCoordinate, currentY += ySpace, "special", "interaction", entry.interaction, entry);

            currentNodes.push(interactionNode);

            this.iterateTipsForEdge(interactionNode);

            if (currentNodes.length > 1) {

                const edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);
            }


            entry.interaction.metadata.reactions.forEach(reaction => {

                lastNode = null;

                if (currentNodes.length > 0) {
                    lastNode = currentNodes[currentNodes.length - 1];
                }

                const reactionNode = this.generateNode(id++, reaction.text, centerCoordinate, currentY += ySpace, "special", "reaction", reaction, entry);


                currentNodes.push(reactionNode);

                if (currentNodes.length > 1) {

                    const edge = this.generateEdge(lastNode.id, reactionNode.id, "emptyEdge", lastNode, reactionNode);

                    edges.push(edge);
                }

            })

            this.addLastReactionTip(entry.interaction.metadata.whereTo, currentNodes[currentNodes.length - 1]);


        } else if (entry.interaction.type === "Story-Conversation"
            || entry.interaction.type === "choice3") {

            var lastNode = null;

            if (entry.transitions) { //if have transitions, have them inside currentNodes
                if (currentNodes.length > 0) {
                    lastNode = currentNodes[currentNodes.length - 1];
                }
            } else if (lastReactionsTips.length > 0) { //no transitions, current nodes is empty 
                lastNode = lastReactionsTips[lastReactionsTips.length - 1].node;  //WHY?! should search for the right one!!!
            }


            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, centerCoordinate, currentY += ySpace, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            this.iterateTipsForEdge(interactionNode);

            if (currentNodes.length > 1) { //have nothing upwards to connect to other than the interaction

                const edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);

            } else { //this is the first transition node for this entry

                if (lastReactionsTips.length > 0) { //every one of these need to connect to the following node

                    var tipsCopy = [...lastReactionsTips];

                    lastReactionsTips.forEach(lastReactionTip => {

                        if (lastReactionTip.whereTo.next === entry.id) {
                            const edge = this.generateEdge(lastReactionTip.node.id, interactionNode.id, "emptyEdge", lastReactionTip.node, interactionNode);
                            edges.push(edge);

                            tipsCopy = tipsCopy.filter(tip => {
                                return tip.id !== lastReactionTip.id;
                            })
                        }

                    });

                    lastReactionsTips = tipsCopy;

                }
            }

            var currentY1 = currentY;
            var currentY2 = currentY;
            var currentY3 = currentY;

            const option1 = entry.interaction.metadata.option1;
            if (option1) {

                const result = this.handleOption(option1, 1, id, currentNodes, interactionNode, currentY, entry, entries);

                currentY1 = result.currentY;
                id = result.id;
                currentNodes = result.currentNodes;

            }


            const option2 = entry.interaction.metadata.option2;
            if (option2) {

                const result = this.handleOption(option2, 2, id, currentNodes, interactionNode, currentY, entry, entries);

                currentY2 = result.currentY;
                id = result.id;
                currentNodes = result.currentNodes;
            }

            const option3 = entry.interaction.metadata.option3;
            if (option3) {

                const result = this.handleOption(option3, 3, id, currentNodes, interactionNode, currentY, entry, entries);

                currentY3 = result.currentY;
                id = result.id;
                currentNodes = result.currentNodes;
            }

            if (currentY1 >= currentY2 && currentY1 >= currentY3) {
                currentY = currentY1;
            } else if (currentY2 >= currentY1 && currentY2 >= currentY3) {
                currentY = currentY2;
            } else if (currentY3 >= currentY1 && currentY3 >= currentY2) {
                currentY = currentY3;
            }


        } else if (this.isAvailability(entry.interaction.type)) {

            const yBeforeAvailability = currentY;

            currentY += ySpace;

            const transitionNode = currentNodes[currentNodes.length - 1];

            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, availabilityCenterCoordinate, currentY, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            if (currentNodes.length > 0) { //we're doing an interaction, there's always only 1 interaction so current nodes should have a transition


                const edge = this.generateEdge(transitionNode.id, interactionNode.id, "emptyEdge", transitionNode, interactionNode);
                edges.push(edge);

            } else {
                this.iterateTipsForEdge(interactionNode);
            }


            currentY += ySpace;

            const option1 = entry.interaction.metadata.option1;
            if (option1) {

                const result = this.handleAvailabilityOption(option1, 1, id, currentNodes, interactionNode, currentY, entry);

                id = result.id;
                currentNodes = result.currentNodes;
                lastAvailabilityAnswer1Node = result.optionReplyNode;
            }


            const option2 = entry.interaction.metadata.option2;
            if (option2) {

                const result = this.handleAvailabilityOption(option2, 2, id, currentNodes, interactionNode, currentY, entry);

                id = result.id;
                currentNodes = result.currentNodes;
                lastAvailabilityAnswer2Node = result.optionReplyNode;
            }

            const option3 = entry.interaction.metadata.option3;
            if (option3) {

                const result = this.handleAvailabilityOption(option3, 3, id, currentNodes, interactionNode, currentY, entry);

                id = result.id;
                currentNodes = result.currentNodes;
                lastAvailabilityAnswer3Node = result.optionReplyNode;
            }

            currentY = yBeforeAvailability;


        } else if (entry.interaction.type === "Question-MultiselectionString"
            || entry.interaction.type === "multiselectionString"
            || entry.interaction.type === constants.MULTI_WORDS) {

            var lastNode = null;

            if (entry.transitions.length > 0) { //if have transitions, have them inside currentNodes
                if (currentNodes.length > 0) {
                    lastNode = currentNodes[currentNodes.length - 1];
                }
            } else if (lastReactionsTips.length > 0) { //no transitions, current nodes is empty 
                lastNode = lastReactionsTips[lastReactionsTips.length - 1].node;  //WHY?! should search for the right one!!!
            }

            currentY += ySpace;

            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, centerCoordinate, currentY, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            //no last node if this is the first entry for this chapter, and no transitions
            if (lastNode) {
                const edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);
            }

            const options = entry.interaction.metadata.options;

            if (options) {

                var extraXs = [];

                var basePlus = centerCoordinate + 300;
                var baseMinus = centerCoordinate - 300;

                var isPlus = false;

                options.forEach(option => {
                    if (isPlus) {
                        extraXs.push(basePlus);
                        basePlus += 500;
                    } else {
                        extraXs.push(baseMinus);
                        baseMinus -= 500;
                    }
                    isPlus = !isPlus;
                });

                currentY += ySpace;
                var maxReactions = 0;

                const lastNode = currentNodes[currentNodes.length - 1];

                options.forEach((option, i) => {

                    var firstOptionY = currentY;

                    var answer = option.answers[0];

                    var targetX = 0;
                    if (extraXs.length > 0) {
                        targetX = extraXs[0];
                        extraXs.splice(0, 1); //removing first element
                    }

                    const rangeNode = this.generateNode(id++, answer, targetX, firstOptionY, "special", "multiStringOption", option, entry);
                    currentNodes.push(rangeNode);

                    const edge = this.generateEdge(lastNode.id, rangeNode.id, "emptyEdge", lastNode, rangeNode);
                    edges.push(edge);

                    option.reactions.forEach((reaction, i) => {

                        const lastReactionNode = currentNodes[currentNodes.length - 1];

                        firstOptionY += ySpace;

                        const optionReactionNode = this.generateNode(id++, reaction.text, targetX, firstOptionY, "special", "multiStringReaction", reaction, entry);
                        currentNodes.push(optionReactionNode);


                        const edge = this.generateEdge(lastReactionNode.id, optionReactionNode.id, "emptyEdge", lastReactionNode, optionReactionNode);
                        edges.push(edge);

                        if (i > maxReactions) {
                            maxReactions = i;
                        }
                    })

                    var nodeToConnect = null;
                    var lastReactionForOption = currentNodes[currentNodes.length - 1];

                    //check case that whereTo points to previous entry id

                    //traverse nodes and find node for first transition of all option's last reaction

                    for (i = 0; i < nodes.length; i++) {
                        const node = nodes[i];
                        if (node.dataType === "transition") {
                            if (node.entry.id === option.whereTo.next) {
                                nodeToConnect = node;
                                break;
                            }
                        }
                    }

                    if (nodeToConnect) { //found and connected it

                        const edge = this.generateEdge(lastReactionForOption.id, nodeToConnect.id, "emptyEdge", lastReactionForOption, nodeToConnect);
                        edges.push(edge);

                    } else {
                        this.addLastReactionTip(option.whereTo, lastReactionForOption);
                    }

                    const hangOn = option.hangOn;

                    // if (hangOn) {

                    //     const lastNode = currentNodes[currentNodes.length - 1];

                    //     currentYNo += ySpace

                    //     const hangOnText = "hangOn-" + hangOn.uiMode + " " + hangOn.time / 1000 / 60 + " Minutes";

                    //     firstOptionY += ySpace;

                    //     const hangOnNode = this.generateNode(id++, hangOnText, targetX, firstOptionY, "special", "hangOn-" + hangOn.uiMode, hangOn, entry.id);
                    //     currentNodes.push(hangOnNode);

                    //     lastEntryWithHangOn = entry;
                    //     lastHangOnNode = hangOnNode;
                    //     lastHangOn = hangOn;

                    //     const edge = this.generateEdge(lastNode.id, hangOnNode.id, "emptyEdge", lastNode, hangOnNode);
                    //     edges.push(edge);

                    // }
                });

                currentY = ((maxReactions + 1) * ySpace) + currentY;
            }

        } else if (entry.interaction.type === "Question-MultiselectionNumber"
            || entry.interaction.type === "multiselectionNumber"
            || entry.interaction.type === constants.MULTI_NUMBERS) {

            var lastNode = null;

            if (entry.transitions.length > 0) { //if have transitions, have them inside currentNodes
                if (currentNodes.length > 0) {
                    lastNode = currentNodes[currentNodes.length - 1];
                }
            } else if (lastReactionsTips.length > 0) { //no transitions, current nodes is empty 
                lastNode = lastReactionsTips[lastReactionsTips.length - 1].node;  //WHY?! should search for the right one!!!
            }

            currentY += ySpace;

            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, centerCoordinate, currentY, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            //no last node if this is the first entry for this chapter, and no transitions
            if (lastNode) {
                const edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);
            }

            const numberOptions = entry.interaction.metadata.options;

            if (numberOptions) {

                var extraXs = [];

                var basePlus = centerCoordinate + 300;
                var baseMinus = centerCoordinate - 300;

                var isPlus = false;

                numberOptions.forEach(option => {
                    if (isPlus) {
                        extraXs.push(basePlus);
                        basePlus += 500;
                    } else {
                        extraXs.push(baseMinus);
                        baseMinus -= 500;
                    }
                    isPlus = !isPlus;
                });


                currentY += ySpace;
                var maxReactions = 0;

                const lastNode = currentNodes[currentNodes.length - 1];

                numberOptions.forEach((option, i) => {

                    var firstOptionY = currentY;

                    var rangeText = option.range[0];
                    if (option.range[0] !== option.range[1]) {
                        rangeText = option.range[0] + "-" + option.range[1];
                    }

                    var targetX = 0;
                    if (extraXs.length > 0) {
                        targetX = extraXs[0];
                        extraXs.splice(0, 1); //removing first element
                    }

                    const rangeNode = this.generateNode(id++, rangeText, targetX, firstOptionY, "special", "rangeOption", option, entry);
                    currentNodes.push(rangeNode);

                    const edge = this.generateEdge(lastNode.id, rangeNode.id, "emptyEdge", lastNode, rangeNode);
                    edges.push(edge);

                    option.reactions.forEach((reaction, i) => {

                        const lastReactionNode = currentNodes[currentNodes.length - 1];

                        firstOptionY += ySpace;

                        const optionReactionNode = this.generateNode(id++, reaction.text, targetX, firstOptionY, "special", "rangeNumberReaction", reaction, entry);
                        currentNodes.push(optionReactionNode);


                        const edge = this.generateEdge(lastReactionNode.id, optionReactionNode.id, "emptyEdge", lastReactionNode, optionReactionNode);
                        edges.push(edge);

                        if (i > maxReactions) {
                            maxReactions = i;
                        }
                    })

                    var nodeToConnect = null;
                    var lastReactionForOption = currentNodes[currentNodes.length - 1];

                    //check case that whereTo points to previous entry id

                    //traverse nodes and find node for first transition of all option's last reaction

                    for (i = 0; i < nodes.length; i++) {
                        const node = nodes[i];
                        if (node.dataType === constants.TRANSITION) {
                            if (node.entry.id === option.whereTo.next) {
                                nodeToConnect = node;
                                break;
                            }
                        }
                    }

                    if (nodeToConnect) { //found and connected it

                        const edge = this.generateEdge(lastReactionForOption.id, nodeToConnect.id, "emptyEdge", lastReactionForOption, nodeToConnect);
                        edges.push(edge);

                    } else {
                        this.addLastReactionTip(option.whereTo, lastReactionForOption);
                    }

                });
                currentY = ((maxReactions + 1) * ySpace) + currentY;
            }

        } else if (entry.interaction.type === "Question-Location"
            || entry.interaction.type === constants.LOCATION) {

            var maxReactions = 0;

            currentY += ySpace;

            var lastNode = null;

            if (currentNodes.length > 0) {
                lastNode = currentNodes[currentNodes.length - 1];
            }

            const interactionNode = this.generateNode(id++, entry.interaction.metadata.text, centerCoordinate, currentY, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            if (lastNode) {
                var edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);
            }

            this.iterateTipsForEdge(interactionNode);

            var noY = currentY;
            var yesY = currentY;

            const optionNo = entry.interaction.metadata.optionNo;
            const noNode = this.generateNode(id++, "Decline", noCoordinate + 500, noY += ySpace, "special", "locationNo", optionNo, entry);
            currentNodes.push(noNode);
            const edgeNo = this.generateEdge(interactionNode.id, noNode.id, "emptyEdge", interactionNode, noNode);
            edges.push(edgeNo);

            const noReactions = entry.interaction.metadata.optionNo.reactions;
            noReactions.forEach(reaction => {

                const lastReactionNode = currentNodes[currentNodes.length - 1];

                const noReactionNode = this.generateNode(id++, reaction.text, noCoordinate + 500, noY += ySpace, "special", "locationNoReaction", reaction, entry);
                currentNodes.push(noReactionNode);

                const edgeNoReaction = this.generateEdge(lastReactionNode.id, noReactionNode.id, "emptyEdge", lastReactionNode, noReactionNode);
                edges.push(edgeNoReaction);
            });
            const lastNoReactionNode = currentNodes[currentNodes.length - 1];

            this.addLastReactionTip(optionNo.whereTo, lastNoReactionNode);



            //YES LOGIC

            const locationOptionYes = entry.interaction.metadata.optionYes;
            const yesNode = this.generateNode(id++, "Approve", yesCoordinate - 500, yesY += ySpace, "special", "locationYes", optionYes, entry);
            currentNodes.push(yesNode);
            const edgeYes = this.generateEdge(interactionNode.id, yesNode.id, "emptyEdge", interactionNode, yesNode);
            edges.push(edgeYes);

            const generalReactions = locationOptionYes.reactions;
            var lastGeneralReactionNode = currentNodes[currentNodes.length - 1]; //first time it's the transition for this location interaction

            var generalY = yesY;
            generalReactions.forEach(reaction => {

                const generalReactionNode = this.generateNode(id++, reaction.text, yesCoordinate - 500, generalY += ySpace, "special", "locationGeneralReaction", reaction, entry);
                currentNodes.push(generalReactionNode);

                const edgeYesReaction = this.generateEdge(lastGeneralReactionNode.id, generalReactionNode.id, "emptyEdge", lastGeneralReactionNode, generalReactionNode);
                edges.push(edgeYesReaction);

                lastGeneralReactionNode = currentNodes[currentNodes.length - 1];
            });

            var falseGeneralY = generalY;

            const logicFalseReactions = locationOptionYes.reactionsLogicFalse;

            var firstReaction = true;
            var logicFalseNode = null;
            var lastReactionNode = null;

            logicFalseReactions.forEach(reaction => {

                logicFalseNode = this.generateNode(id++, reaction.text, yesCoordinate - 1500, falseGeneralY += ySpace, "special", "locationLogicFalseReaction", reaction, entry);
                currentNodes.push(logicFalseNode);

                if (firstReaction) {
                    firstReaction = false;

                    const edgeYesReaction = this.generateEdge(lastGeneralReactionNode.id, logicFalseNode.id, "emptyEdge", lastGeneralReactionNode, logicFalseNode);
                    edges.push(edgeYesReaction);

                } else {
                    const edgeYesReaction = this.generateEdge(lastReactionNode.id, logicFalseNode.id, "emptyEdge", lastReactionNode, logicFalseNode);
                    edges.push(edgeYesReaction);
                }

                lastReactionNode = logicFalseNode;
            });

            this.addLastReactionTip(locationOptionYes.whereTo, lastReactionNode);


            lastReactionNode = null;
            var trueGeneralY = generalY;
            const logicTrueReactions = locationOptionYes.reactionsLogicTrue;
            firstReaction = true;
            var logicTrueNode = null;

            logicTrueReactions.forEach(reaction => {

                logicTrueNode = this.generateNode(id++, reaction.text, yesCoordinate - 1000, trueGeneralY += ySpace, "special", "locationLogicTrueReaction", reaction, entry);
                currentNodes.push(logicTrueNode);

                if (firstReaction) {
                    firstReaction = false;

                    const edgeYesReaction = this.generateEdge(lastGeneralReactionNode.id, logicTrueNode.id, "emptyEdge", lastGeneralReactionNode, logicTrueNode);
                    edges.push(edgeYesReaction);

                } else {
                    const edgeYesReaction = this.generateEdge(lastReactionNode.id, logicTrueNode.id, "emptyEdge", lastReactionNode, logicTrueNode);
                    edges.push(edgeYesReaction);
                }

                lastReactionNode = logicTrueNode;
            });
            this.addLastReactionTip(locationOptionYes.whereTo, lastReactionNode);


            firstReaction = true;
            var logicUndeterminedNode = null;
            var undeterminedGeneralY = generalY;
            lastReactionNode = null;

            const undeterminedReactions = locationOptionYes.reactionsUndetermined;
            undeterminedReactions.forEach(reaction => {

                logicUndeterminedNode = this.generateNode(id++, reaction.text, yesCoordinate - 500, undeterminedGeneralY += ySpace, "special", "locationUndeterminedReaction", reaction, entry);
                currentNodes.push(logicUndeterminedNode);

                if (firstReaction) {
                    firstReaction = false;

                    const edgeUndeterminedReaction = this.generateEdge(lastGeneralReactionNode.id, logicUndeterminedNode.id, "emptyEdge", lastGeneralReactionNode, logicUndeterminedNode);
                    edges.push(edgeUndeterminedReaction);

                } else {
                    const edgeUndeterminedReaction = this.generateEdge(lastReactionNode.id, logicUndeterminedNode.id, "emptyEdge", lastReactionNode, logicUndeterminedNode);
                    edges.push(edgeUndeterminedReaction);
                }

                lastReactionNode = logicUndeterminedNode;

            });
            this.addLastReactionTip(locationOptionYes.whereTo, lastReactionNode);

            if (falseGeneralY > trueGeneralY && falseGeneralY > undeterminedGeneralY && falseGeneralY > noY) {
                currentY = falseGeneralY;
            } else if (trueGeneralY > falseGeneralY && trueGeneralY > undeterminedGeneralY && trueGeneralY > noY) {
                currentY = trueGeneralY;
            } else if (undeterminedGeneralY > falseGeneralY && undeterminedGeneralY > trueGeneralY && undeterminedGeneralY > noY) {
                currentY = undeterminedGeneralY;
            } else if (noY > falseGeneralY && noY > trueGeneralY && noY > undeterminedGeneralY) {
                currentY = noY;
            } else {
                currentY = falseGeneralY;
            }

            currentY += ySpace; //better display

        } else if (entry.interaction.type === "Question-YesNo"
            || entry.interaction.type === "Question-Image"
            || entry.interaction.type === "Question-Share"
            || entry.interaction.type === "Question-Payment"
            || entry.interaction.type === "yesno"
            || entry.interaction.type === "share"
            || entry.interaction.type === "payment"
            || entry.interaction.type === "image") {

            var lastNode = null;

            if (currentNodes.length > 0) {
                lastNode = currentNodes[currentNodes.length - 1];
            }


            var text = entry.interaction.metadata.text
            if (entry.interaction.type === "Question-Payment"
                || entry.interaction.type === "payment") {
                text = text + " " + entry.interaction.metadata.payment + " baecoins";
            }
            const interactionNode = this.generateNode(id++, text, centerCoordinate, currentY += ySpace, "special", "interaction", entry.interaction, entry);
            currentNodes.push(interactionNode);

            if (lastNode) {
                var edge = this.generateEdge(lastNode.id, interactionNode.id, "emptyEdge", lastNode, interactionNode);
                edges.push(edge);
            }


            this.iterateTipsForEdge(interactionNode);

            var currentYNo = currentY;
            var currentYYes = currentY;

            var optionNo = entry.interaction.metadata.optionNo;

            currentYNo += ySpace

            const noNode = this.generateNode(id++, "", noCoordinate, currentYNo, "special", "optionNo", optionNo, entry);
            currentNodes.push(noNode);

            var edge = this.generateEdge(interactionNode.id, noNode.id, "emptyEdge", interactionNode, noNode);
            edges.push(edge);


            optionNo.reactions.forEach(reaction => {

                const lastReactionNode = currentNodes[currentNodes.length - 1];

                const noReactionNode = this.generateNode(id++, reaction.text, noCoordinate, currentYNo += ySpace, "special", "reaction", reaction, entry);
                currentNodes.push(noReactionNode);

                const edge = this.generateEdge(lastReactionNode.id, noReactionNode.id, "emptyEdge", lastReactionNode, noReactionNode);
                edges.push(edge);
            })

            const hangOnNo = optionNo.hangOn

            if (hangOnNo) {

                const lastNode = currentNodes[currentNodes.length - 1];

                currentYNo += ySpace

                const hangOnText = "hangOn-" + hangOnNo.uiMode + " " + hangOnNo.time / 1000 / 60 + " Minutes";

                const hangOnNoNode = this.generateNode(id++, hangOnText, noCoordinate, currentYNo, "special", "hangOn-" + hangOnNo.uiMode, hangOnNo, entry);
                currentNodes.push(hangOnNoNode);

                lastEntryWithHangOn = entry;
                lastHangOnNode = hangOnNoNode;
                lastHangOn = hangOnNo;

                const edge = this.generateEdge(lastNode.id, hangOnNoNode.id, "emptyEdge", lastNode, hangOnNoNode);
                edges.push(edge);

            }

            if (optionNo.whereTo) {

                lastReactionNode = currentNodes[currentNodes.length - 1];

                this.addLastReactionTip(optionNo.whereTo, lastReactionNode);

                this.connectLastEntryToPreviousIfNeeded(entries, optionNo, nodes, edges, lastReactionNode);
            }


            var optionYes = entry.interaction.metadata.optionYes;

            currentYYes += ySpace

            const yesNode = this.generateNode(id++, "", yesCoordinate, currentYYes, "special", "optionYes", optionYes, entry);
            currentNodes.push(yesNode);

            var edge = this.generateEdge(interactionNode.id, yesNode.id, "emptyEdge", interactionNode, yesNode);
            edges.push(edge);


            optionYes.reactions.forEach(reaction => {

                const lastReactionNode = currentNodes[currentNodes.length - 1];

                const yesReactionNode = this.generateNode(id++, reaction.text, yesCoordinate, currentYYes += ySpace, "special", "reaction", reaction, entry);
                currentNodes.push(yesReactionNode);

                const edge = this.generateEdge(lastReactionNode.id, yesReactionNode.id, "emptyEdge", lastReactionNode, yesReactionNode);
                edges.push(edge);

            })

            const hangOnYes = optionYes.hangOn

            if (hangOnYes) {

                const lastNode = currentNodes[currentNodes.length - 1];

                currentYYes += ySpace

                const hangOnText = "hangOn-" + hangOnYes.uiMode + " " + hangOnYes.time / 1000 / 60 + " Minutes";

                const hangOnYesNode = this.generateNode(id++, hangOnText, yesCoordinate, currentYYes, "special", "hangOn-" + hangOnYes.uiMode, hangOnYes, entry);
                currentNodes.push(hangOnYesNode);

                lastEntryWithHangOn = entry;
                lastHangOnNode = hangOnYesNode;
                lastHangOn = hangOnYes;

                const edge = this.generateEdge(lastNode.id, hangOnYesNode.id, "emptyEdge", lastNode, hangOnYesNode);
                edges.push(edge);

            }

            if (optionYes.whereTo) {

                lastReactionNode = currentNodes[currentNodes.length - 1];

                this.addLastReactionTip(optionYes.whereTo, lastReactionNode);

                this.connectLastEntryToPreviousIfNeeded(entries, optionYes, nodes, edges, lastReactionNode);

            }


            if (currentYYes >= currentYNo) {
                currentY = currentYYes;
            } else {
                currentY = currentYNo;
            }


        }

        return { id, currentY }
    }

    connectLastEntryToPreviousIfNeeded(entries, option, nodes, edges, lastReactionNode) {

        var nodeToConnect = null;
        outer:
        for (var i = 0; i < entries.length; i++) {

            const itEntry = entries[i];

            if (option.whereTo.next == itEntry.id) {
                
                for (var j = 0; j < nodes.length; j++) {

                    const itNode = nodes[j];

                    if (itEntry.id === itNode.entry.id) {

                        if (itEntry.transitions.length > 0) {
                            
                            if (itNode.dataType === constants.TRANSITION) {
                                nodeToConnect = itNode;
                                break outer;
                            }

                        } else if (itEntry.interaction) {

                            if (itNode.dataType === constants.INTERACTION) {
                                nodeToConnect = itNode;
                                break outer;
                            }

                        }
                    }
                }

            }
        }

        if (nodeToConnect) {
            const edge = this.generateEdge(lastReactionNode.id, nodeToConnect.id, "emptyEdge", lastReactionNode, nodeToConnect);
            edges.push(edge);
        }
    }

    isAvailability = (type) => {
        return type === "Story-See"
            || type === "Story-Respond"
            || type === "Story-Chapter"
            || type === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_SEE
            || type === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_RESPOND
            || type === constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_CHAPTER
    }

    handleAvailabilityOption = (option, optionNumber, id, currentNodes, interactionNode, currentY, entry) => {

        var xCoordinate = 0;
        var currentY = currentY;

        switch (optionNumber) {
            case 1:
                xCoordinate = availabilityLeftCoordinate;
                break;
            case 2:
                xCoordinate = availabilityCenterCoordinate;
                break;
            case 3:
                xCoordinate = availabilityRightCoordinate;
                break;
            default:
                return 0;
        }

        const optionReplyNode = this.generateNode(id++, option.reply, xCoordinate, currentY, "special", "option" + optionNumber, option, entry);
        currentNodes.push(optionReplyNode);

        const edge = this.generateEdge(interactionNode.id, optionReplyNode.id, "emptyEdge", interactionNode, optionReplyNode);
        edges.push(edge);

        return { id, currentNodes, optionReplyNode };
    }

    handleOption = (option, optionNumber, id, currentNodes, interactionNode, currentY, entry, entries) => {

        var xCoordinate = 0;
        var currentY = currentY;
        var reactionKey = "";

        switch (optionNumber) {
            case 1:
                xCoordinate = storyLeftCoordinate;
                reactionKey = "option1Reaction";
                break;
            case 2:
                xCoordinate = centerCoordinate;
                reactionKey = "option2Reaction";
                break;
            case 3:
                xCoordinate = storyRightCoordinate;
                reactionKey = "option3Reaction";
                break;
            default:
                return 0;
        }

        var replyText = "";
        if (option.replyData) {
            replyText = option.replyData.text;
        } else {
            replyText = option.reply;
        }
        const optionReplyNode = this.generateNode(id++, replyText, xCoordinate, currentY += ySpace, "special", "option" + optionNumber, option, entry);
        currentNodes.push(optionReplyNode);

        const edge = this.generateEdge(interactionNode.id, optionReplyNode.id, "emptyEdge", interactionNode, optionReplyNode);
        edges.push(edge);

        if (option.reactions) {

            var lastReactionNode = null;

            option.reactions.forEach(reaction => {

                const lastNode = currentNodes[currentNodes.length - 1];

                const optionReactionNode = this.generateNode(id++, reaction.text, xCoordinate, currentY += ySpace, "special", reactionKey, reaction, entry);
                currentNodes.push(optionReactionNode);

                lastReactionNode = optionReactionNode;

                var edge = null;
                const dataType = lastNode['dataType'];

                if (dataType === reactionKey) {
                    edge = this.generateEdge(lastNode.id, optionReactionNode.id, "emptyEdge", lastNode, optionReactionNode);
                } else {
                    edge = this.generateEdge(optionReplyNode.id, optionReactionNode.id, "emptyEdge", optionReplyNode, optionReactionNode);
                }
                edges.push(edge);
            })

            // if (lastReactionNode) {
            //     if (option.whereTo) {
            //         lastReactionsTips.push({ "id": tipsId++, "whereTo": option.whereTo, "node": currentNodes[currentNodes.length - 1] });
            //     }
            // }
        }

        const hangOn = option.hangOn

        if (hangOn) {

            const lastNode = currentNodes[currentNodes.length - 1];

            currentY += ySpace

            const hangOnText = "hangOn-" + hangOn.uiMode + " " + hangOn.time / 1000 / 60 + " Minutes";

            const hangOnNode = this.generateNode(id++, hangOnText, xCoordinate, currentY, "special", "hangOn-" + hangOn.uiMode, hangOn, entry);
            currentNodes.push(hangOnNode);

            lastEntryWithHangOn = entry;
            lastHangOnNode = hangOnNode;
            lastHangOn = hangOn;

            const edge = this.generateEdge(lastNode.id, hangOnNode.id, "emptyEdge", lastNode, hangOnNode);
            edges.push(edge);


        }

        if (option.whereTo) {

            lastReactionNode = currentNodes[currentNodes.length - 1];

            this.addLastReactionTip(option.whereTo, lastReactionNode);

            this.connectLastEntryToPreviousIfNeeded(entries, option, nodes, edges, lastReactionNode);

        }


        return { id, currentNodes, currentY };
    }

    addHangOnIfNeeded = (hangOn, currentNodes, currentY, id, x, edges) => {
        if (hangOn) {

            const lastNode = currentNodes[currentNodes.length - 1];

            currentY += ySpace

            const hangOnText = "hangOn-" + hangOn.uiMode + " " + hangOn.time / 1000 / 60 + " Minutes";

            const hangOnNode = this.generateNode(id++, hangOnText, x, currentY, "special", "hangOn-" + hangOn.uiMode, hangOn);
            currentNodes.push(hangOnNode);

            lastHangOnNode = hangOnNode;
            //need to also save entry if using this function
            const edge = this.generateEdge(lastNode.id, hangOnNode.id, "emptyEdge", lastNode, hangOnNode);
            edges.push(edge);
        }

        return { currentNodes, edges };
    }

    generateNode = (id, text, x, y, nodeType, dataType, extraValue, entry) => {
        return {
            "id": id,
            "title": text ? text : "EMPTY_TEXT",
            "x": x,
            "y": y,
            "nodeType": nodeType,
            "dataType": dataType,
            "data": extraValue,
            "entry": entry
        }
    }

    generateEdge = (source, target, type, reference1, reference2) => {
        return {
            "source": source,
            "target": target,
            "type": type,
            "reference": { reference1, reference2 }
        }
    }

    addLastReactionTip = (whereTo, node) => {
        if (node.dataType === "hangOn-OFFLINE") {
            return;
        }
        lastReactionsTips.push({ "id": tipsId++, "whereTo": whereTo, "node": node });
    }

    iterateTipsForEdge = (currentNode) => {
        if (lastReactionsTips.length > 0) { //every one of these need to connect to the following node

            var tipsCopy = [...lastReactionsTips];

            lastReactionsTips.forEach((lastReactionTip) => {

                const whereTo = lastReactionTip.whereTo;
                const node = lastReactionTip.node;

                if (entry.interaction) {
                    if (this.isAvailability(entry.interaction.type)) {
                        if (node.dataType === "hangOn-AWAY" || node.dataType === "hangOn-OFFLINE") {
                            const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                            edges.push(edge);
                        }
                    } else {

                        if (node.dataType === "locationLogicFalseReaction") {
                            if (whereTo.different === entry.id) {
                                const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                                edges.push(edge);

                                tipsCopy = tipsCopy.filter(tip => {
                                    return tip.id !== lastReactionTip.id;
                                })
                            }
                        } else if (node.dataType === "locationLogicTrueReaction") {
                            if (whereTo.same === entry.id) {
                                const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                                edges.push(edge);

                                tipsCopy = tipsCopy.filter(tip => {
                                    return tip.id !== lastReactionTip.id;
                                })
                            }
                        } else if (node.dataType === "locationUndeterminedReaction") {
                            if (whereTo.broken === entry.id) {
                                const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                                edges.push(edge);

                                tipsCopy = tipsCopy.filter(tip => {
                                    return tip.id !== lastReactionTip.id;
                                })
                            }
                        } else if (whereTo.next === entry.id) {
                            const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                            edges.push(edge);

                            tipsCopy = tipsCopy.filter(tip => {
                                return tip.id !== lastReactionTip.id;
                            })
                        }


                    }
                } else {

                    if (whereTo.next === entry.id) {
                        const edge = this.generateEdge(node.id, currentNode.id, "emptyEdge", node, currentNode);
                        edges.push(edge);

                        tipsCopy = tipsCopy.filter(tip => {
                            return tip.id !== lastReactionTip.id;
                        })
                    }


                }

            });

            lastReactionsTips = tipsCopy;

        }
    }
    /*
    <div className="node">
                            <div className="outer">
                                <div className="middle">
                                    
                                </div>
                            </div>
                        </div>
    */

    generateTextDiv = (data) => {

        if (data.entry.id === 10) {
            var a = 0;
            a += 1;
        }

        if (data.title === EMPTY_TEXT) {

            return <div className="flow-empty-text">
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </div>
        } else if (data.dataType === "transition") {

            const extra = this.generatePlaceholder(data.data);
            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }

            return <div className="flow-box">

                <div className="align-left-item-transition">
                    <div className="align-left-item-transition-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>



                <div className="align-right-item">
                    {extra}
                </div>
                <div className="flow-box-inner">
                    <div className="flow-transition-title">TRANSITION</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>

        } else if (data.dataType === "option1Reaction"
            || data.dataType === "option2Reaction"
            || data.dataType === "option3Reaction"
            || data.dataType === "locationNoReaction"
            || data.dataType === "locationGeneralReaction"
            || data.dataType === "locationLogicFalseReaction"
            || data.dataType === "locationLogicTrueReaction"
            || data.dataType === "locationUndeterminedReaction"
            || data.dataType === "rangeNumberReaction"
            || data.dataType === "multiStringReaction"
            || data.dataType === "reaction") {

            const extra = this.generatePlaceholder(data.data);
            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }
            return <div className="flow-box">

                <div className="align-left-item-reaction">
                    <div className="align-left-item-reaction-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>
                <div className="align-right-item">
                    {extra}
                </div>
                <div className="flow-box-inner">
                    <div className="flow-reaction-title">{this.optionReactionTitle(data.dataType)}</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>

        } else if (data.dataType === "interaction") {

            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }

            return <div className="flow-box">

                <div className="align-left-item-interaction">
                    <div className="align-left-item-interaction-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>

                <div className="flow-box-inner">
                    <div className="flow-interaction-title">INTERACTION</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>


        } else if (data.dataType === "option1"
            || data.dataType === "option2"
            || data.dataType === "option3"
            || data.dataType === "optionYes"
            || data.dataType === "optionNo"
            || data.dataType === "locationNo"
            || data.dataType === "locationYes"
            || data.dataType === "rangeOption"
            || data.dataType === "multiStringOption") {

            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }

            return <div className="flow-box">

                <div className="align-left-item-option">
                    <div className="align-left-item-option-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>

                <div className="flow-box-inner">
                    <div className="flow-option-title">{this.optionTitle(data)}</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>

        } else if (data.dataType === "hangOn-AWAY") {

            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }

            return <div className="flow-box">

                <div className="align-left-item-away">
                    <div className="align-left-item-away-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>

                <div className="flow-box-inner">
                    <div className="flow-away-title">HANG ON</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>

        } else if (data.dataType === "hangOn-OFFLINE") {

            var statusText = null;
            if (data.entry.status && data.entry.status.text !== "") {
                statusText = "STATUS";
            }

            return <div className="flow-box">

                <div className="align-left-item-offline">
                    <div className="align-left-item-offline-inner">
                        {("0" + data.entry.id).slice(-2)}
                    </div>
                    <div>{statusText}</div>
                </div>

                <div className="flow-box-inner">
                    <div className="flow-offline-title">HANG ON</div>
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>

        }

        return <div className="flow-text">
            <div dangerouslySetInnerHTML={{ __html: data.title }} />
        </div>
    }

    generatePlaceholder = (data) => {
        var extra = null;
        if (data.imageId && data.imageId !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/83142c/FFFFFF/?text=I" width={50} height={50} />;
        } else if (data.voiceId && data.voiceId !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/2B4E75/FFFFFF/?text=V" width={50} height={50} />;
        } else if (data.linkImage && data.linkImage.linkId !== -1 && data.linkImage.imageId !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/978d58/FFFFFF/?text=LI" width={50} height={50} />;
        } else if (data.profilePic && data.profilePic !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/2B4E75/FFFFFF/?text=P" width={50} height={50} />;
        } else if (data.videoId && data.videoId !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/2B4E75/FFFFFF/?text=C" width={50} height={50} />;
        } else if (data.locationImageId && data.locationImageId !== -1) {
            extra = <img className="image-border-small" src="http://via.placeholder.com/100/2B4E75/FFFFFF/?text=L" width={50} height={50} />;
        }
        return extra;
    }

    optionReactionTitle = (type) => {

        if (type === "option1Reaction"
            || type === "option2Reaction"
            || type === "option3Reaction"
            || type === "reaction") {
            return "REACTION";
        } else if (type === "locationNoReaction") {
            return "REACTION NO"
        } else if (type === "locationGeneralReaction") {
            return "REACTION GENERAL"
        } else if (type === "locationLogicFalseReaction") {
            return "REACTION DIFFERENT COUNTRY"
        } else if (type === "locationLogicTrueReaction") {
            return "REACTION SAME COUNTRY"
        } else if (type === "locationUndeterminedReaction") {
            return "REACTION NO COUNTRY";
        } else {
            return "REACTION";
        }
    }

    optionTitle = (option) => {

        if (option.data.directive && option.data.directive !== "") {
            return option.data.directive;//"OPTION (" + data.directive + ")";
        } else if (option.dataType === constants.OPTION_NO) {
            return "OPTION - NO"
        } else if (option.dataType === constants.OPTION_YES) {
            return "OPTION - YES"
        } else {
            return "OPTION"
        }
    }
}


export default ChapterGraphUtil;
//modes
export const ENTRY = "entry";
export const TRANSLATIONS = "translations";
export const SAVE_CHAPTER = "saveChapter";
export const CHAPTER = "chapter";
export const EXTRAS_SELECTION = "extrasSelection";

export const ON = "on";

export const REPLY = "reply";
export const INTERACTION = "interaction";
export const TRANSITION = "transition";
export const REACTION = "reaction";

export const BAES = "Baes";
export const CARDS = "Cards";
export const VOICES = "Voices";
export const CHAPTERS = "Chapters";
export const IMAGES = "Images";
export const VIDEOS = "Videos";
export const INVITATIONS = "Invitations";
export const LINKS = "Links";
export const CHAPTER_GRAPH = "ChapterGraph";
export const CHAPTER_GENERATOR = "ChapterGenerator";

export const STORY_RESPOND = "Story-Respond";
export const STORY_SEE = "Story-See";
export const STORY_CHAPTER = "Story-Chapter";

export const LOCATION = "location";
export const MULTI_WORDS = "multi-words";
export const MULTI_NUMBERS = "multi-numbers";
export const NAME = "name";

export const QUESTION_MULTISELECTION_STRING = "Question-MultiselectionString";
export const QUESTION_MULTISELECTION_NUMBER = "Question-MultiselectionNumber";
export const QUESTION_LOCATION = "Question-Location";
export const QUESTION_NAME = "Question-Name";
export const QUESTION_PAYMENT = "Question-Payment";
export const QUESTION_SHARE = "Question-Share";
export const QUESTION_IMAGE = "Question-Image";
export const QUESTION_YESNO = "Question-YesNo";
export const CONVERSATION_STORY = "Story-Conversation";
export const CONVERSATION_AVAILABILITY = "Availability-Conversation";

export const CHOICE3 = "choice3";
export const CHOICE3_AVAILABILITY = "choice3-availability";
export const YES_NO = "yesno";
export const SHARE = "share";
export const PAYMENT = "payment";
export const IMAGE = "image";

export const AVAILABILITY_SEE = "see";
export const AVAILABILITY_RESPOND = "respond";
export const AVAILABILITY_CHAPTER = "chapter";

export const ONLINE = "ONLINE";
export const AWAY = "AWAY";
export const OFFLINE = "OFFLINE";

export const OPTION1 = "option1";
export const OPTION2 = "option2";
export const OPTION3 = "option3";
export const OPTION_YES = "optionYes";
export const OPTION_NO = "optionNo";

export const REACTIONS = "reactions";
export const REACTIONS_LOGIC_TRUE = "reactionsLogicTrue";
export const REACTIONS_LOGIC_FALSE = "reactionsLogicFalse";
export const REACTIONS_UNDETERMINED = "reactionsUndetermined";

export const NEXT = "next";
export const AVAILABILITY = "availability";
export const SAME = "same";
export const DIFFERENT = "different";
export const BROKEN = "broken";

export const NO_LOGIN = -1;
export const LOGIN_APPROVED = 0;
export const LOGIN_REJECTED = 1;

export const KAYLEE_METADATA = "f3uAnhTnpH7uTild4Evc"
export const VASILENA_METADATA = "gU7ImhJhmCAgeRBgflXj"
export const NOELLE_METADATA = "lv82mwt1dyCBtAIxoNSX"
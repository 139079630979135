import React from 'react';
import AddEntry from "../AddEntry/AddEntry";
import AddExtras from "../AddExtras/AddExtras";
import AddReply from "../AddReply/AddReply";
import FinishChapter from "../FinishChapter/FinishChapter";
import ExtrasSelection from "../ExtrasSelection/ExtrasSelection";
import Translations from "../../ChapterTranslations/ChapterTranslations";
import ChapterGraphUtil from '../../ChapterGraph/ChapterGraphUtil';
import EntryHelper from '../Helpers/EntryHelper';
import './ChapterReviewer.css';
import * as constants from '../../../variables/constants';
import Modal from '../../../layouts/Modal/Modal';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

import {
    GraphView, // required
    Edge, // optional
    type IEdge, // optional
    Node, // optional
    type INode, // optional
    type LayoutEngineType, // required to change the layoutEngineType, otherwise optional
    BwdlTransformer, // optional, Example JSON transformer
    GraphUtils // optional, useful utility functions
} from 'react-digraph';
import { createCatchClause } from 'typescript';

const SpecialShape = (
    // <symbol viewBox="-27 0 154 154" id="special" width="154" height="154">
    //   <rect transform="translate(50) rotate(45)" width="109" height="109" />
    // </symbol>
    <symbol viewBox="0 0 400 54" width="400" height="54" id="special">
        <rect x="0" y="0" rx="2" ry="2" width="400" height="54" />
    </symbol>
);

const GraphConfig = {
    NodeTypes: {
        empty: { // required to show empty nodes
            typeText: "None",
            shapeId: "#empty", // relates to the type property of a node
            shape: (
                <symbol viewBox="0 0 100 100" id="empty" key="0">
                    <circle cx="50" cy="50" r="45"></circle>
                </symbol>
            )
        },
        custom: { // required to show empty nodes
            typeText: "Custom",
            shapeId: "#custom", // relates to the type property of a node
            shape: (
                <symbol viewBox="0 0 50 25" id="custom" key="0">
                    <ellipse cx="50" cy="25" rx="50" ry="25"></ellipse>
                </symbol>
            )
        },
        special: {
            shape: SpecialShape,
            shapeId: '#special',
            typeText: 'Special',
        },
    },
    NodeSubtypes: {},
    EdgeTypes: {
        emptyEdge: {  // required to show empty edges
            shapeId: "#emptyEdge",
            shape: (
                <symbol viewBox="0 0 50 50" id="emptyEdge" key="0">
                </symbol>
            )
        }
    }
}

var entryHelper = null;
var chapterGraphUtil = null;
var reactionId = 1;
var elementId = 1;

class ChapterReviewer extends React.Component {

    //element is a temp transition or reaction
    //it's an element in the add entry view
    //when clicking done on the extras view it becomes a transition or reaction


    state = {

        baeKeys: this.props.baeMetadata.keys,

        copiedEntry: null,

        chapter: this.props.chapter ? this.props.chapter : {
            id: -1,
            uniqueId: -1,
            finished: false,
            name: "",
            endingType: "",
            entries: []
        },


        entry: {
            id: 1,
            interaction: null,
            transitions: [], //[{}, {}]
            status: null,
            whereTo: {
                next: -1,
                availability: -1
            }
        },

        //to pass around to different views
        editedElement: null, //1st edit level

        showModal: false,
        mode: "chapter",
        extrasType: null,
        graph: {
            nodes: [],
            edges: []
        },
        selected: {}
    }

    constructor(props) {
        super(props);


    }

    componentDidMount() {

        entryHelper = new EntryHelper();

        chapterGraphUtil = new ChapterGraphUtil();

        this.renderNewGraph(this.props.chapter, null, this.state.baeKeys);
    }

    renderNewGraph(chapter, entry, baeKeys) {

        chapterGraphUtil.initData();

        var nodes = [];
        var edges = [];

        if (chapter) {
            const nodesWithEdges = chapterGraphUtil.createGraph(chapter.entries);
            nodes = nodesWithEdges.nodes;
            edges = nodesWithEdges.edges;
        }


        if (!entry) {

            var highestEntryId = 1;
            this.state.chapter.entries.forEach(entry => {
                if (entry.id > highestEntryId) {
                    highestEntryId = entry.id;
                }
            });

            entry = {
                id: highestEntryId,
                interaction: null,
                transitions: [], //[{}, {}]
                status: null,
                whereTo: {
                    next: -1,
                    availability: -1
                }
            };
        }

        if (!chapter) {
            chapter = {
                id: -1,
                uniqueId: -1,
                finished: false,
                name: "",
                endingType: "",
                entries: []
            };
        }

        this.setState({
            baeKeys : baeKeys,
            chapter: chapter,
            graph: {
                nodes: nodes,
                edges: edges
            },
            selected: {},
            showModal: false,
            mode: "chapter",
            entry: entry
        });
    }

    render() {

        var currentView = null;

        if (this.state.mode === constants.REPLY) {

            currentView = <AddReply
                editedElement={this.state.editedElement}

                replyDone={(replyObject, dynamicOptionKey, option) => this.replyDone(replyObject, dynamicOptionKey, option)}
                replyCancel={() => this.replyCancel()}
                ></AddReply>

        } else if (this.state.mode === "entry") {
            currentView = <AddEntry

                editedElement={this.state.editedElement}

                entry={this.state.entry}
                baeMetadata={this.props.baeMetadata}
                copiedEntry={this.state.copiedEntry}

                entryIdChanged={(id) => this.entryIdChanged(id)}
                done={() => this.addEntryDoneClick()}
                cancel={() => this.addEntryCancelClick()}
                editReply={(replyOptionKey, replyOption) => this.editReply(replyOptionKey, replyOption)}
                gotoTransitionExtras={(element) => this.gotoTransitionExtras(element)}
                gotoReactionExtras={(answerType, element) => this.gotoReactionExtras(answerType, element)}
                gotoLocationReactionExtras={(answerType, element, isYes) => this.gotoLocationReactionExtras(answerType, element, isYes)}
                gotoMultiWordsReactionExtras={(answerType, element, index) => this.gotoMultiWordsReactionExtras(answerType, element, index)}
                gotoMultiNumbersReactionExtras={(answerType, element, index) => this.gotoMultiNumbersReactionExtras(answerType, element, index)}
                addElementToEntry={() => this.addElementToEntry()}
                copyEntry={(entryToCopy) => this.copyEntry(entryToCopy)}
                plusTransition={() => this.plusTransition()}
                pasteEntry={(entryToPaste) => this.pasteEntry(entryToPaste)}
                containerRemoveTransition={(element) => this.containerRemoveTransition(element)}
                containerTransitionUp={(element) => this.containerTransitionUp(element)}
                containerTransitionDown={(element) => this.containerTransitionDown(element)}
                containerReactionUp={(dynamicOptionKey, option, reaction) => this.containerReactionUp(dynamicOptionKey, option, reaction)}
                containerReactionDown={(dynamicOptionKey, option, reaction) => this.containerReactionDown(dynamicOptionKey, option, reaction)}
                multiwordAnswerChanged={(option, answer) => this.multiwordAnswerChanged(option, answer)}
                parentSelectionChanged={(element, event) => this.parentSelectionChanged(element, event)}
                addEmptyHangOnToOption={(option, dynamicOptionKey) => this.addEmptyHangOnToOption(option, dynamicOptionKey)}
                addEmptyReactionToOption={(option, dynamicOptionKey) => this.addEmptyReactionToOption(option, dynamicOptionKey)}
                addEmptyReactionToLocationOption={(option, dynamicOptionKey, reactions, dynamicReactionsKey) => this.addEmptyReactionToLocationOption(option, dynamicOptionKey, reactions, dynamicReactionsKey)}
                addEmptyReactionToMultiWords={(option) => this.addEmptyReactionToMultiWords(option)}
                availabilityReplyTextChanged={(dynamicOptionKey, option, reply) => this.availabilityReplyTextChanged(dynamicOptionKey, option, reply)}
                interactionTextChanged={(text) => this.interactionTextChanged(text)}
                removeInteraction={() => this.removeInteraction()}
                reactionChanged={(answerNumber, reaction, text) => this.reactionChanged(answerNumber, reaction, text)}
                removeGeneralReaction={(dynamicOptionKey, option, reactionToRemove) => this.removeGeneralReaction(dynamicOptionKey, option, reactionToRemove)}
                removeLocationReaction={(dynamicReactionsKey, reactions, reactionToRemove, dynamicOptionKey, option) => this.removeLocationReaction(dynamicReactionsKey, reactions, reactionToRemove, dynamicOptionKey, option)}
                removeMultiWordsReaction={(option, reactionToRemove) => this.removeMultiWordsReaction(option, reactionToRemove)}
                removeMultiNumbersReaction={(option, reactionToRemove) => this.removeMultiNumbersReaction(option, reactionToRemove)}
                addMultiWordsOption={() => this.addMultiWordsOption()}
                interactionNameHintChanged={(hint) => this.interactionNameHintChanged(hint)}
                addInteractionTypeToState={(interactionType) => this.addInteractionTypeToState(interactionType)}
                statusChanged={(value) => this.handleStatusChange(value)}
                reactionLocationWhereToChanged={(type, entryId, dynamicOptionKey, option) => this.reactionLocationWhereToChanged(type, entryId, dynamicOptionKey, option)}
                reactionAvailabilityEntryChanged={(availability, dynamicOptionKey, option) => this.reactionAvailabilityEntryChanged(availability, dynamicOptionKey, option)}
                reactionNextEntryChanged={(next, dynamicOptionKey, option) => this.reactionNextEntryChanged(next, dynamicOptionKey, option)}
                transitionAvailabilityEntryChanged={(availability) => this.transitionAvailabilityEntryChanged(availability)}
                transitionNextEntryChanged={(next) => this.transitionNextEntryChanged(next)}
                nameNextEntryChanged={(next) => this.nameNextEntryChanged(next)}
                nameAvailabilityEntryChanged={(availability) => this.nameAvailabilityEntryChanged(availability)}
                rangeFromChanged={(value, option) => this.rangeFromChanged(value, option)}
                rangeToChanged={(value, option) => this.rangeToChanged(value, option)}
                removeNameReaction={(answerType, reaction) => this.removeNameReaction(answerType, reaction)}
                gotoNameReactionExtras={(reaction) => this.gotoNameReactionExtras(reaction)}
                addEmptyReactionToName={() => this.addEmptyReactionToName()}
                availabilitySelectionChanged={(event) => this.availabilitySelectionChanged(event)}
                hangOnUIModeChanged={(uiMode, dynamicOptionKey, option) => this.hangOnUIModeChanged(uiMode, dynamicOptionKey, option)}
                hangOnMinutesChanged={(minutes, dynamicOptionKey, option) => this.hangOnMinutesChanged(minutes, dynamicOptionKey, option)}
                removeHangOn={(dynamicOptionKey, option) => this.removeHangOn(dynamicOptionKey, option)}

                hangOnTransitionMinutesChanged={(minutes) => this.hangOnTransitionMinutesChanged(minutes)}
                hangOnTransitionUIModeChanged={(uiMode) => this.hangOnTransitionUIModeChanged(uiMode)}
                removeHangOnTransition={() => this.removeHangOnTransition()}
                addEmptyHangOnToEntry={() => this.addEmptyHangOnToEntry()}
                deleteEntry={(entryToDelete) => this.deleteEntry(entryToDelete)}
            />
        } else if (this.state.mode === "extras") {
            currentView = <AddExtras
                baeMetadata={this.props.baeMetadata}
                whereTo={this.state.whereTo}
                editedElement={this.state.editedElement}
                entry={this.state.entry}
                baeKeys={this.state.baeKeys}
                cancelExtrasClick={() => this.cancelExtrasClick()}
                profilePicStateChanged={(isChecked, imageId) => this.profilePicStateChanged(isChecked, imageId)}
                doneNameRectionExtrasClick={(element, condition) => this.doneNameRectionExtrasClick(element, condition)}
                doneGeneralRectionExtrasClick={(element, reactions, dynamicOptionKey, option, condition) => this.doneGeneralRectionExtrasClick(element, reactions, dynamicOptionKey, option, condition)}
                doneLocationRectionExtrasClick={(element, dynamicReactionsKey, reactions, dynamicOptionKey, option, condition) => this.doneLocationRectionExtrasClick(element, dynamicReactionsKey, reactions, dynamicOptionKey, option, condition)}
                doneMultiWordsRectionExtrasClick={(element, option, condition) => this.doneMultiWordsRectionExtrasClick(element, option, condition)}
                doneMultiNumbersRectionExtrasClick={(element, option, condition) => this.doneMultiNumbersRectionExtrasClick(element, option, condition)}
                doneTransitionExtrasClick={(element, condition) => this.doneTransitionExtrasClick(element, condition)}
                extrasSelectionClick={(type) => this.containerExtrasSelectionClick(type)}
                extraTextChanged={(value) => this.extraTextChanged(value)}
                entryKeyChanged={(entryKeys) => this.entryKeyChanged(entryKeys)}
            />
        } else if (this.state.mode === constants.EXTRAS_SELECTION) {

            currentView = <ExtrasSelection
                type={this.state.extrasType}
                baeMetadata={this.props.baeMetadata}
                editedElement={this.state.editedElement}
                toSelectLinkClick={() => this.toSelectLinkClick()}
                toSelectImageClick={(extrasType) => this.toSelectImageClick(extrasType)}
                voiceSelectionClick={(extra) => this.voiceExtraSelectedClick(extra)}
                linkSelectionClick={(extra) => this.linkExtraSelectedClick(extra)}
                imageSelectionClick={(type, extra) => this.imageExtraSelectedClick(type, extra)}
                videoSelectionClick={(extra) => this.videoExtraSelectedClick(extra)}
                doneExtrasSelectionClick={(extra) => this.doneExtrasSelectionClick(extra)}
                cancelExtrasSelectionClick={() => this.cancelExtrasSelectionClick()}
                linkImageDoneClick={(linkImage) => this.linkImageDoneClick(linkImage)}
                linkImageCancelClick={() => this.linkImageCancelClick()}
                locationDoneClick={(location) => this.locationDoneClick(location)}
                locationCancelClick={() => this.locationCancelClick()}
                extrasSelectionClick={(type, extra) => this.containerExtraSelectedClick(type, extra)}
                extrasCancel={() => this.extrasCancel()}
            />
        } else if (this.state.mode === constants.SAVE_CHAPTER) {
            currentView = <FinishChapter
                chapter={this.state.chapter}
                baeKeys={this.state.baeKeys}
                writoToFirebase={(chapter) => this.writoToFirebase(chapter)}
                finishChapterCancel={() => this.finishChapterCancel()}
            />
        } else if (this.state.mode === constants.TRANSLATIONS) {
            currentView = <Translations
                chapter={this.state.chapter}
            />
        } else if (this.state.mode === constants.CHAPTER) {

            //takes the chapter from state and shows it on graph

            //if coming from outside - need to supply chapter object and set chapter mode


        }


        return (
            <div>
                <Modal show={this.state.showModal}>
                    {currentView}
                </Modal>
                <div className="chapter-title">
                    {this.props.baeMetadata.name}
                </div>
                <div className="chapter-subtitle">
                    Chapter {this.state.chapter.id}
                </div>
                <br />
                <div class="row mx-md-n1">
                    <div class="px-md-1">
                        <Button variant="dark" 
                            onClick={() => this.addNewEntry()}>
                        {'Add Entry'}
                        </Button>
                    </div>
                    <div class="px-md-1">
                    <Button variant="dark"
                            onClick={() => this.saveChapter()}>
                        {'Save Chapter'}
                    </Button>
                    </div>
                    <div class="px-md-1">
                    <Button variant="dark"
                            onClick={() => this.showTranslations()}>
                        {'Translations'}
                    </Button>
                    </div>
                </div>
                <br />
                <br />
                <div className="graph">
                    <GraphView ref='GraphView'
                        nodeKey="id"
                        nodes={this.state.graph.nodes}
                        edges={this.state.graph.edges}
                        selected={this.state.selected}
                        nodeTypes={GraphConfig.NodeTypes}
                        nodeSubtypes={GraphConfig.NodeSubtypes}
                        edgeTypes={GraphConfig.EdgeTypes}
                        renderNodeText={this.renderNodeText}
                        onSelectNode={this.onSelectNode.bind(this)}
                        onCreateNode={this.onCreateNode}
                        onUpdateNode={this.onUpdateNode}
                        onDeleteNode={this.onDeleteNode}
                        onSelectEdge={this.onSelectEdge}
                        onCreateEdge={this.onCreateEdge}
                        onSwapEdge={this.onSwapEdge}
                        onDeleteEdge={this.onDeleteEdge} />
                </div>
            </div>
        );


    }

    jsonInteractionTypeToEntryObjectType = (interactionType) => {
        switch (interactionType) {
            case constants.QUESTION_NAME:
                return constants.NAME;
            case constants.QUESTION_YESNO:
                return constants.YES_NO;
            case constants.QUESTION_PAYMENT:
                return constants.PAYMENT;
            case constants.QUESTION_SHARE:
                return constants.SHARE;
            case constants.QUESTION_IMAGE:
                return constants.IMAGE;
            case constants.CONVERSATION_STORY:
                return constants.CHOICE3;
            case constants.CONVERSATION_AVAILABILITY:
                return constants.CHOICE3_AVAILABILITY;
            case constants.QUESTION_LOCATION:
                return constants.LOCATION;
            case constants.QUESTION_MULTISELECTION_STRING:
                return constants.MULTI_WORDS;
            case constants.QUESTION_MULTISELECTION_NUMBER:
                return constants.MULTI_NUMBERS;
            case constants.STORY_SEE:
                return constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_SEE;
            case constants.STORY_RESPOND:
                return constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_RESPOND;
            case constants.STORY_CHAPTER:
                return constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_CHAPTER;
            default:
                return interactionType
        }
    }

    jsonEntryToObject = (jsonEntry) => {

        var entryObject = {};
        const transitions = [];

        if (jsonEntry.transitions) {

            jsonEntry.transitions.forEach(transition => {

                const transitionObject = {
                    ...transition,
                    type: constants.TRANSITION
                }

                transitions.push(transitionObject);
            });
        }

        var interactionType = null;
        if (jsonEntry.interaction) {
            interactionType = this.jsonInteractionTypeToEntryObjectType(jsonEntry.interaction.type)
        }

        if (interactionType) {

            var metadata = {
                ...jsonEntry.interaction.metadata
            }

            const metadataWithReactionIds = this.addReactionIdsToJSONSource(interactionType, metadata)

            entryObject = {
                id: jsonEntry.id,
                ...jsonEntry.status && { status: jsonEntry.status }, //conditional key value
                transitions: transitions,
                interaction: {
                    type: interactionType,
                    metadata: {
                        ...metadataWithReactionIds
                    }
                }
            }
        } else {
            entryObject = {
                id: jsonEntry.id,
                ...jsonEntry.status && { status: jsonEntry.status }, //conditional key value
                transitions: transitions,
                whereTo: {
                    ...jsonEntry.whereTo
                },
                ...jsonEntry.hangOn && { hangOn: jsonEntry.hangOn }
            }
        }

        return entryObject;
    }

    addReactionIdsToJSONSource = (interactionType, metadata) => {

        switch (interactionType) {

            case constants.NAME:

                const reactions = metadata.reactions;

                reactions.forEach(reaction => {
                    reaction.id = reactionId++;
                });
                break;

            case constants.YES_NO:
            case constants.PAYMENT:
            case constants.SHARE:
            case constants.IMAGE:

                const reactionsYes = metadata.optionYes.reactions;
                reactionsYes.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactionsNo = metadata.optionNo.reactions;
                reactionsNo.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                break;

            case constants.CHOICE3:
            case constants.CHOICE3_AVAILABILITY:
            case constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_SEE:
            case constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_RESPOND:
            case constants.CHOICE3_AVAILABILITY + "-" + constants.AVAILABILITY_CHAPTER:

                const reactions1 = metadata.option1.reactions;
                reactions1.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactions2 = metadata.option2.reactions;
                reactions2.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                if (metadata.option3) {
                    const reactions3 = metadata.option3.reactions;
                    reactions3.forEach(reaction => {
                        reaction.id = reactionId++;
                    });
                }
                

                break;
            case constants.LOCATION:

                const reactionsYesLocationGeneral = metadata.optionYes.reactions;
                reactionsYesLocationGeneral.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactionsYesLogicTrue = metadata.optionYes.reactionsLogicTrue;
                reactionsYesLogicTrue.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactionsYesLogicFalse = metadata.optionYes.reactionsLogicFalse;
                reactionsYesLogicFalse.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactionsUndetermined = metadata.optionYes.reactionsUndetermined;
                reactionsUndetermined.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                const reactionsNoLocation = metadata.optionNo.reactions;
                reactionsNoLocation.forEach(reaction => {
                    reaction.id = reactionId++;
                });

                break;

            case constants.MULTI_WORDS:
            case constants.MULTI_NUMBERS:
                metadata.options.forEach(option => {
                    const reactions = option.reactions;
                    reactions.forEach(reaction => {
                        reaction.id = reactionId++;
                    });
                });


                break;
        }

        return metadata;

    }

    onSelectNode = (node) => {

        if (node) {

            var entry = null;
            const entryId = node.entry.id;

            var i;
            for (i = 0; i < this.state.chapter.entries.length; i++) {
                const iterationEntry = this.state.chapter.entries[i];
                if (iterationEntry.id === entryId) {
                    entry = iterationEntry;
                    break;
                }
            }

            if (entry) {

                const entryObject = this.jsonEntryToObject(entry);

                if (entry) {
                    this.setState({
                        showModal: true,
                        entry: entryObject, //should never be a json
                        mode: "entry"
                    });
                }
            }

        } else {
            console.log("onSelectNode empty");
        }

    }

    onUpdateNode = (node) => {
        console.log("onUpdateNode " + node.data);
    }

    renderNodeText(data) {

        //console.log("renderNodeText");
        return (
            <foreignObject className="bae-graph-text"
                x="-190"
                y="10"
                width="380px"
                height="44"
                overflow="visible">

                <div className="node">
                    <div className="outer">
                        <div className="middle">
                            {chapterGraphUtil.generateTextDiv(data)}
                        </div>
                    </div>
                </div>
            </foreignObject>
        );
    }

    toSelectLinkClick = () => {
        this.setState({
            extrasType: "links",
            mode: constants.EXTRAS_SELECTION
        });
    }

    toSelectImageClick = (extrasType) => {
        this.setState({
            extrasType: extrasType,
            mode: constants.EXTRAS_SELECTION
        });
    }


    voiceExtraSelectedClick = (extra) => {

        this.setState((previousState) => ({
            mode: "extras",
            editedElement: {
                ...previousState.editedElement,
                voiceId: extra.id,
                imageId: -1,
                linkImage: null,
                locationImageId: -1,
                profilePic: -1,
                videoId : -1
            }
        }));
    }

    linkExtraSelectedClick = (extra) => {

        this.setState({
            mode: "extrasSelection",
            extrasType: "linkImage"
        });
    }

    imageExtraSelectedClick = (type, extra) => {

        if (type === "linkImage") {
            this.setState({
                mode: "extrasSelection",
                extrasType: "linkImage"
            });
        } else if (type === "location") {
            this.setState((previousState) => ({
                mode: "extras",
                editedElement: {
                    ...previousState.editedElement,
                    voiceId: -1,
                    imageId: -1,
                    linkImage: null,
                    locationImageId: extra.id,
                    profilePic: -1,
                    videoId : -1
                }
            }));
        }
    }

    videoExtraSelectedClick = (video) => {
        this.setState((previousState) => ({
            mode: "extras",
            editedElement: {
                ...previousState.editedElement,
                voiceId: -1,
                imageId: -1,
                linkImage: null,
                locationImageId: -1,
                profilePic: -1,
                videoId : video.id
            }
        }));
    }

    linkImageDoneClick = (linkImage) => {

        this.setState((previousState) => ({
            mode: "extras",
            editedElement: {
                ...previousState.editedElement,
                voiceId: -1,
                imageId: -1,
                linkImage: {
                    linkId: linkImage.linkId,
                    imageId: linkImage.imageId
                },
                locationImageId: -1,
                profilePic: -1,
                videoId : -1
            }
        }));
    }

    linkImageCancelClick = () => {
        this.setState({
            mode: "extras"
        });
    }



    locationDoneClick = (location) => {
        this.setState((previousState) => ({
            mode: "extras",
            editedElement: {
                ...previousState.editedElement,
                voiceId: -1,
                imageId: -1,
                linkImage: null,
                locationImageId: location,
                profilePic: -1,
                videoId : -1
            }
        }));
    }

    locationCancelClick = () => {
        this.setState({
            mode: "extras"
        });
    }

    extrasCancel = () => {
        this.setState({
            mode: "extras"
        });
    }

    containerExtraSelectedClick = (type, extra) => {

        switch (type) {
            case "image":
                this.setState({
                    editedElement: {
                        ...this.state.editedElement,
                        imageId: extra.id,
                        voiceId: -1,
                        linkImage: null,
                        locationImageId: -1,
                        profilePic: -1,
                        videoId : -1
                    },
                    mode: "extras"
                });
                break;
            case "voice":
                this.setState({
                    editedElement: {
                        ...this.state.editedElement,
                        imageId: -1,
                        voiceId: extra.id,
                        linkImage: null,
                        locationImageId: -1,
                        profilePic: -1,
                        videoId : -1
                    },
                    mode: "extras"
                });
                break;
            case "link":
                this.setState({
                    editedElement: {
                        ...this.state.editedElement,
                        imageId: -1,
                        voiceId: -1,
                        linkImage: extra,
                        locationImageId: -1,
                        profilePic: -1,
                        videoId : -1
                    },
                    mode: "extras"
                });
                break;
            case "location":
                this.setState({
                    editedElement: {
                        ...this.state.editedElement,
                        imageId: -1,
                        voiceId: -1,
                        linkImage: null,
                        locationImageId: extra.id,
                        profilePic: -1,
                        videoId : -1
                    },
                    mode: "extras"
                });
                break;
            default:
                break;
        }

    }

    containerExtrasSelectionClick = (type) => {

        this.setState({
            extrasType: type,
            mode: "extrasSelection",
        });
    }

    editReply = (replyOptionKey, replyOption) => {
        this.setState({
            editedElement: {
                replyOptionKey : replyOptionKey,
                replyOption : replyOption
            },
            mode: constants.REPLY
        });
    }

    gotoTransitionExtras = (element) => {

        this.setState({
            editedElement: {
                ...element
            },
            mode: "extras"
        });
    }

    gotoMultiNumbersReactionExtras = (answerType, element, index) => {
        this.setState({
            editedElement: {
                ...element,
                index: index,
                answerType: answerType
            },
            mode: "extras"
        });
    }

    gotoMultiWordsReactionExtras = (answerType, element, index) => {

        this.setState({
            editedElement: {
                ...element,
                index: index,
                answerType: answerType
            },
            mode: "extras"
        });
    }

    gotoLocationReactionExtras = (answerType, element, isYes) => {

        this.setState({
            editedElement: {
                ...element,
                isYes: isYes,
                answerType: answerType
            },
            mode: "extras"
        });
    }

    gotoNameReactionExtras = (element) => {

        this.setState({
            editedElement: {
                ...element,
                answerType: constants.NAME
            },
            mode: "extras"
        });
    }

    addEmptyHangOnToOption = (option, dynamicOptionKey) => {

        if (dynamicOptionKey === "") { //options array

            var optionToChange = null;

            var i;
            for (i = 0; i < this.state.entry.interaction.metadata.options.length; i++) {
                var iterationOption = this.state.entry.interaction.metadata.options[i];
                if (iterationOption === option) {
                    optionToChange = iterationOption;
                    break;
                }
            }

            optionToChange.hangOn = {
                time: -1,
                state: 1,
                uiMode: ""
            };

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...previousState.entry.interaction.metadata.options
                            ]
                        }

                    }
                }
            }));

        } else {
            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                hangOn: {
                                    time: -1,
                                    state: 1,
                                    uiMode: ""
                                }
                            }
                        }

                    }
                }
            }));
        }

    }
    addEmptyReactionToName = () => {
        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        reactions: [
                            ...previousState.entry.interaction.metadata.reactions,
                            {
                                id: reactionId++,
                                type: "reaction",
                                text: "",
                                imageId: -1,
                                voiceId: -1,
                                linkImage: null,
                                locationImageId: -1,
                                profilePic: -1,
                                videoId: -1
                            }
                        ]
                    }

                }
            }
        }));
    }

    availabilitySelectionChanged = (event) => {

        const newType = constants.CHOICE3_AVAILABILITY + "-" + event.target.value;

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    type: newType
                }
            }
        }));
    }

    hangOnUIModeChanged = (uiMode, dynamicOptionKey, option) => {

        if (dynamicOptionKey === "") {

            const options = this.state.entry.interaction.metadata.options;
            var optionToChange = null;

            var i;
            for (i = 0; i < options.length; i++) {
                var iterationOption = options[i];
                if (iterationOption === option) {
                    optionToChange = iterationOption;
                    break;
                }
            }

            optionToChange.hangOn = {
                ...optionToChange.hangOn,
                uiMode: uiMode
            };

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...options
                            ]
                        }

                    }
                }
            }));

        } else {
            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                hangOn: {
                                    ...option.hangOn,
                                    uiMode: uiMode
                                }
                            }
                        }

                    }
                }
            }));
        }

    }

    removeHangOn = (dynamicOptionKey, option) => {

        const optionArray = [{ ...option }];
        const optionWithoutHangOn = optionArray.map(({ hangOn, ...rest }) => rest)

        if (dynamicOptionKey === "") {

            const options = this.state.entry.interaction.metadata.options;

            var indexToRemoveHangOn = -1;

            var i;
            for (i = 0; i < options.length; i++) {
                var iterationOption = options[i];
                if (iterationOption === option) {
                    indexToRemoveHangOn = i;
                    break;
                }
            }

            if (indexToRemoveHangOn !== -1) {
                options[indexToRemoveHangOn] = optionWithoutHangOn[0];
            }

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...options
                            ]
                        }

                    }
                }
            }));

        } else {
            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...optionWithoutHangOn[0]
                            }
                        }

                    }
                }
            }));
        }


    }

    hangOnMinutesChanged = (minutes, dynamicOptionKey, option) => {

        if (dynamicOptionKey === "") {

            const options = this.state.entry.interaction.metadata.options;
            var optionToChange = null;

            var i;
            for (i = 0; i < options.length; i++) {
                var iterationOption = options[i];
                if (iterationOption === option) {
                    optionToChange = iterationOption;
                    break;
                }
            }

            optionToChange.hangOn.time = minutes;

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...options
                            ]
                        }

                    }
                }
            }));

        } else {
            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                hangOn: {
                                    ...option.hangOn,
                                    time: minutes
                                }
                            }
                        }

                    }
                }
            }));
        }


    }

    removeNameReaction = (reaction) => {

        const filteredReactions = this.state.entry.interaction.metadata.reactions.filter(reactionIteration => {
            return reactionIteration.id !== reaction.id;
        });

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        reactions: filteredReactions
                    }

                }
            }
        }));

    }

    gotoReactionExtras = (answerType, element) => {

        this.setState({
            editedElement: {
                ...element,
                answerType: answerType,
                type: constants.REACTION
            },
            mode: "extras"
        });
    }

    extraTextChanged = (value) => {
        this.setState({
            editedElement: {
                ...this.state.editedElement,
                text: value
            }
        })
    }

    //adds ui line to select transition or interaction
    addElementToEntry = () => {

        const editedElement = this.state.editedElement;

        if (!editedElement) {
            this.setState({
                editedElement: {
                    id: elementId++,
                    text: "",
                    imageId: -1,
                    voiceId: -1,
                    linkImage: null,
                    locationImageId: -1,
                    profilePic: -1,
                    videoId : -1
                }
            });
        }
    }

    plusTransition = () => {

        const newTransitions = this.state.entry.transitions;
        newTransitions.push({
            id: elementId++,
            type: constants.TRANSITION,
            text: "",
            imageId: -1,
            voiceId: -1,
            linkImage: null,
            locationImageId: -1,
            profilePic: -1,
            videoId: -1
        });
        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                transitions: newTransitions
            }
        }));
    }

    copyEntry = (entryToCopy) => {

        this.notify("Entry " + entryToCopy.id +  " copied")

        this.setState((previousState) => ({
            ...previousState,
            copiedEntry: entryToCopy
        }));
    }

    pasteEntry = (entryToPaste) => {
        this.setState((previousState) => ({
            ...previousState,
            copiedEntry: null,
            entry: entryToPaste
        }));
    }

    transitionAvailabilityEntryChanged = (availability) => {
        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                whereTo: {
                    ...previousState.entry.whereTo,
                    availability: availability
                }
            }

        }));
    }

    transitionNextEntryChanged = (next) => {
        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                whereTo: {
                    ...previousState.entry.whereTo,
                    next: next
                }
            }
        }));
    }

    rangeFromChanged = (value, option) => {

        const options = this.state.entry.interaction.metadata.options;
        option.range[0] = value;

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }
                }
            }
        }));
    }

    rangeToChanged = (value, option) => {

        const options = this.state.entry.interaction.metadata.options;
        option.range[1] = value;

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }
                }
            }
        }));
    }

    reactionLocationWhereToChanged = (type, entryId, dynamicOptionKey, option) => {

        if (option && option.whereTo) {

            this.setState((previousState) => ({
                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                whereTo: {
                                    ...option.whereTo,
                                    [type]: entryId
                                }
                            }
                        }
                    }
                }
            }));
        }

    }

    nameNextEntryChanged = (next) => {
        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        whereTo: {
                            ...previousState.entry.interaction.metadata.whereTo,
                            next: next
                        }
                    }

                }
            }
        }));
    }

    nameAvailabilityEntryChanged = (availability) => {
        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        whereTo: {
                            ...previousState.entry.interaction.metadata.whereTo,
                            availability: availability
                        }
                    }

                }
            }
        }));
    }

    reactionAvailabilityEntryChanged = (availability, dynamicOptionKey, option) => {

        if (dynamicOptionKey === "") {

            const options = this.state.entry.interaction.metadata.options;
            var optionToChange = null;

            var i;
            for (i = 0; i < options.length; i++) {
                var iterationOption = options[i];
                if (iterationOption === option) {
                    optionToChange = iterationOption;
                    break;
                }
            }

            optionToChange.whereTo.availability = availability;

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...options
                            ]
                        }

                    }
                }
            }));

        } else {

            this.setState((previousState) => ({
                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                whereTo: {
                                    ...option.whereTo,
                                    availability: availability
                                }
                            }
                        }
                    }
                }
            }));
        }


    }

    reactionNextEntryChanged = (next, dynamicOptionKey, option) => {

        if (dynamicOptionKey === "") {

            const options = this.state.entry.interaction.metadata.options;
            var optionToChange = null;

            var i;
            for (i = 0; i < options.length; i++) {
                var iterationOption = options[i];
                if (iterationOption === option) {
                    optionToChange = iterationOption;
                    break;
                }
            }

            optionToChange.whereTo.next = next;

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...options
                            ]
                        }

                    }
                }
            }));

        } else {

            this.setState((previousState) => ({
                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                whereTo: {
                                    ...option.whereTo,
                                    next: next
                                }
                            }
                        }
                    }
                }
            }));

        }

    }

    //replydata
    replyDone = (replyObject, dynamicOptionKey, option) => {
        
        this.setState((previousState) => ({
            editedElement: null,
            mode: constants.ENTRY,
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOptionKey]: {
                            ...option,
                            reply: replyObject.text,
                            replyData: replyObject
                        }
                    }

                }
            }
        }));
    }

    replyCancel = () => {
        this.setState({
            editedElement: null,
            mode: constants.ENTRY
        });
    }

    entryIdChanged = (id) => {

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                id: id
            }
        }));
    }

    handleStatusChange = (value) => {

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                status: {
                    text: value,
                    timeSaid: 0
                }
            }
        }));

    }

    doneExtrasSelectionClick = (extra) => {
        console.log("doneExtrasSelectionClick " + extra);
    }

    cancelExtrasSelectionClick = () => {
        console.log("cancelExtrasSelectionClick");
    }



    handleReactionAddExtra = (reactions, element, condition) => {

        if (reactions.length > 0) {

            //if contains it - do nothing

            var existingIndex = -1;
            const elementExists = reactions.some((item, idx) => {

                const exists = item.id === element.id;

                if (exists) {
                    existingIndex = idx;
                }

                return exists;
            })

            if (elementExists) {

                const existingElement = reactions[existingIndex]
                existingElement.text = element.text;
                existingElement.imageId = element.imageId;
                existingElement.linkImage = element.linkImage;
                existingElement.voiceId = element.voiceId;
                existingElement.locationImageId = element.locationImageId;
                existingElement.profilePic = element.profilePic;
                existingElement.videoId = element.videoId;

                if (element.keys) {
                    existingElement.keys = element.keys;
                } else {
                    existingElement.keys = null;
                }
                
                if (condition) {
                    existingElement.conditions = condition;
                } else {
                    existingElement.conditions = null;
                }
            } else {
                reactions.push(element);
            }

        } else {
            reactions.push(element);
        }

        return reactions;
    }

    doneGeneralRectionExtrasClick = (element, reactions, dynamicOptionKey, option, condition) => {

        const currentReactions = this.handleReactionAddExtra(reactions, element, condition)

        this.setState((previousState) => ({
            editedElement: null,
            mode: "entry",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOptionKey]: {
                            ...option,
                            reactions: currentReactions,
                        }
                    }

                }
            }
        }));
    }

    doneLocationRectionExtrasClick = (element, dynamicReactionsKey, reactions, dynamicOptionKey, option, condition) => {

        const currentReactions = this.handleReactionAddExtra(reactions, element, condition)

        this.setState((previousState) => ({
            editedElement: null,
            mode: "entry",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOptionKey]: {
                            ...option,
                            [dynamicReactionsKey]: currentReactions,
                        }
                    }
                }
            }
        }));
    }

    doneMultiNumbersRectionExtrasClick = (element, option, condition) => {

        const options = this.state.entry.interaction.metadata.options;
        const currentReactions = this.handleReactionAddExtra(option.reactions, element, condition)
        option.reactions = currentReactions;

        this.setState((previousState) => ({
            editedElement: null,
            mode: "entry",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }
                }
            }
        }));
    }

    hangOnTransitionMinutesChanged = (minutes) => {
        this.setState((previousState) => ({
            mode: "entry",
            entry: {
                ...previousState.entry,
                hangOn: {
                    ...previousState.entry.hangOn,
                    time: minutes
                }
            }
        }));
    }

    hangOnTransitionUIModeChanged = (uiMode) => {

        this.setState((previousState) => ({
            mode: "entry",
            entry: {
                ...previousState.entry,
                hangOn: {
                    ...previousState.entry.hangOn,
                    uiMode: uiMode
                }
            }
        }));
    }

    deleteEntry = (entryToDelete) => {

        const chapter = this.state.chapter;
        const chapterEntries = chapter.entries;

        var idxToDelete = -1;
        if (chapterEntries.length > 0) {
            var i;
            for (i = 0; i < chapterEntries.length; i++) {
                const iterationEntry = chapterEntries[i];
                if (iterationEntry.id === entryToDelete.id) { //reviewed and clicked done
                    idxToDelete = i;
                    break;
                }
            }
        }

        if (idxToDelete !== -1) {
            chapterEntries.splice(idxToDelete, 1);
        }

        this.renderNewGraph(chapter, null, []);

    }

    entryKeyChanged = (entryKey) => {
        
        this.setState((previousState) => ({
            editedElement: {
                ...previousState.editedElement,
                keys: entryKey === '' ? null : entryKey
            }
        }));
        
    }

    addEmptyHangOnToEntry = () => {
        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                hangOn: {
                    time: -1,
                    state: 1,
                    uiMode: ""
                }
            }
        }));
    }
    removeHangOnTransition = () => {

        const optionArray = [{ ...this.state.entry }];
        const entryWithoutHangOn = optionArray.map(({ hangOn, ...rest }) => rest)

        this.setState({
            entry: {
                ...entryWithoutHangOn[0]
            }
        });
    }

    profilePicStateChanged = (isChecked, imageId) => {

        if (isChecked) { //have profile pic
            this.setState((previousState) => ({
                editedElement: {
                    ...previousState.editedElement,
                    imageId: -1,
                    profilePic: imageId
                }
            }));
        } else {
            this.setState((previousState) => ({
                editedElement: {
                    ...previousState.editedElement,
                    imageId: imageId,
                    profilePic: -1
                }
            }));
        }

    }
    doneNameRectionExtrasClick = (element, condition) => {

        const currentReactions = this.handleReactionAddExtra(this.state.entry.interaction.metadata.reactions, element, condition)

        this.setState((previousState) => ({
            editedElement: null,
            mode: "entry",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        reactions: currentReactions
                    }
                }
            }
        }));
    }

    cancelExtrasClick = () => {
        this.setState({
            editedElement: null,
            mode: "entry"
        });
    }

    doneMultiWordsRectionExtrasClick = (element, option, condition) => {

        const options = this.state.entry.interaction.metadata.options;
        const currentReactions = this.handleReactionAddExtra(option.reactions, element, condition)
        option.reactions = currentReactions;

        this.setState((previousState) => ({
            editedElement: null,
            mode: "entry",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }
                }
            }
        }));

    }


    doneTransitionExtrasClick = (element, condition) => {

        var elementTransitions = [];

        if (this.state.entry.transitions.length > 0) {

            elementTransitions = this.state.entry.transitions;

            //if contains it - do nothing

            var existingIndex = -1;
            const elementExists = this.state.entry.transitions.some((item, idx) => {

                const exists = item.id === element.id;

                if (exists) {
                    existingIndex = idx;
                }

                return exists;
            })

            if (elementExists) {

                const existingElement = elementTransitions[existingIndex]
                existingElement.text = element.text;
                existingElement.imageId = element.imageId;
                existingElement.linkImage = element.linkImage;
                existingElement.voiceId = element.voiceId;
                existingElement.locationImageId = element.locationImageId;
                existingElement.profilePic = element.profilePic;
                existingElement.videoId = element.videoId;
                
                if (element.keys) {
                    existingElement.keys = element.keys;
                } else {
                    existingElement.keys = null;
                }

                if (condition) {
                    existingElement.conditions = condition;
                } else {
                    existingElement.conditions = null;
                }
                
            } else {
                elementTransitions.push(element);
            }

        } else {
            elementTransitions.push(element);
        }

        if (element.keys) {
            this.setState((previousState) => ({
                baeKeys: [
                    ...previousState.baeKeys,
                    element.keys
                ],
                entry: {
                    ...previousState.entry,
                    transitions: elementTransitions
                },
                editedElement: null,
                mode: "entry"
            }));
        } else {
            this.setState((previousState) => ({
                entry: {
                    ...previousState.entry,
                    transitions: elementTransitions
                },
                editedElement: null,
                mode: "entry"
            }));
        }
        
    }





    addEntryCancelClick = () => {
        console.log("[EntryContainer] addEntryCancelClick");

        var highestEntryId = 1;
        this.state.chapter.entries.forEach(entry => {
            if (entry.id > highestEntryId) {
                highestEntryId = entry.id;
            }
        });

        this.setState({
            showModal: false,
            mode: "chapter",
            editedElement: null,
            entry: {
                id: highestEntryId,
                interaction: null,
                transitions: [], //[{}, {}]
                status: null,
                whereTo: {
                    next: -1,
                    availability: -1
                }
            }
        });
        //this.props.closeClick();
    }



    parentSelectionChanged = (event) => {

        const selection = event.target.value;

        if (selection === "transition") {
            //remove interaction
            this.setState((previousState) => ({
                mode: "extras",
                editedElement: {
                    ...this.state.editedElement,
                    type: selection
                },
                entry: {
                    ...previousState.entry,
                    interaction: null,
                    whereTo: {
                        ...previousState.entry.whereTo,
                        next: previousState.entry.id + 1
                    }
                }
            }));
        } else if (selection === "interaction") {

            this.setState({

                editedElement: {
                    ...this.state.editedElement,
                    type: selection
                }
            });
        }


    }



    addParentInteractionToState = () => {

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    id: elementId++,
                    type: "interaction"
                }
            }
        }));
    }

    interactionNameHintChanged = (hint) => {
        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        hint: hint
                    }
                }
            }
        }));
    }

    addInteractionTypeToState = (interactionType) => {

        if (interactionType === constants.CHOICE3) {

            this.setState((previousState) => ({
                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            option1: {
                                reply: "",
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            },
                            option2: {
                                reply: "",
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            },
                            option3: {
                                reply: "",
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            }
                        }

                    }
                }

            }));
        } else if (interactionType === constants.CHOICE3_AVAILABILITY) {

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            option1: {
                                reply: "",
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            },
                            option2: {
                                reply: "",
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            },
                            option3: {
                                reply: "",
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            }
                        }

                    }
                }

            }));
        } else if (interactionType === constants.YES_NO
            || interactionType === constants.SHARE
            || interactionType === constants.PAYMENT
            || interactionType === constants.IMAGE) {

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            optionYes: {
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            },
                            optionNo: {
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            }
                        }
                    }
                }

            }));

        } else if (interactionType === constants.LOCATION) {

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            optionYes: {
                                reactions: [],
                                reactionsLogicTrue: [],
                                reactionsLogicFalse: [],
                                reactionsUndetermined: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1,
                                    same: -1,
                                    different: -1,
                                    broken: -1
                                }
                            },
                            optionNo: {
                                reactions: [],
                                whereTo: {
                                    next: -1,
                                    availability: -1
                                }
                            }
                        }
                    }
                }

            }));

        } else if (interactionType === constants.MULTI_WORDS) {

            var next = this.state.entry.id + 1;

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            //should have text also
                            options: [
                                {
                                    answers: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                },
                                {
                                    answers: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                },
                                {
                                    answers: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                },
                                {
                                    answers: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                }
                            ]

                        }
                    }
                }

            }));
        } else if (interactionType === constants.MULTI_NUMBERS) {

            var next = this.state.entry.id + 1;

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            options: [
                                {
                                    range: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                },
                                {
                                    range: [],
                                    reactions: [],
                                    whereTo: {
                                        next: next,
                                        availability: -1
                                    }
                                }
                            ]

                        }
                    }
                }

            }));
        } else if (interactionType === constants.NAME) {

            var next = this.state.entry.id + 1;

            this.setState((previousState) => ({

                editedElement: null,

                entry: {
                    ...previousState.entry,
                    hangOn: null,
                    interaction: {
                        type: interactionType,
                        metadata: {
                            text: "",
                            reactions: [],
                            hint: "",
                            whereTo: {
                                next: next,
                                availability: -1
                            }
                        }
                    }
                }

            }));
        }

    }

    addEmptyReactionToLocationOption = (option, dynamicOptionKey, reactions, dynamicReactionsKey) => {

        const pastWhereTo = option.whereTo;

        if (dynamicOptionKey === constants.OPTION_YES
            && dynamicReactionsKey === constants.REACTIONS) {

            pastWhereTo.next = this.state.entry.id + 1;

        } else if (dynamicOptionKey === constants.OPTION_NO
            && dynamicReactionsKey === constants.REACTIONS) {

            pastWhereTo.next = this.state.entry.id + 1;

        } else {

            switch (dynamicReactionsKey) {
                case constants.REACTIONS_LOGIC_TRUE:
                    pastWhereTo.same = this.state.entry.id + 1;
                    break;
                case constants.REACTIONS_LOGIC_FALSE:
                    pastWhereTo.different = this.state.entry.id + 1;
                    break;
                case constants.REACTIONS_UNDETERMINED:
                    pastWhereTo.broken = this.state.entry.id + 1;
                    break;
                default:
                    pastWhereTo.next = this.state.entry.id + 1;
            }
        }



        this.handleAddReactionToLocationState(dynamicOptionKey, option, dynamicReactionsKey, reactions, pastWhereTo);

    }

    handleAddReactionToLocationState = (dynamicOption, pastOption, dynamicReactionsKey, reactions, pastWhereTo) => {

        const emptyReaction = {
            id: reactionId++,
            type: "reaction",
            text: "",
            imageId: -1,
            voiceId: -1,
            linkImage: null,
            locationImageId: -1,
            profilePic: -1,
            videoId: -1
        };

        this.setState((previousState) => ({
            editedElement: {
                ...emptyReaction,
                answerType: dynamicReactionsKey,
                isYes: dynamicOption === constants.OPTION_YES
            },
            mode: "extras",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOption]: {
                            ...pastOption,
                            [dynamicReactionsKey]: [
                                ...reactions,
                                emptyReaction
                            ],
                            whereTo: pastWhereTo ? { ...pastWhereTo } : {
                                next: -1,
                                availability: -1
                            }
                        }
                    }

                }
            }

        }));
    }

    multiwordAnswerChanged = (option, answer) => {

        var optionToChange = null;

        var i;
        for (i = 0; i < this.state.entry.interaction.metadata.options.length; i++) {
            var iterationOption = this.state.entry.interaction.metadata.options[i];
            if (iterationOption === option) {
                optionToChange = iterationOption;
                break;
            }
        }

        if (optionToChange) {

            optionToChange.answers = [answer];
            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            options: [
                                ...previousState.entry.interaction.metadata.options
                            ]
                        }
                    }
                }

            }));
        }
    }

    addEmptyReactionToMultiWords = (option) => {

        var optionToChange = null;

        var targetIndex = -1;
        var i;
        for (i = 0; i < this.state.entry.interaction.metadata.options.length; i++) {
            var iterationOption = this.state.entry.interaction.metadata.options[i];
            if (iterationOption === option) {
                optionToChange = iterationOption;
                targetIndex = i;
                break;
            }
        }

        const emptyReaction = {
            id: reactionId++,
            type: "reaction",
            text: "",
            imageId: -1,
            voiceId: -1,
            linkImage: null,
            locationImageId: -1,
            profilePic: -1,
            videoId: -1
        };

        if (optionToChange) {

            optionToChange.reactions.push(emptyReaction);

            optionToChange.whereTo.next = this.state.entry.id + 1;
        }


        this.setState((previousState) => ({
            editedElement: {
                ...emptyReaction,
                index: targetIndex,
            },
            mode: "extras",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...previousState.entry.interaction.metadata.options
                        ]
                    }
                }
            }

        }));
    }

    addEmptyReactionToOption = (option, dynamicOptionKey) => {

        var pastReply = "";
        var reactions = [];
        var pastWhereTo = null;

        if (option && option.reactions) {
            reactions = [...option.reactions];
        }

        pastReply = option.reply;
        pastWhereTo = option.whereTo;
        pastWhereTo.next = this.state.entry.id + 1;

        this.handleAddReactionToChoice3State(dynamicOptionKey, option, pastReply, reactions, pastWhereTo);
    }




    handleAddReactionToChoice3State = (dynamicOption, option, pastReply, reactions, pastWhereTo) => {

        const emptyReaction = {
            id: reactionId++,
            type: constants.REACTION,
            text: "",
            imageId: -1,
            voiceId: -1,
            linkImage: null,
            locationImageId: -1,
            profilePic: -1,
            videoId: -1
        };

        this.setState((previousState) => ({
            editedElement: {
                ...emptyReaction,
                answerType: dynamicOption
            },
            mode: "extras",
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOption]: {
                            ...option,
                            reply: pastReply,
                            reactions: [
                                ...reactions,
                                emptyReaction
                            ],
                            whereTo: pastWhereTo ? { ...pastWhereTo } : {
                                next: -1,
                                availability: -1
                            }
                        }
                    }
                }
            }

        }));
    }


    removeLocationReaction = (dynamicReactionsKey, reactions, reactionToRemove, dynamicOptionKey, option) => {

        if (reactions) {
            //new reactions array without the one wanted to be removed
            const filteredReactions = option.reactions.filter(reactionIteration => {
                return reactionIteration.id !== reactionToRemove.id;
            });

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                [dynamicReactionsKey]: filteredReactions,
                            }
                        }

                    }
                }

            }));
        }
    }

    removeGeneralReaction = (dynamicOptionKey, option, reactionToRemove) => {

        if (option && option.reactions) {
            //new reactions array without the one wanted to be removed
            const filteredReactions = option.reactions.filter(reactionIteration => {
                return reactionIteration.id !== reactionToRemove.id;
            });

            this.setState((previousState) => ({

                entry: {
                    ...previousState.entry,
                    interaction: {
                        ...previousState.entry.interaction,
                        metadata: {
                            ...previousState.entry.interaction.metadata,
                            [dynamicOptionKey]: {
                                ...option,
                                reactions: filteredReactions,
                            }
                        }

                    }
                }
            }));
        }
    }


    addMultiWordsOption = () => {

        var next = this.state.entry.id + 1;

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...previousState.entry.interaction.metadata.options,
                            {
                                answers: [],
                                reactions: [],
                                whereTo: {
                                    next: next,
                                    availability: -1
                                }
                            }
                        ]
                    }

                }
            }

        }));
    }

    removeMultiNumbersReaction = (option, reactionToRemove) => {

        var options = this.state.entry.interaction.metadata.options;

        const filteredReactions = option.reactions.filter(reactionIteration => {
            return reactionIteration.id !== reactionToRemove.id;
        });

        option.reactions = filteredReactions;

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }

                }
            }

        }));
    }

    removeMultiWordsReaction = (option, reactionToRemove) => {

        var options = this.state.entry.interaction.metadata.options;

        const filteredReactions = option.reactions.filter(reactionIteration => {
            return reactionIteration.id !== reactionToRemove.id;
        });

        option.reactions = filteredReactions;

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        options: [
                            ...options
                        ]
                    }

                }
            }

        }));
    }

    removeYesNoReaction = (reactionToRemove, reactions, dynamicOption, pastWhereTo) => {

        const filteredReactions = reactions.filter(reactionIteration => {
            return reactionIteration.id !== reactionToRemove.id;
        });

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOption]: {
                            reactions: filteredReactions,
                            whereTo: {
                                ...pastWhereTo
                            }
                        }
                    }

                }
            }

        }));
    }
    removeChoice3Reaction = (reactionToRemove, reactions, dynamicOption, pastReply, pastWhereTo) => {

        const filteredReactions = reactions.filter(reactionIteration => {
            return reactionIteration.id !== reactionToRemove.id;
        });

        this.setState((previousState) => ({

            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOption]: {
                            reply: pastReply,
                            reactions: filteredReactions,
                            whereTo: {
                                ...pastWhereTo
                            }
                        }
                    }

                }
            }

        }));
    }


    containerTransitionUp = (transition) => {
        console.log("transition up");

        if (this.state.entry.transitions.length > 1) { //can actually move the transition

            const newTransitions = this.state.entry.transitions;

            var idx = -1;
            for (let [index, val] of newTransitions.entries()) {
                
                if (val.id === transition.id) {
                    idx = index;
                    break;
                }
            }


            if (idx > 0) {
                newTransitions.splice(idx, 1);
                newTransitions.splice(idx - 1, 0, transition);
    
                this.setState((previousState) => ({
                    entry: {
                        ...previousState.entry,
                        transitions: newTransitions
                    }
                }));
            }
            

        }
    }

    containerTransitionDown = (transition) => {

        console.log("transition down");

        if (this.state.entry.transitions.length > 1) { //can actually move the transition

            const newTransitions = this.state.entry.transitions;

            var idx = -1;
            for (let [index, val] of newTransitions.entries()) {
                
                if (val.id === transition.id) {
                    idx = index;
                    break;
                }
            }

            if (idx < newTransitions.length - 1) {
                newTransitions.splice(idx, 1);
                newTransitions.splice(idx + 1, 0, transition);
    
                this.setState((previousState) => ({
                    entry: {
                        ...previousState.entry,
                        transitions: newTransitions
                    }
                }));
            }
            

        }
    }

    containerReactionUp = (dynamicOptionKey, option, reaction) => {

        console.log("reaction up");

        if (option && option.reactions.length > 1) { //can actually move the transition

            const newReactions = option.reactions;

            var idx = -1;
            for (let [index, val] of newReactions.entries()) {
                
                if (val.id === reaction.id) {
                    idx = index;
                    break;
                }
            }


            if (idx > 0) {
                newReactions.splice(idx, 1);
                newReactions.splice(idx - 1, 0, reaction);
    
                this.setState((previousState) => ({

                    entry: {
                        ...previousState.entry,
                        interaction: {
                            ...previousState.entry.interaction,
                            metadata: {
                                ...previousState.entry.interaction.metadata,
                                [dynamicOptionKey]: {
                                    ...option,
                                    reactions: newReactions,
                                }
                            }
    
                        }
                    }
                }));
            }
            

        }

    }

    containerReactionDown = (dynamicOptionKey, option, reaction) => {
    
        console.log("reaction down");
        if (option && option.reactions.length > 1) { //can actually move the transition

            const newReactions = option.reactions;

            var idx = -1;
            for (let [index, val] of newReactions.entries()) {
                
                if (val.id === reaction.id) {
                    idx = index;
                    break;
                }
            }


            if (idx < newReactions.length - 1) {
                newReactions.splice(idx, 1);
                newReactions.splice(idx + 1, 0, reaction);
    
                this.setState((previousState) => ({

                    entry: {
                        ...previousState.entry,
                        interaction: {
                            ...previousState.entry.interaction,
                            metadata: {
                                ...previousState.entry.interaction.metadata,
                                [dynamicOptionKey]: {
                                    ...option,
                                    reactions: newReactions,
                                }
                            }
    
                        }
                    }
                }));
            }
            

        }
        
    }

    containerRemoveTransition = (transition) => {
        if (this.state.entry.transitions.length > 0) {

            var newTransitions = this.state.entry.transitions.filter(transitionIteration => {
                return transitionIteration.id !== transition.id
            });

            this.setState((previousState) => ({
                entry: {
                    ...previousState.entry,
                    transitions: newTransitions
                }
            }));

        } else if (this.state.editedElement.type === constants.TRANSITION) {
            this.setState((previousState) => ({
                editedElement: null
            }));
        }
    }

    reactionChanged = (answerNumber, reaction, text) => {

        var reactions = [];

        var dynamicOption = "";
        var previousReply = "";

        switch (answerNumber) {
            case "1":

                if (this.state.entry.interaction
                    && this.state.entry.interaction.metadata
                    && this.state.entry.interaction.metadata.option1
                    && this.state.entry.interaction.metadata.option1.reactions) {
                    reactions = [...this.state.entry.interaction.metadata.option1.reactions];
                }

                previousReply = this.state.entry.interaction.metadata.option1.reply;
                dynamicOption = "option1";

                break;
            case "2":

                if (this.state.entry.interaction
                    && this.state.entry.interaction.metadata
                    && this.state.entry.interaction.metadata.option2
                    && this.state.entry.interaction.metadata.option2.reactions) {
                    reactions = [...this.state.entry.interaction.metadata.option2.reactions];
                }

                previousReply = this.state.entry.interaction.metadata.option2.reply;
                dynamicOption = "option2";

                break;
            case "3":

                if (this.state.entry.interaction
                    && this.state.entry.interaction.metadata
                    && this.state.entry.interaction.metadata.option3
                    && this.state.entry.interaction.metadata.option3.reactions) {
                    reactions = [...this.state.entry.interaction.metadata.option3.reactions];
                }

                previousReply = this.state.entry.interaction.metadata.option3.reply;
                dynamicOption = "option3";

                break;
        }

        reactions.map(iterationReaction => {
            if (iterationReaction.id === reaction.id) {
                iterationReaction.text = text;
            }
        });

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOption]: {
                            reply: previousReply,
                            reactions: [...reactions]
                        }
                    }

                }
            }

        }));
    }

    removeInteraction = () => {
        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: null
            }
        }));
    }

    interactionTextChanged = (text) => {
        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        text: text
                    }
                }
            }
        }));
    }



    availabilityReplyTextChanged = (dynamicOptionKey, option, text) => {

        this.setState((previousState) => ({
            entry: {
                ...previousState.entry,
                interaction: {
                    ...previousState.entry.interaction,
                    metadata: {
                        ...previousState.entry.interaction.metadata,
                        [dynamicOptionKey]: {
                            ...option,
                            reply: text,
                        }
                    }

                }
            }
        }));

    }


    addEntryDoneClick = () => {

        const entryAlmostJSON = entryHelper.finalizeEntry(this.state.entry);
        //const entryJSON = JSON.stringify(entryAlmostJSON)
        //console.log(entryJSON);

        var chapterEntries = [];
        if (this.state.chapter.entries) {
            chapterEntries = this.state.chapter.entries;
        }

        var found = false;
        if (chapterEntries.length > 0) {
            var i;
            for (i = 0; i < chapterEntries.length; i++) {
                const iterationEntry = chapterEntries[i];
                if (iterationEntry.id === entryAlmostJSON.id) { //reviewed and clicked done
                    chapterEntries[i] = entryAlmostJSON;
                    found = true;
                    break;
                }
            }
        }

        if (!found) {
            chapterEntries.push(entryAlmostJSON);
        }

        const chapter = {
            ...this.state.chapter,
            entries: chapterEntries
        }

        var currentEntryId = this.state.entry.id;

        const entry = {
            id: ++currentEntryId,
            interaction: null,
            transitions: [], //[{}, {}]
            status: null,
            whereTo: {
                next: -1,
                availability: -1
            }
        }

        this.renderNewGraph(chapter, entry, this.state.baeKeys);

    }

    addNewEntry = () => {
        this.setState({
            showModal: true,
            mode: constants.ENTRY
        });
    }

    showTranslations = () => {
        this.setState({
            showModal: true,
            mode: constants.TRANSLATIONS
        });
    }

    saveChapter = () => {
        this.setState({
            showModal: true,
            mode: constants.SAVE_CHAPTER
        });
    }


    finishChapterCancel = () => {
        this.setState({
            mode: "chapter",
            showModal: false
        });
    }

    writoToFirebase = (chapter) => {

        chapter.uniqueId = uuidv4();

        this.setState({
            chapter: chapter,
            mode: "chapter",
            showModal: false
        });

        this.props.writeChapterToFirebase(chapter, this.state.baeKeys);
    }


    notify = (text) => {
        toast(text, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
}

export default ChapterReviewer;
import React from 'react';

class FinishChapter extends React.Component {

    state = {
        price: -1,
        invitation: false,
        chapter: this.props.chapter
    }

    componentDidMount() {

        console.log(this.props);

        var currentPrice = this.state.price;
        if (this.state.chapter && this.state.chapter.endingType.includes("Purchase")) {
            currentPrice = this.getActionPrice(this.state.chapter.endingType);
        }

        var isInvitation = this.isInvitation(this.props.chapter.endingType);

        this.setState({
            price: currentPrice,
            invitation: isInvitation,
        })
    }
    render() {

        
        var purchaseElement = null;
        
        if (this.state.chapter.endingType.includes("Purchase")) {

            const purchasePrice = this.getActionPrice(this.state.chapter.endingType);

            purchaseElement = <input
            className="entry-transition-input"
                onChange={event => this.actionPriceChanged(event.target.value)}
                defaultValue={purchasePrice}>
            </input>;
        }

        var inviteElement = null;
        if (this.state.price > 0) {
            inviteElement = <div><input
                type="checkbox"
                checked={this.state.invitation}
                onChange={(event) => this.invitationCheckboxChanged(event.target.checked)}
            /> Invite</div>
        }


        var actionDiv = <div>
            <select id="ending-types" onChange={event => this.chapterEndingChanged(event.target.value)} >
                <option value="" selected>Choose type</option>
                <option value="Purchase" selected={this.state.chapter.endingType.includes("Purchase")}>Purchase</option>
            </select>
            {purchaseElement}
            <br />
            <br />
            {inviteElement}
        </div>;

        return (<div>

            <input
                placeholder="Chapter name"
                type="text"
                onChange={(event) => this.updateChapterName(event.target.value)}
                defaultValue={this.state.chapter.name}
            >
            </input>
            <br />
            <br />
            <input
                placeholder="Chapter number"
                type="char-text"
                disabled={this.state.chapter.id > 0 ? true : false}
                onChange={(event) => this.updateChapterId(event.target.value)}
                defaultValue={this.state.chapter.id > 0 ? this.state.chapter.id : null}
            >
            </input>
            <br />
            <br />
            <select id="ending-types" onChange={event => this.chapterEndingChanged(event.target.value)} >
                <option value="" selected>Choose ending type</option>
                <option value="Time" selected={this.state.chapter.endingType === "Time"}>Time</option>
                <option value="Action" selected={this.state.chapter.endingType.startsWith("Action")}>Action</option>
            </select>
            <br />
            <br />
            {this.state.chapter.endingType.startsWith("Action") ?
                <div>
                    {actionDiv}
                    <br />

                </div> : null}
            <br />
            <br />
            <input
                type="button"
                value="Write to Firebase"
                onClick={() => this.props.writoToFirebase(this.state.chapter)}>
            </input>
            <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={() => this.props.finishChapterCancel()}></input>
        </div>);
    }

    updateChapterName = (name) => {
        this.setState((previousState) => ({
            chapter: {
                ...previousState.chapter,
                name: name
            }
        }))
    }

    updateChapterId = (chapterId) => {

        const id = parseInt(chapterId, 10);
        this.setState((previousState) => ({
            chapter: {
                ...previousState.chapter,
                id: id
            }
        }))
    }

    chapterEndingChanged = (newEndingType) => {

        var targetEndingType = "";

        var currentEndingType = this.state.chapter.endingType;

        if (currentEndingType === "") {
            targetEndingType = newEndingType;
        } else if (currentEndingType === "Action") {

            if (newEndingType === "Purchase") {
                targetEndingType = "Action(Purchase)";
            } else {
                targetEndingType = newEndingType;
            }
        } else if (currentEndingType === "Time") {

            targetEndingType = newEndingType;
        }

        this.setState((previousState) => ({
            chapter: {
                ...previousState.chapter,
                endingType: targetEndingType
            }
        }));
    }

    invitationCheckboxChanged = (checked) => {

        var invitation = false;
        var currentEnding = this.state.chapter.endingType;
        if (this.state.price > 0) { //must have price to enable invitation price
            if (checked) {
                invitation = false;
                currentEnding = "Action(Purchase" + this.state.price + ", Invite)";
            } else {
                invitation = true;
                currentEnding = "Action(Purchase" + this.state.price + ")";
            }
        }

        this.setState((previousState) => ({
            chapter: {
                ...previousState.chapter,
                endingType: currentEnding
            },
            invitation: invitation
        }));
    }

    actionPriceChanged = (price) => {

        var currentEnding = this.state.chapter.endingType;
        if (currentEnding.startsWith("Action(Purchase")) {
            currentEnding = "Action(Purchase" + price + ")";
        }

        if (this.invitation) {
            currentEnding = "Action(Purchase" + price + ", Invite)";
        }

        this.setState((previousState) => ({
            chapter: {
                ...previousState.chapter,
                endingType: currentEnding
            },
            price: price
        }));
    }

    isInvitation = (endingType) => {
        return endingType.includes("Invitation");
    }

    getActionPrice = (endingType) => {
        var numb = endingType.match(/\d/g);
        if (!numb) {
            return 0
        }
        numb = numb.join("");
        return numb;
    }
}




export default FinishChapter;
import React, { Component } from 'react';
import './MetadataLink.css';
import url from '../../../assets/img/url.jpg';

class MetadataLink extends Component {

    render() {

        return (

            <div className="images-container">


                <article class="bae-image-card bae-image-card--2">
                    <div class="bae-image-card__info-hover">
                        <span class="bae-image-card__time" viewBox="0 0 24 24">{this.props.item.id}</span>

                    </div>
                    <div class="bae-image-card__img"></div>
                    <a href={this.props.item.url} target="_blank" class="bae-image-card_link" style={{ backgroundImage: `url(${url})` }}>
                        <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${url})` }}></div>
                    </a>
                    <div>
                        <span class="bae-image-card__category">{this.props.item.comment}</span>
                        <div class="bae-image-card__title">{this.props.item.url}</div>
                    </div>
                </article>

            </div>

        );
    }
}

export default MetadataLink;
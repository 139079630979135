import app, { functions } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import * as constants from '../../variables/constants';

const config = {
  apiKey: "AIzaSyC52dQ0h5f3OH-eN9hMmRpwLUwbQG8XbVM",
  authDomain: "spousee-b87da.firebaseapp.com",
  databaseURL: "https://spousee-b87da.firebaseio.com",
  projectId: "spousee-b87da",
  storageBucket: "spousee-b87da.appspot.com",
  messagingSenderId: "154005706056",
};

export class Firebase {
  constructor() {

    if (!app.apps.length) {
      app.initializeApp(config);
    }

  }


  getAuth() {
    return app.auth();
  }

  signIn() {

    const auth = app.auth();

    var provider = new app.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('https://www.googleapis.com/auth/plus.me');
    auth.signInWithPopup(provider);
  }


  getBaes = () => {
    const db = app.firestore();
    return db.collection("baes").get();
  }

  getBaeMetadata = (documentId) => {

    const db = app.firestore();
    return db.collection("baes_metadata_test").doc(documentId).get();
    //return db.collection("test").doc(documentId).get();
    //return db.collection("test").doc("F7rrQTM9euD6iA34D007").get();

  }

  writeChapter = (metadata, chapter, oldChapters, baeKeys, success, failure) => {

    var indexToUpdate = -1;

    var i;
    for (i = 0; i < oldChapters.length; i++) {
      var iterationChapter = oldChapters[i];
      if (iterationChapter.id === chapter.id) {
        indexToUpdate = i;
        break;
      }
    }

    const db = app.firestore();
    var metadataRef = db.collection("baes_metadata_test").doc(metadata);

    if (indexToUpdate !== -1) { //chapter exists - now updating

      oldChapters[indexToUpdate] = chapter;

      var updatedChapters = [];
      oldChapters.forEach(chapter => {
        updatedChapters.push(JSON.stringify(chapter));
      })

      metadataRef.update({ plot: updatedChapters, keys: baeKeys }).then(success).catch(failure);

    } else { //this is a new chapter, push it to the array

        metadataRef.update({ plot: app.firestore.FieldValue.arrayUnion(JSON.stringify(chapter)) }).then(success).catch(failure);

    }




      // functions.https.onRequest(async (request, response) => {
      //   const snapshot = await metadataRef.update({ plot: updatedChapters }).get();
      //   const data = snapshot.data();
      //   response.send(data);
      // });

      //work
      // metadataRef.update({ plot: updatedChapters }).then(() => {
      //   console.log("111");
      // }).catch(err => {
      //   console.log("222");
      // })



    //WORKING
    // return metadataRef.update({
    //   plot: app.firestore.FieldValue.arrayUnion(chapter), merge: true
    // });

  }

  test() {
    console.log('test');
  }
}

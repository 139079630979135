/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Bae from './Bae/Bae';
import './Baes.css';
import Spinner from '../components/UI/Spinner/Spinner';
import { Firebase } from './../components/Firebase/firebase';
import MetadataCard from './Metadata/MetadataCard/MetadataCard';
import MetadataInvitation from "./Metadata/MetadataInvitation/MetadataInvitation";
import MetadataImage from "./Metadata/MetadataImage/MetadataImage";
import MetadataVideo from "./Metadata/MetadataVideo/MetadataVideo";
import MetadataLink from "./Metadata/MetadataLink/MetadataLink";
import MetadataVoice from "./Metadata/MetadataVoice/MetadataVoice";
import MetadataChapter from "./Metadata/MetadataChapter/MetadataChapter";
import arrows from '../assets/img/arrows.png';
import * as constants from '../variables/constants';
import BaeButton from './Button/BaeButton';
import ChapterReviewer from './EntryBuilders/ChapterReviewer/ChapterReviewer';
import { UserContext } from "../providers/UserProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Baes extends React.Component {

  state = {
    signedInResult: constants.NO_LOGIN,
    uiShown: constants.BAES,
    baeMetadata: {
      documentId: "",
      id: 0,
      name: "",
      imagesList: [],
      invitations: [],
      links: [],
      voices: [],
      plot: []
    },
    baeMetadataPreviews: [],
    baes: [],
    selectedBae: [],
    loading: false,
    showMetadata: false,
    innerCardsDataSource: null,
    selectedChapter: null
  }

  async componentDidMount() {
    const fire = new Firebase();
    const result = await fire.getBaes();

    var newBaes = [];
    result.docs.forEach(doc => {
      newBaes.push(doc.data());
      this.setState({ baes: newBaes });
    });

    fire.getAuth().onAuthStateChanged(async userAuth => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log("debug");
        this.setState({ signedInResult: constants.LOGIN_APPROVED });
      } else {
        console.log("not debug");
        if (userAuth.email === "mktamir@gmail.com"
        || userAuth.email === "nave03102006@gmail.com"
        || userAuth.email === "avzizmor@gmail.com"
        || userAuth.email === "or.hayout@gmail.com") {
          this.setState({ signedInResult: constants.LOGIN_APPROVED });
        } else {
          this.setState({ signedInResult: constants.LOGIN_REJECTED });
        }
      }
    });
  }

  async getBaeMetadata(metadataDocumentId) {

    this.setState({ loading: true });
    const fire = new Firebase();
    const result = await fire.getBaeMetadata(metadataDocumentId);
    const doc = result.data();

    this.setState({ loading: false, uiShown: constants.CARDS });

    this.presentBae(doc);

  }

  presentBae = (bae) => {
    var imagesList = [];
    if (bae.imagesList) {
      imagesList = JSON.parse(bae.imagesList);
    }

    var links = [];
    if (bae.links) {
      links = JSON.parse(bae.links);
    }

    var invitations = [];
    if (bae.invitations) {
      invitations = bae.invitations;
    }

    var voices = [];
    if (bae.voicesList) {
      voices = JSON.parse(bae.voicesList);
    }

    var videos = [];
    if (bae.videosList) {
      videos = JSON.parse(bae.videosList);
    }

    var keys = [];
    if (bae.keys) {
      keys = bae.keys;
    }

    const name = bae.name;
    const id = bae.id;
    const documentId = this.state.selectedBae.metadata;

    var plotChapters = []
    if (bae.plot) {
      bae.plot.forEach(chapter => {
        if (chapter !== '') {
          var chapterJSON = JSON.parse(chapter);
          plotChapters.push(chapterJSON);
        }
      });
    }


    const previews = [];
    var imagesPreview = "";
    var videosPreview = "";
    var linksPreview = "";
    var voicesPreview = "";
    var invitationsPreview = "";
    var plotPreview = "";


    previews.push(imagesPreview);
    previews.push(linksPreview);
    previews.push(voicesPreview);
    previews.push(invitationsPreview);
    previews.push(plotPreview);
    previews.push(videosPreview);

    this.setState({
      baeMetadata: {
        imagesList: imagesList,
        invitations: invitations,
        links: links,
        voices: voices,
        videos : videos,
        keys : keys,
        name: name,
        id: id,
        plot: plotChapters,
        documentId: documentId
      },
      showMetadata: true,
      baeMetadataPreviews: previews
    });

    
  }

  notify = (baeName) => {
    toast(baeName, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  baeSelectedHandler = (metadataDocumentId) => {

    if (this.state.baeMetadata.documentId === metadataDocumentId) {
      this.setState({ showMetadata: true, uiShown: constants.CARDS });
    } else {
      var selectedBae = null;
      this.state.baes.forEach(bae => {
        if (bae.metadata === metadataDocumentId) {
          selectedBae = bae;
        }
      });

      this.setState({ selectedBae: selectedBae, innerCardsDataSource: null, uiShown: constants.CARDS });


      this.getBaeMetadata(metadataDocumentId);
    }

  }

  imagesSelectedHandler = () => {
    console.log("imagesSelectedHandler");
    this.setState({ uiShown: constants.IMAGES, innerCardsDataSource: { type: "images", data: this.state.baeMetadata.imagesList }, showMetadata: false });
  }
  linksSelectedHandler = () => {
    console.log("linksSelectedHandler");
    this.setState({ uiShown: constants.LINKS, innerCardsDataSource: { type: "links", data: this.state.baeMetadata.links }, showMetadata: false });
  }
  voicesSelectedHandler = () => {
    console.log("voicesSelectedHandler");
    this.setState({ uiShown: constants.VOICES, innerCardsDataSource: { type: "voices", data: this.state.baeMetadata.voices }, showMetadata: false });
  }

  invitationsSelectedHandler = () => {
    console.log("invitationsSelectedHandler");
    this.setState({ uiShown: constants.INVITATIONS, innerCardsDataSource: { type: "invitations", data: this.state.baeMetadata.invitations }, showMetadata: false });
  }

  plotSelectedHandler = () => {
    console.log("plotSelectedHandler");
    this.setState({ uiShown: constants.CHAPTERS, innerCardsDataSource: { type: "plot", data: this.state.baeMetadata.plot }, showMetadata: false });
  }

  videoSelectedHandler = () => {
    console.log("videoSelectedHandler");
    this.setState({ uiShown: constants.VIDEOS, innerCardsDataSource: { type: "videos", data: this.state.baeMetadata.videos }, showMetadata: false });
  }

  chapterClickHandler = (chapterNumber) => {
    console.log("chapterClickHandler: " + chapterNumber);
    this.setState({ uiShown: constants.CHAPTER_GENERATOR, selectedChapter: this.state.baeMetadata.plot[chapterNumber], innerCardsDataSource: null });
  }

  addChapterClickHandler = () => {
    console.log("addChapterClickHandler");
    this.setState({ uiShown: constants.CHAPTER_GENERATOR });
  }

  backClickHandler = () => {
    console.log("backClickHandler");

    switch (this.state.uiShown) {
      case constants.CARDS: {
        this.setState({ uiShown: constants.BAES });
        break;
      }
      case constants.VOICES:
      case constants.CHAPTERS:
      case constants.IMAGES:
      case constants.VIDEOS:
      case constants.INVITATIONS:
      case constants.LINKS:
      {
        this.setState({ uiShown: constants.CARDS });
        this.baeSelectedHandler(this.state.selectedBae.metadata);
        break;
      }
      case constants.CHAPTER_GENERATOR:
      case constants.CHAPTER_GRAPH: {
        this.plotSelectedHandler();

        break;
      }
      default: {
        break;
      }
    }
  }

  entryContainerClosed = () => {

    console.log("entryContainerClosed")
    this.setState({ uiShown: constants.CHAPTERS, innerCardsDataSource: { type: "plot", data: this.state.baeMetadata.plot }, showMetadata: false });

  }

  writeChapterToFirebase = (chapter, baeKeys) => {
    this.writeChapter(chapter, baeKeys);
  }

  writeChapter(chapter, baeKeys) {

    const firebase = new Firebase();
    firebase.writeChapter(this.state.selectedBae.metadata, chapter, this.state.baeMetadata.plot, baeKeys, () => {
      this.notify("Successfully written chapter to firebase");
    }, (error) => {
      this.notify("Failed to write chapter " + error);
    });
    
  }

  signInWithGoogle = () => {

    console.log("SignIn");

    const firebase = new Firebase();
    firebase.signIn();

  }

  render() {

    if (this.state.signedInResult === constants.NO_LOGIN) {

      return <>
        <div className="content">
          <input
            type="button"
            className="btn-general"
            value="Google Sign-In"
            onClick={() => this.signInWithGoogle()}>
          </input>
        </div>
      </>
    } else if (this.state.signedInResult === constants.LOGIN_REJECTED) {

      return <>
        <div className="content">
          NOT AUTHORIZED
        </div>
      </>
    } else if (this.state.signedInResult === constants.LOGIN_APPROVED) {
      var fetchedBaes = <Spinner />;
      if (this.state.baes.length > 0) {
        fetchedBaes = this.state.baes.map(bae => {
          return (
            <Bae
              key={bae.id}
              selectedBae={bae.id === this.state.baeMetadata.id}
              title={bae.name}
              profileImage={bae.profileImage}
              metadatdDocumentId={bae.metadata}
              clicked={() => this.baeSelectedHandler(bae.metadata)}
            />
          );
        })
      }

      var baeMetadataVideosElement = null;
      var baeMetadataImagesElement = null;
      var baeMetadataVoicesElement = null;
      var baeMetadataLinksElement = null;
      var baeMetadataInvitationCountriesElement = null;
      var baeMetadataPlotElement = null;

      if (this.state.loading) {

        var baeMetadataLoader = <Spinner />;

      } else if (this.state.baeMetadata.id !== 0) {

        baeMetadataLoader = null;

        const imagesList = this.state.baeMetadata.imagesList;
        baeMetadataImagesElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={imagesList.length}
          extraName={"Images"}
          image={this.state.baeMetadataPreviews[0]}
          clicked={() => this.imagesSelectedHandler()} />;

        const voices = this.state.baeMetadata.voices;
        baeMetadataVoicesElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={voices.length}
          extraName={"Voices"}
          image={this.state.baeMetadataPreviews[1]}
          clicked={() => this.voicesSelectedHandler()} />;

        const links = this.state.baeMetadata.links;
        baeMetadataLinksElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={links.length}
          extraName={"Links"}
          image={this.state.baeMetadataPreviews[2]}
          clicked={() => this.linksSelectedHandler()} />;

        const invitationCountries = this.state.baeMetadata.invitations;
        baeMetadataInvitationCountriesElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={invitationCountries.length}
          extraName={"Invitation Countries"}
          image={this.state.baeMetadataPreviews[3]}
          clicked={() => this.invitationsSelectedHandler()} />;


        const plot = this.state.baeMetadata.plot;
        baeMetadataPlotElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={plot.length}
          extraName={"Chapters"}
          image={this.state.baeMetadataPreviews[4]}
          clicked={() => this.plotSelectedHandler()}
        />;

        const videos = this.state.baeMetadata.videos;
        baeMetadataVideosElement = <MetadataCard
          baeMetadata={this.state.baeMetadata}
          bae={this.state.selectedBae}
          extraSize={videos.length}
          extraName={"Videos"}
          image={this.state.baeMetadataPreviews[5]}
          clicked={() => this.videoSelectedHandler()}
        />;
        

        var innerCards = null;
        var selectedChapter = null;
        var createChapter = null;

        if (this.state.innerCardsDataSource) {

          if (this.state.innerCardsDataSource.type === "invitations") {
            innerCards = this.state.innerCardsDataSource.data.map(item => {
              return <MetadataInvitation key={item} item={item} />
            });
          } else if (this.state.innerCardsDataSource.type === "images") {
            innerCards = this.state.innerCardsDataSource.data.map(item => {
              return <MetadataImage key={item.id} item={item} />
            });
          } else if (this.state.innerCardsDataSource.type === "videos") {
            innerCards = this.state.innerCardsDataSource.data.map(item => {
              return <MetadataVideo key={item.id} item={item} />
            });
          } else if (this.state.innerCardsDataSource.type === "links") {
            innerCards = this.state.innerCardsDataSource.data.map(item => {
              return <MetadataLink key={item.id} item={item} />
            });
          } else if (this.state.innerCardsDataSource.type === "voices") {
            innerCards = this.state.innerCardsDataSource.data.map(item => {
              return <MetadataVoice key={item.id} item={item} />
            });
          } else if (this.state.innerCardsDataSource.type === "plot") {
            innerCards = [];

            const innerCardsTemp = this.state.innerCardsDataSource.data.map((item, idx) => {
              return <MetadataChapter
                key={item.id}
                item={item}
                chapterClicked={() => this.chapterClickHandler(idx)} />
            });
            innerCards.push(<BaeButton key="add"
              addChapterClicked={() => this.addChapterClickHandler()} />);
            innerCards.push(...innerCardsTemp);
          }

        }

        if (this.state.uiShown === constants.CHAPTER_GENERATOR) {
          createChapter = (
            <div>
          <img src={arrows} width="50" height="50" onClick={() => this.backClickHandler()} />
          <ChapterReviewer
            writeChapterToFirebase={(chapter, baeKeys) => this.writeChapterToFirebase(chapter, baeKeys)}
            baeMetadata={this.state.baeMetadata}
            chapter={this.state.selectedChapter}
            closeClick={() => this.entryContainerClosed()} />
            </div>
          );
        }
      }

      var baeTitle = null;
      if (this.state.baeMetadata.id > 0 && !this.state.loading) {
        baeTitle = <div className="baetitle-white-card">{this.state.baeMetadata.name}'s Story</div>;
      }
      return (


        <>
          <div className="content">
            <div className="cards-list">
              {fetchedBaes}
            </div>
            <br />
            {(this.state.uiShown === constants.VOICES
              || this.state.uiShown === constants.CHAPTERS
              || this.state.uiShown === constants.IMAGES
              || this.state.uiShown === constants.VIDEOS
              || this.state.uiShown === constants.INVITATIONS
              || this.state.uiShown === constants.LINKS
              || this.state.uiShown === constants.CHAPTER_GRAPH) && <img src={arrows} width="50" height="50" onClick={() => this.backClickHandler()} />}
            {baeMetadataLoader}
            <br />
            {baeTitle}
            <br />
            <div className="cards-list">
            {this.state.showMetadata && baeMetadataPlotElement}
              {this.state.showMetadata && baeMetadataImagesElement}
              {this.state.showMetadata && baeMetadataVoicesElement}
              {this.state.showMetadata && baeMetadataVideosElement}
              {this.state.showMetadata && baeMetadataLinksElement}
              {this.state.showMetadata && baeMetadataInvitationCountriesElement}
            </div>

            <div className="cards-list">
              {(this.state.uiShown === constants.VOICES
                || this.state.uiShown === constants.CHAPTERS
                || this.state.uiShown === constants.IMAGES
                || this.state.uiShown === constants.VIDEOS
                || this.state.uiShown === constants.INVITATIONS
                || this.state.uiShown === constants.LINKS
                || this.state.uiShown === constants.CHAPTER_GRAPH) && innerCards}
            </div>
            {this.state.uiShown === constants.CHAPTER_GENERATOR && createChapter}
            <ToastContainer />
          </div>
        </>
      );
    }

  }
}

export default Baes;

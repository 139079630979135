import { v4 as uuidv4 } from 'uuid';
import * as constants from '../../../variables/constants';

class EntryHelper {

    finalizeEntry = (stateEntry) => {

        if (stateEntry.interaction && stateEntry.interaction.type.startsWith(constants.CHOICE3_AVAILABILITY)) {

            if (stateEntry.interaction) {

                var interactionObject = {
                    type: stateEntry.interaction.type
                }

                if (stateEntry.interaction.metadata.text) {
                    interactionObject = {
                        ...interactionObject,
                        metadata: {
                            text: stateEntry.interaction.metadata.text
                        }
                    }
                }

                if (stateEntry.interaction.metadata.option1) {
                    interactionObject = this.finalizeAvailabilityChoice3Option(interactionObject, stateEntry.interaction.metadata.option1, constants.OPTION1);
                }

                if (stateEntry.interaction.metadata.option2) {
                    interactionObject = this.finalizeAvailabilityChoice3Option(interactionObject, stateEntry.interaction.metadata.option2, constants.OPTION2);
                }

                if (stateEntry.interaction.metadata.option3) {
                    interactionObject = this.finalizeAvailabilityChoice3Option(interactionObject, stateEntry.interaction.metadata.option3, constants.OPTION3);
                }
            }

            return {
                id: stateEntry.id,
                uniqueId: uuidv4(),
                interaction: interactionObject
            };

        } else {

            var entry = null;
            var status = null;

            if (stateEntry.status) {
                status = stateEntry.status;
            }

            entry = {
                id: stateEntry.id,
                uniqueId: uuidv4(),
                ...stateEntry.status && {status: stateEntry.status},
                whereTo: stateEntry.whereTo
            };

            var transitions = [];

            stateEntry.transitions.map(transition => {

                var linkId = -1;
                var imageId = -1;
                if (transition.linkImage) {
                    linkId = transition.linkImage.linkId;
                    imageId = transition.linkImage.imageId;
                }

                if (transition.locationImageId !== -1) {

                    transition = {
                        id: transition.id,
                        type: transition.type,
                        text: transition.text,
                        imageId: transition.locationImageId,
                        profilePic: transition.profilePic,
                        voiceId: transition.voiceId,
                        videoId: transition.videoId,
                        linkImage: linkId !== -1 && imageId !== -1 ? {
                            linkId: linkId,
                            imageId: imageId
                        } : null,
                        keys: transition.keys ? [...transition.keys] : null,
                        conditions: transition.conditions ? [...transition.conditions] : null
                    }
                    transitions.push(transition);

                } else {

                    transition = {
                        id: transition.id,
                        type: transition.type,
                        text: transition.text,
                        imageId: transition.imageId,
                        profilePic: transition.profilePic,
                        voiceId: transition.voiceId,
                        videoId: transition.videoId,
                        linkImage: linkId !== -1 && imageId !== -1 ? {
                            linkId: linkId,
                            imageId: imageId
                        } : null,
                        keys: transition.keys ? [...transition.keys] : null,
                        conditions: transition.conditions ? [...transition.conditions] : null
                    }
                    transitions.push(transition);

                }
                
            });

            if (stateEntry.interaction) {

                var interactionObject = {
                    type: stateEntry.interaction.type
                }

                if (stateEntry.interaction.type === constants.CHOICE3) {

                    interactionObject = {
                        ...interactionObject,
                        metadata: {
                            text: stateEntry.interaction.metadata.text ? stateEntry.interaction.metadata.text : ""
                        }
                    }


                    if (stateEntry.interaction.metadata.option1) {
                        interactionObject = this.finalizeChoice3Option(interactionObject, stateEntry.interaction.metadata.option1, constants.OPTION1);
                    }

                    if (stateEntry.interaction.metadata.option2) {
                        interactionObject = this.finalizeChoice3Option(interactionObject, stateEntry.interaction.metadata.option2, constants.OPTION2);
                    }

                    if (stateEntry.interaction.metadata.option3) {
                        interactionObject = this.finalizeChoice3Option(interactionObject, stateEntry.interaction.metadata.option3, constants.OPTION3);
                    }

                } else if (stateEntry.interaction.type === constants.YES_NO
                    || stateEntry.interaction.type === constants.SHARE
                    || stateEntry.interaction.type === constants.PAYMENT
                    || stateEntry.interaction.type === constants.IMAGE) {

                    if (stateEntry.interaction.metadata.text) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                text: stateEntry.interaction.metadata.text
                            }
                        }
                    }

                    if (stateEntry.interaction.metadata.optionYes) {

                        interactionObject = this.finalizeYesNoOption(interactionObject, stateEntry.interaction.metadata.optionYes, constants.OPTION_YES);
                    }

                    if (stateEntry.interaction.metadata.optionNo) {
                        interactionObject = this.finalizeYesNoOption(interactionObject, stateEntry.interaction.metadata.optionNo, constants.OPTION_NO);
                    }

                } else if (stateEntry.interaction.type === constants.LOCATION) {

                    if (stateEntry.interaction.metadata.text) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                text: stateEntry.interaction.metadata.text
                            }
                        }
                    }

                    if (stateEntry.interaction.metadata.optionYes) {
                        interactionObject = this.finalizeYesNoOption(interactionObject, stateEntry.interaction.metadata.optionYes, constants.OPTION_YES);
                        interactionObject = this.finalizeLocationOption(interactionObject, stateEntry.interaction.metadata.optionYes, "reactionsLogicTrue");
                        interactionObject = this.finalizeLocationOption(interactionObject, stateEntry.interaction.metadata.optionYes, "reactionsLogicFalse");
                        interactionObject = this.finalizeLocationOption(interactionObject, stateEntry.interaction.metadata.optionYes, "reactionsUndetermined");
                    }

                    if (stateEntry.interaction.metadata.optionNo) {
                        interactionObject = this.finalizeYesNoOption(interactionObject, stateEntry.interaction.metadata.optionNo, constants.OPTION_NO);
                    }

                    interactionObject = {
                        ...interactionObject,
                        metadata: {
                            ...interactionObject.metadata,
                            code: {
                                type: "City",
                                name: "user_city"           
                            }
                        }
                    }

                } else if (stateEntry.interaction.type === constants.MULTI_WORDS) {

                    if (stateEntry.interaction.metadata.text) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                text: stateEntry.interaction.metadata.text
                            }
                        }
                    }

                    const options = stateEntry.interaction.metadata.options;
                    if (options) {
                        options.forEach(option => {
                            interactionObject = this.finalizeMultiWordsOption(interactionObject, option);
                        });

                    }

                } else if (stateEntry.interaction.type === constants.MULTI_NUMBERS) {

                    if (stateEntry.interaction.metadata.text) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                text: stateEntry.interaction.metadata.text
                            }
                        }
                    }

                    const options = stateEntry.interaction.metadata.options;
                    if (options) {
                        options.forEach(option => {
                            interactionObject = this.finalizeMultiNumbersOption(interactionObject, option);
                        });

                    }

                } else if (stateEntry.interaction.type === constants.NAME) {

                    if (stateEntry.interaction.metadata.text) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                ...interactionObject.metadata,
                                text: stateEntry.interaction.metadata.text
                            }
                        }
                    }

                    if (stateEntry.interaction.metadata.hint) {
                        interactionObject = {
                            ...interactionObject,
                            metadata: {
                                ...interactionObject.metadata,
                                hint: stateEntry.interaction.metadata.hint
                            }
                        }
                    }

                    var parsedReactions = [];
                    const reactions = stateEntry.interaction.metadata.reactions;
                    if (reactions.length > 0) {
                        parsedReactions = this.populateReactions(reactions);
                    }

                    var next = -1;
                    var availability = -1;
                    if (stateEntry.interaction.metadata.whereTo) {
                        next = stateEntry.interaction.metadata.whereTo.next;
                        availability = stateEntry.interaction.metadata.whereTo.availability;
                    }

                    interactionObject = {
                        ...interactionObject,
                        metadata: {
                            ...interactionObject.metadata,
                            reactions: parsedReactions,
                            whereTo: {
                                next: next,
                                availability: availability
                            },
                            code: {
                                type: "Name",
                                name: ""
                            }
                        }
                    }

                }

                entry = {
                    ...entry,
                    transitions: transitions,
                    interaction: interactionObject
                }

            } else { //if no interaction, check for hang-on

                if (stateEntry.hangOn) {

                    entry = {
                        ...entry,
                        transitions: transitions,
                        hangOn: {
                            ...stateEntry.hangOn
                        }
                    }

                } else {

                    entry = {
                        ...entry,
                        transitions: transitions
                    }

                }

            }

            //var transitionsJSON = JSON.stringify(transitions);


            return entry;

        }

    }

    finalizeMultiNumbersOption = (interactionObject, option) => {

        if (option) {

            const reactions = this.populateReactions(option.reactions);

            //whereTo
            var next = -1;
            var availability = -1;
            if (option.whereTo) {
                next = option.whereTo.next;
                availability = option.whereTo.availability;
            }

            if (interactionObject.metadata.options) {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        options: [
                            ...interactionObject.metadata.options,
                            {
                                reactions: reactions,
                                whereTo: {
                                    next: next,
                                    availability: availability
                                },
                                range: option.range
                            }
                        ]
                    }
                }
            } else {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        options: [
                            {
                                reactions: reactions,
                                whereTo: {
                                    next: next,
                                    availability: availability
                                },
                                range: option.range
                            }
                        ]
                    }
                }
            }


            return interactionObject;
        }
    }

    finalizeMultiWordsOption = (interactionObject, option) => {

        if (option) {

            const reactions = this.populateReactions(option.reactions);

            //whereTo
            var next = -1;
            var availability = -1;
            if (option.whereTo) {
                next = option.whereTo.next;
                availability = option.whereTo.availability;
            }

            var options = [];
            if (interactionObject.metadata.options) {
                options = interactionObject.metadata.options;
            }

            if (option.hangOn) {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        options: [
                            ...options,
                            {
                                reactions: reactions,
                                whereTo: {
                                    next: next,
                                    availability: availability
                                },
                                answers: option.answers,
                                hangOn: {
                                    ...option.hangOn
                                }
                            }
                        ]
                    }
                }
            } else {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        options: [
                            ...options,
                            {
                                reactions: reactions,
                                whereTo: {
                                    next: next,
                                    availability: availability
                                },
                                answers: option.answers
                            }
                        ]
                    }
                }
            }


            return interactionObject;
        }
    }


    populateReactions = (optionReactions) => {

        var finalReactions = [];

        if (optionReactions) {

            optionReactions.map(reaction => {

                var linkId = -1;
                var imageId = -1;
                if (reaction.linkImage) {
                    linkId = reaction.linkImage.linkId;
                    imageId = reaction.linkImage.imageId;
                }

                if (reaction.locationImageId) {

                    reaction = {
                        text: reaction.text,
                        imageId: reaction.locationImageId,
                        profilePic: reaction.profilePic,
                        voiceId: reaction.voiceId,
                        videoId : reaction.videoId,
                        linkImage: linkId !== -1 && imageId !== -1 ? {
                            linkId: linkId,
                            imageId: imageId
                        } : null,
                        keys: reaction.keys ? [...reaction.keys] : null,
                        conditions: reaction.conditions ? [...reaction.conditions] : null
                        //for location we only show image, the metadata is brought from the image object
                    }
                    finalReactions.push(reaction);

                } else {

                    reaction = {
                        text: reaction.text,
                        imageId: reaction.imageId,
                        profilePic: reaction.profilePic,
                        voiceId: reaction.voiceId,
                        videoId : reaction.videoId,
                        linkImage: linkId !== -1 && imageId !== -1 ? {
                            linkId: linkId,
                            imageId: imageId
                        } : null,
                        keys: reaction.keys ? [...reaction.keys] : null,
                        conditions: reaction.conditions ? [...reaction.conditions] : null
                        //for location we only show image, the metadata is brought from the image object
                    }
                    finalReactions.push(reaction);

                }
                
            });

        }

        return finalReactions;
    }

    finalizeLocationOption = (interactionObject, option, reactionsType) => {

        if (option) {

            var optionReactions = null;
            switch (reactionsType) {
                case "reactionsLogicTrue":
                    optionReactions = option.reactionsLogicTrue;
                    break;
                case "reactionsLogicFalse":
                    optionReactions = option.reactionsLogicFalse;
                    break;
                case "reactionsUndetermined":
                    optionReactions = option.reactionsUndetermined;
                    break;
            }

            const reactions = this.populateReactions(optionReactions);

            interactionObject = {
                ...interactionObject,
                metadata: {
                    ...interactionObject.metadata,
                    optionYes: {
                        ...option,
                        [reactionsType]: reactions
                    }
                }
            }

            return interactionObject;
        }
    }



    finalizeYesNoOption = (interactionObject, option, dynamicOption) => {

        if (option) {

            const reactions = this.populateReactions(option.reactions);

            //whereTo
            var next = -1;
            var availability = -1;
            if (option.whereTo) {
                next = option.whereTo.next;
                availability = option.whereTo.availability;
            }

            if (option.hangOn) {

                var time = -1;
                var uiMode = "";
                if (option.hangOn) {
                    time = option.hangOn.time;
                    uiMode = option.hangOn.uiMode;
                }

                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOption]: {
                            reactions: reactions,
                            whereTo: {
                                next: next,
                                availability: availability
                            },
                            hangOn: {
                                time: time,
                                state: 1,
                                uiMode: uiMode
                            }
                        }
                    }
                }

            } else {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOption]: {
                            reactions: reactions,
                            whereTo: {
                                next: next,
                                availability: availability
                            }
                        }
                    }
                }
            }

            return interactionObject;
        }

    }

    finalizeChoice3Option = (interactionObject, option, dynamicOption) => {

        if (option) {

            var reply = "";
            var replyData = null;

            if (option.replyData) {
                replyData = option.replyData;
                reply = option.replyData.text;
            } else if (option.reply) {
                reply = option.reply;
            }

            const reactions = this.populateReactions(option.reactions);

            //whereTo
            var next = -1;
            var availability = -1;
            if (option.whereTo) {
                next = option.whereTo.next;
                availability = option.whereTo.availability;
            }

            if (option.hangOn) {

                var time = -1;
                var uiMode = "";
                if (option.hangOn) {
                    time = option.hangOn.time;
                    uiMode = option.hangOn.uiMode;
                }

                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOption]: {
                            reply: reply,
                            replyData: replyData,
                            reactions: reactions,
                            whereTo: {
                                next: next,
                                availability: availability
                            },
                            hangOn: {
                                time: time,
                                state: 1,
                                uiMode: uiMode
                            }
                        }
                    }
                }

            } else {
                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOption]: {
                            reply: reply,
                            replyData: replyData,
                            reactions: reactions,
                            whereTo: {
                                next: next,
                                availability: availability
                            }
                        }
                    }
                }
            }


            return interactionObject;
        }

    }

    finalizeAvailabilityChoice3Option = (interactionObject, option, dynamicOptionKey) => {

        if (option) {

            var reply = "";
            var replyData = null;

            if (option.replyData) {
                replyData = option.replyData;
            } else if (option.reply) {
                reply = option.reply;
            }

            if (!interactionObject.type.includes("-see")) {

                var next = -1;
                if (option.whereTo) {
                    next = option.whereTo.next;
                }

                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOptionKey]: {
                            reply: reply,
                            replyData: replyData,
                            whereTo: {
                                next: next,
                                availability: -1
                            }
                        }
                    }
                }
            } else {

                interactionObject = {
                    ...interactionObject,
                    metadata: {
                        ...interactionObject.metadata,
                        [dynamicOptionKey]: {
                            reply: reply,
                        }
                    }
                }
            }





            return interactionObject;
        }

    }

}

export default EntryHelper;
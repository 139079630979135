import React from 'react';
import "./AddReply.css";

class AddReply extends React.Component {

    state = {
        text: this.props.editedElement.replyOption.replyData ? this.props.editedElement.replyOption.replyData.text : this.props.editedElement.replyOption.reply ? this.props.editedElement.replyOption.reply : null,
        directive: this.props.editedElement.replyOption.replyData ? this.props.editedElement.replyOption.replyData.directive : this.props.editedElement.replyOption.directive ? this.props.editedElement.replyOption.directive : null,
        achievement: this.props.editedElement.replyOption.replyData ? this.props.editedElement.replyOption.replyData.achievement : this.props.editedElement.replyOption.achievement ? this.props.editedElement.replyOption.achievement : null
    }

    render() {

        return (
            <div>
                <div id="entry-elements">
                    Current Element:
                <br />
                    <br />
                    <input
                        placeholder="Enter reply..."
                        type="text"
                        size="80"
                        defaultValue={this.state.text}
                        text={this.state.text}
                        onChange={e => this.textChanged(e.target.value)}></input>
                    <br />
                    <br />
                    <input
                        type="text"
                        placeholder="Enter directive..."
                        size="80"
                        defaultValue={this.state.directive}
                        text={this.state.directive}
                        onChange={e => this.directiveChanged(e.target.value)}>
                    </input>
                    <br />
                    <br />
                    <input
                        type="text"
                        placeholder="Enter achievement..."
                        size="80"
                        defaultValue={this.state.achievement}
                        text={this.state.achievement}
                        onChange={e => this.achievementChanged(e.target.value)}>
                    </input>
                    <br />
                    <br />
                </div>
                <br />
                <input type="button" className="btn-done" id="btnDone" value="DONE" onClick={() => this.replyDone()}></input>
                <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={() => this.replyCancel()}></input>
            </div>
        );
    }

    achievementChanged = (achievement) => {

        this.setState({
            achievement: achievement
        });
    }

    directiveChanged = (directive) => {
        this.setState({
            directive: directive
        });
    }

    textChanged = (text) => {
        this.setState({
            text: text
        });
    }

    replyDone = () => {
        this.props.replyDone({
            text : this.state.text,
            directive : this.state.directive,
            achievement : this.state.achievement
        }, this.props.editedElement.replyOptionKey, this.props.editedElement.replyOption)
    }

    replyCancel = () => {
        this.props.replyCancel();
    }
}

export default AddReply;
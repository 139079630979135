import React, { Component } from 'react';
import './MetadataChapter.scss';

class MetadataChapter extends Component {

    
    render() {

        var idText = ""
        var nameText = "";
        var onClickHandler = null;
        if (this.props.item) {
            idText = "Chapter " + this.props.item.id;
            nameText = this.props.item.name;
            onClickHandler = this.props.chapterClicked;
        }
        

        return (
            <div className="chapter-container" onClick={onClickHandler}>
                <div className="chapter-card">
                    <div className="chapter-card__image-container">
                        <img className="chapter-card__image" src="https://images.unsplash.com/photo-1519999482648-25049ddd37b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2126&q=80" alt="" />
                    </div>

                    <svg className="chapter-card__svg" viewBox="0 0 800 500">
                        <path d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400 L 800 500 L 0 500" stroke="transparent" fill="#333" />
                        <path className="chapter-card__line" d="M 0 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 800 400" stroke="pink" strokeWidth="3" fill="transparent" />
                    </svg>

                    <div className="chapter-card__content">
                        <h1 className="chapter-card__title">{idText}</h1>
                        <h3>{nameText}</h3>
                    </div>

                    {/* <div>
                        <img className="chapter-card__image" src={this.props.item} alt=""/>
                    </div> */}
                </div>
            </div>

        );
    }
}

export default MetadataChapter;
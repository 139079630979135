import React, { Component } from 'react';
import './MetadataVideo.css';

class MetadataVideo extends Component {

    render() {

        return (

            <div className="images-container">


                <article class="bae-image-card bae-image-card--2">
                    <div class="bae-image-card__info-hover">
                        <span class="bae-image-card__time" viewBox="0 0 24 24">{this.props.item.id}</span>

                    </div>
                    <div class="bae-image-card__img"></div>
                    <a href={this.props.item.videoUrl} target="_blank" class="bae-image-card_link" style={{ backgroundImage: `url(${this.props.item.thumbnail})` }}>
                        <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${this.props.item.thumbnail})` }}></div>
                    </a>
                    <div class="bae-image-card__info">
                        <span class="bae-image-card__category">{this.props.item.comment}</span>
                        <h3 class="bae-image-card__title">{this.props.item.type ? this.props.item.type : "image"}</h3>
                    </div>
                </article>

            </div>

        );
    }
}

export default MetadataVideo;
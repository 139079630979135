import React from 'react';
import "./AddLocation.css";
import ExtraPreview from "../../ExtraPreview/ExtraPreview"

class AddLocation extends React.Component {

    state = {
        mode : "locationPreview",//locationPreview, imagesLocation
        location : {
            latitude: this.props.location ? this.props.location.latitude : 0.0,
            longitude: this.props.location ? this.props.location.longitude : 0.0,
            description: this.props.location ? this.props.location.description : "",
            image: this.props.location ? this.props.location.image : null,
        }
    }

    render() {

        var latitude = null;
        if (this.state.location.latitude) {
            latitude = this.state.location.latitude;
        }

        var longitude = null;
        if (this.state.location.longitude) {
            longitude = this.state.location.longitude;
        }

        var description = null;
        if (this.state.location.description) {
            description = this.state.location.description;
        }

        var imageSource = null;
        if (this.state.location.image) {
            imageSource = this.state.location.image.url;
        }

        if (this.state.mode === "locationPreview") {

            return (
                <div>
                    Current Location:
                    <br />
                        Latitude:<input className="entry-transition-input"
                        size="30"
                        defaultValue={ latitude }
                        onChange={e => this.latitudeChanged(e.target.value)}></input>
                    <br />
                        Longitude:<input className="entry-transition-input"
                        size="30"
                        defaultValue={ longitude }
                        onChange={e => this.longitudeChanged(e.target.value)}></input>
                    <br />
                        Description:<input className="entry-transition-input"
                        size="60"
                        defaultValue={ description }
                        onChange={e => this.descriptionChanged(e.target.value)}></input>
                    <br />
                    Image:<img src={imageSource} width={150} height={150} onClick={() => this.showImages()}/>
                    <br />
                    <br />
                    <input type="button" className="btn-done" id="btnDone" value="DONE" onClick={() => this.doneHandler()}></input>
                    <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={() => this.cancelHandler()}></input>
                </div>
            );

        } else if (this.state.mode === "imagesLocation") {
            return this.props.baeMetadata.imagesList.map(item => {
                return <ExtraPreview 
                type="imagesLocation"
                className="preview-image"
                key={item.id} 
                item={item}
                previewClick={() => this.galleryImageClick(item)} />
              });
        }
        
    }
    
    galleryImageClick = (image) => {
        this.setState((previousState) => ({
            mode: "locationPreview",
            location: {
                ...previousState.location,
                image: image
            }
        }));
    }
    showImages = () => {
        this.setState({
            mode: "imagesLocation"
        })
    }

    doneHandler = () => {
        this.props.locationDone(this.state.location)
    }

    cancelHandler = () => {
        this.props.locationCancel()
    }

    latitudeChanged = (text) => {
        this.setState({ location: { 
            ...this.state.location,
            latitude: text }});
    }

    longitudeChanged = (text) => {
        this.setState({ location: { 
            ...this.state.location,
            longitude: text }});
    }

    descriptionChanged = (text) => {
        this.setState({ location: { 
            ...this.state.location,
            description: text }});
    }

};

export default AddLocation;
import React, { Component } from 'react';
import './MetadataCard.css';
import Card from 'react-bootstrap/Card';

class MetadataCard extends Component {

    render() {

        var box;

        switch (this.props.extraName) {

            
            case 'Images':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fimages.jpg?alt=media&token=9e2b2a3b-c22b-4c8f-a233-6842e2c13734"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fimages.jpg?alt=media&token=9e2b2a3b-c22b-4c8f-a233-6842e2c13734"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Images</h3>
                </div>
            </article>;
                break;
            case 'Videos':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fvideos.jpg?alt=media&token=057459e4-5572-4fea-9759-977cddaabac6"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fvideos.jpg?alt=media&token=057459e4-5572-4fea-9759-977cddaabac6"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Videos</h3>
                </div>
            </article>;
                break;
            case 'Voices':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fvoices.jpg?alt=media&token=505b7be0-1e41-45fe-9b7d-e1cacfdf3f48"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fvoices.jpg?alt=media&token=505b7be0-1e41-45fe-9b7d-e1cacfdf3f48"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Voices</h3>
                </div>
            </article>;
                break;
            case 'Links':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Flinks.jpg?alt=media&token=b67152ed-dc30-4f17-9b8e-23e4e79f97e4"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Flinks.jpg?alt=media&token=b67152ed-dc30-4f17-9b8e-23e4e79f97e4"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Links</h3>
                </div>
            </article>;
                break;
            case 'Invitation Countries':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Finvitation.jpg?alt=media&token=05688e93-470c-4255-8918-32a5a74b903d"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Finvitation.jpg?alt=media&token=05688e93-470c-4255-8918-32a5a74b903d"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Invitation Countries</h3>
                </div>
            </article>;
                break;
            case 'Chapters':
                box = <article class="bae-image-card bae-image-card--2" onClick={this.props.clicked}>
                <div class="bae-image-card__info-hover">
                    <span class="bae-image-card__time" viewBox="0 0 24 24">🤭</span>

                </div>
                <div class="bae-image-card__img"></div>
                <a class="bae-image-card_link" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fchapters.jpg?alt=media&token=85181672-475b-4337-9434-c342dc8308fa"})` }}>
                    <div class="bae-image-card__img--hover" style={{ backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/spousee-b87da.appspot.com/o/thumbnails%2Fnoah%2Fchapters.jpg?alt=media&token=85181672-475b-4337-9434-c342dc8308fa"})` }}></div>
                </a>
                <div class="bae-image-card__info">
                    <span class="bae-image-card__category">Click to view</span>
                    <h3 class="bae-image-card__title">{this.props.extraSize} Chapters</h3>
                </div>
            </article>;
                break;
        }
        return box;
    }
}

export default MetadataCard;
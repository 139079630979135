import React, { Component } from 'react';
import './ExtraPreview.css';
import url from '../../../assets/img/url.jpg';
import voice from '../../../assets/img/voice.png';

class ExtraPreview extends Component {

    render() {

        var preview = null;
        if (this.props.type === "images" || this.props.type === "imagesLinkImage") {

            if (!this.props.item.type) {
                preview = <div className="imageBorder" onClick={() => this.props.previewClick(this.props.item)}>
                    <img src={this.props.item.url} width={200} height={200} style={{ backgroundImage: `url(${this.props.item.url})` }} />
                    <br />
                    Image
                </div>;
            } else if (this.props.item.type.startsWith("image")) {
                preview = <div className="imageBorder" onClick={() => this.props.previewClick(this.props.item)}>
                    <img src={this.props.item.url} width={200} height={200} style={{ backgroundImage: `url(${this.props.item.url})` }} />
                    <br />
                    {this.props.item.type.charAt(0).toUpperCase()  + this.props.item.type.slice(1)}
                </div>;
            }

        } else if (this.props.type === "imagesLocation") {

            if (this.props.item.type === "location") {
                preview = <div className="imageBorder" onClick={() => this.props.previewClick(this.props.item)}>
                    <img src={this.props.item.url} width={200} height={200} style={{ backgroundImage: `url(${this.props.item.url})` }} />
                    <br />
                    {this.props.item.comment}
                </div>;
            }

        } else if (this.props.type === "voices") {
            preview = <div className="generalBorder" onClick={() => this.props.previewClick(this.props.item)}>
                <img src={voice} width={100} height={100} />
                {this.voiceParsedName(this.props.item.filename)}
                {this.props.item.type}
            </div>;
        } else if (this.props.type === "links"
            || this.props.type === "linksLinkImage") {
            preview = <div className="generalBorder" onClick={() => this.props.previewClick(this.props.item)}>
                <img src={url} width={100} height={100} />
                <br />
                {this.props.item.url}
            </div>;
        } else if (this.props.item.type === "video") {
            preview = <div className="imageBorder" onClick={() => this.props.previewClick(this.props.item)}>
                <img src={this.props.item.thumbnail} width={200} height={200} style={{ backgroundImage: `url(${this.props.item.thumbnail})` }} />
                <br />
                {this.props.item.type.charAt(0).toUpperCase()  + this.props.item.type.slice(1)}
            </div>;
        }

        return (
            preview
        );
    }

    voiceParsedName = (path) => {

        const msg2 = path.split("/");
        const msg3 = msg2[7].split(".")[0];

        var s = "%2F";
        var p = new RegExp("(" + s + ")")

        const msg4 = msg3.split(p)[4];

        return msg4;
    }
}

export default ExtraPreview;
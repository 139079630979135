import React from 'react';
import ExtraPreview from '../../ExtraPreview/ExtraPreview'

class AddLinkImage extends React.Component {

    state = {
        mode: "linkImagePreview",
        linkImage: {
            linkId: this.props.editedElement.linkImage ? this.props.editedElement.linkImage.linkId : null,
            imageId: this.props.editedElement.linkImage ? this.props.editedElement.linkImage.imageId : null
        }
    }

    render() {

        const imageSource = this.props.baeMetadata.imagesList.filter(item => {
            return item.id === this.state.linkImage.imageId
        });

        var imageURL = "https://via.placeholder.com/100";
        if (imageSource.length === 1) {
            if (imageSource[0]) {
                imageURL = imageSource[0].url;
            }

        }

        const linkSource = this.props.baeMetadata.links.filter(item => {
            return item.id === this.state.linkImage.linkId
        });

        var linkURL = "";
        if (linkSource.length === 1) {
            if (linkSource[0]) {
                linkURL = linkSource[0].url;
            }
        }



        if (this.state.mode === "linkImagePreview") {

            return (
                <div>
                    Current LinkImage:
                    <br />

                    <table>
                        <tr>
                            <td>Link:</td>
                            <td><input type="text" size="80" defaultValue={linkURL} onClick={() => this.showLinks()}></input></td>
                        </tr>
                        <br />
                        <tr>
                            <td>Image:</td>
                            <td><img className="imageBorderNoMargin" src={imageURL} width={150} height={150} onClick={() => this.showImages()} />
                            </td>
                        </tr>
                    </table>

                    <br />
                    <br />
                    <input type="button" className="btn-done" id="btnDone" value="DONE" onClick={() => this.doneHandler()}></input>
                    <input type="button" className="btn-cancel" id="btnCancel" value="CANCEL" onClick={() => this.cancelHandler()}></input>
                </div>
            );

        } else if (this.state.mode === "imagesLinkImage") {

            return this.props.baeMetadata.imagesList.map(item => {
                return <ExtraPreview
                    type="imagesLinkImage"
                    className="preview-image"
                    key={item.id}
                    item={item}
                    previewClick={() => this.galleryImageClick(item)} />
            });
        } else if (this.state.mode === "linksLinkImage") {

            return this.props.baeMetadata.links.map(item => {
                return <ExtraPreview
                    type="linksLinkImage"
                    className="preview-image"
                    key={item.id}
                    item={item}
                    previewClick={() => this.galleryLinkClick(item)} />
            });
        }

    }

    showImages = () => {
        this.setState({
            mode: "imagesLinkImage"
        });
    }

    showLinks = () => {
        this.setState({
            mode: "linksLinkImage"
        });
    }

    galleryImageClick = (image) => {
        this.setState((previousState) => ({
            mode: "linkImagePreview",
            linkImage: {
                ...previousState.linkImage,
                imageId: image.id
            }
        }));
    }

    galleryLinkClick = (link) => {
        this.setState((previousState) => ({
            mode: "linkImagePreview",
            linkImage: {
                ...previousState.linkImage,
                linkId: link.id
            }
        }));
    }

    doneHandler = () => {
        this.props.linkImageDone(this.state.linkImage);
    }

    cancelHandler = () => {
        this.props.linkImageCancel();
    }


};

export default AddLinkImage;